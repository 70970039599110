import { isEmpty, isNull, upperCase } from 'lodash';

import MerchantService from '../api/service/MerchantService';
import UserService from '../api/service/UserService';
import { makeAutoObservable } from 'mobx';
import ConsoleSafe from '../utils/ConsoleSafe';

class UserStore {
  id = null;
  email = null;
  country = null;
  country_code = null;
  first_name = null;
  last_name = null;
  has_agreed = false;
  is_active = false;
  business = {};
  partner_account = null;
  stripe_customer_id = null;
  payment_methods = [];
  receiptCount = null;
  roles = [];
  epopId = null;
  integrated_source = null;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.id = null;
    this.email = null;
    this.country = null;
    this.country_code = null;
    this.first_name = null;
    this.last_name = null;
    this.phone = null;
    this.has_agreed = false;
    this.is_active = false;
    this.partner_account = null;
    this.stripe_customer_id = null;
    this.payment_methods = [];
    this.receiptCount = null;
    this.roles = [];
    this.business = {};
    this.epopId = null;
    this.integrated_source;
  }

  setDetails(data) {
    this.id = data.id;
    this.email = data.email;
    this.country = data.country;
    this.country_code = data.country_code;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.phone = data.phone;
    this.has_agreed = data.has_agreed;
    this.is_active = data.is_active;
    this.partner_account = data.partner_account;
    this.business = data.merchant_business;
    this.partner_account = data.partner_account;
    this.stripe_customer_id = data.stripe_customer_id;
    this.payment_methods = data.payment_methods;
    this.roles = data.roles;
    this.epopId = data.epop_identifier;
    this.integrated_source = data.integrated_source;
  }

  getUserDetails = () => {
    return {
      id: this.id,
      email: this.email,
      country: this.country,
      country_code: this.country_code,
      first_name: this.first_name,
      last_name: this.last_name,
      has_agreed: this.has_agreed,
      is_active: this.is_active,
      business: this.business,
      partner_account: this.partner_account,
      stripe_customer_id: this.stripe_customer_id,
      payment_methods: this.payment_methods,
      roles: this.roles,
      epopId: this.epopId,
      integrated_source: this.integrated_source,
    };
  };

  getDetails = (forceReload = false) => {
    return new Promise((resolve, reject) => {
      if (!forceReload && this.id && this.email) {
        const storeDetails = this.getUserDetails();
        resolve(storeDetails);
      } else {
        UserService.getUserDetails()
          .then((response) => {
            this.setDetails(response);
            resolve(this.getUserDetails());
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  };

  getReceiptStatistics = () => {
    return new Promise((resolve, reject) => {
      if (!isNull(this.receiptCount)) {
        resolve(this.receiptCount);
      } else {
        MerchantService.getAllReceiptsCount()
          .then((response) => {
            this.receiptCount = response.receipts;
            resolve(response.receipts);
          })
          .catch((err) => {
            this.receiptCount = 0;
            ConsoleSafe.logError('Error in receipt count : ', err);
            reject(err);
          });
      }
    });
  };

  setBusiness(data) {
    this.business = data;
  }

  isUserSet() {
    return !isEmpty(this.id);
  }

  isActiveUser() {
    return this.is_active;
  }

  isUserAgreementAccepted() {
    return this.has_agreed;
  }

  isUserSquarePartner() {
    return upperCase(this.partner_account) === 'SQUARE';
  }

  agreeToAgreement() {
    this.has_agreed = true;
  }
}

export default UserStore;
