import { useState, useEffect } from 'react';

export const useWindowWidth = () => {
  const getWidth = () => window.innerWidth;

  const [screenWidth, setScreenWidth] = useState(getWidth());

  useEffect(() => {
    const updateWidth = () => setScreenWidth(getWidth());

    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return screenWidth;
};
