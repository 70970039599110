import { Col, Row, message } from 'antd';
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';
import { inject, observer } from 'mobx-react';

import MerchantService from '../../api/service/MerchantService';
import OnboardingFooterBtn from './common/OnboardingFooterBtn';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import UrlGenerator from '../../api/UrlGenerator';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import TrackingUtils from '../../utils/TrackingUtils';

@inject('dataStore')
@observer
class OnboardingFeatures extends React.Component {
  constructor(props) {
    super(props);
    //ensures that the back and forth through the process works
    this.state = this.props.location.state || {
      receipts: false,
      offers: false,
      ads: false,
      others: false,
      loading: false,
    };
    //now verify if we did not already recorded the state in the backend,
    //and uses the recorded information otherwise
    //notice that this page must have dataStore.user.business not empty for being reached
    var userBusinessFeatures = this.props.dataStore.user.business.features;
    if (!isEmpty(userBusinessFeatures)) {
      this.state.receipts = !isEmpty(userBusinessFeatures.find((f) => f.type === 'POS_BASED'));
      this.state.offers = !isEmpty(userBusinessFeatures.find((f) => f.type === 'OFFER_BASED'));
      this.state.ads = !isEmpty(userBusinessFeatures.find((f) => f.type === 'ADS_BASED'));
      this.state.others = !isEmpty(userBusinessFeatures.find((f) => f.type === 'OTHERS'));
    }
    //rebinds the routing/navigation history to this state
    this.props.history.replace(this.props.location.pathname, this.state);
  }

  // Called when props change (Props change when Route changes).
  componentDidUpdate(prevProps) {
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.location !== prevProps.location) {
      this.setState(this.props.location.state);
    }
  }

  onSubmit = () => {
    const { receipts, offers, ads, others } = this.state;
    const features = [];
    if (receipts) features.push('POS_BASED');
    if (offers) features.push('OFFER_BASED');
    if (ads) features.push('ADS_BASED');
    if (others) features.push('OTHERS');

    this.setState({ loading: true });
    MerchantService.updateBusiness({
      ':id': this.props.match.params.id,
      features,
      epop_id: this.props.dataStore.user.epopId,
    })
      .then((response) => {
        this.props.dataStore.user.setBusiness(response);
        //this cannot be empty anymore
        var userBusinessFeatures = this.props.dataStore.user.business.features;
        this.setState({
          loading: false,
          receipts: !isEmpty(userBusinessFeatures.find((f) => f.type === 'POS_BASED')),
          offers: !isEmpty(userBusinessFeatures.find((f) => f.type === 'OFFER_BASED')),
          ads: !isEmpty(userBusinessFeatures.find((f) => f.type === 'ADS_BASED')),
          others: !isEmpty(userBusinessFeatures.find((f) => f.type === 'OTHERS')),
        });

        //make sure to rebind (in case of loss of connection or internal refresh)
        this.props.history.replace(this.props.location.pathname, this.state);
        this.props.history.push(
          UrlGenerator.getUiUrlWithPathParams(RouteConstants.ONBOARDING.CHOOSE_POS, { id: this.props.match.params.id }),
        );
      })
      .catch(() => {
        var errMgs = "Couldn't update features at the moment";
        message.error(errMgs);
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    TrackingUtils.eventMerchantOnboardingStep3Features();
  }

  render() {
    let { receipts, offers, ads, others, loading } = this.state;
    return (
      <>
        <SectionHeader
          title='Let us help you get the most out of e.pop!'
          subTitle='Select from our key features below. You can make changes at any
          time from <span class="font-MuliBold">your e.pop Dashboard</span>.'
        />

        <Row>
          <Col span={24} className='mt-3'>
            <div
              onClick={() => {
                this.setState({ receipts: !this.state.receipts });
              }}
              className={receipts ? 'customList-checkbox active' : 'customList-checkbox'}
            >
              <div>
                <p>
                  <span> Smart Receipts </span>- provide more valuable receipts to your customers
                </p>
              </div>
              <div>{receipts ? <FaCheckCircle className='checked' /> : <FaRegCheckCircle className='unchecked' />}</div>
            </div>
            <div
              onClick={() => {
                this.setState({ offers: !this.state.offers });
              }}
              className={offers ? 'customList-checkbox active' : 'customList-checkbox'}
            >
              <div>
                <p>
                  <span> Offers </span> - send promotions & cashback offers to new & existing customers
                </p>
              </div>
              <div>{offers ? <FaCheckCircle className='checked' /> : <FaRegCheckCircle className='unchecked' />}</div>
            </div>
            <div
              onClick={() => {
                this.setState({ ads: !this.state.ads });
              }}
              className={ads ? 'customList-checkbox active' : 'customList-checkbox'}
            >
              <div>
                <p>
                  <span> Advertising </span> - increase brand awareness & reach new customers
                </p>
              </div>
              <div>{ads ? <FaCheckCircle className='checked' /> : <FaRegCheckCircle className='unchecked' />}</div>
            </div>
            <div
              onClick={() => {
                this.setState({ others: !this.state.others });
              }}
              className={others ? 'customList-checkbox active' : 'customList-checkbox'}
            >
              <div>
                <p>
                  <span> Other </span> - reduce costs, provide safe contactless experience
                </p>
              </div>
              <div>{others ? <FaCheckCircle className='checked' /> : <FaRegCheckCircle className='unchecked' />}</div>
            </div>

            <small className='mt-1'>*Please select at least one</small>
          </Col>
        </Row>

        <OnboardingFooterBtn
          text='Continue'
          disabled={!(receipts || offers || ads || others)}
          onClick={this.onSubmit}
          loading={loading}
          showBack={true}
        />
      </>
    );
  }
}

export default SecureComponent(withRouter(OnboardingFeatures));
