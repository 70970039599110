import { Col, Row } from 'antd';
import React, { Component } from 'react';

import BrandingImages from '../../components/common/branding/BrandingImages';
import ForgotPasswordForm from './forgotPassword/ForgotPasswordForm';
import IntroLayout from './common/IntroLayout';
import RouteConstants from '../../constants/RouteConstants';
import SectionHeader from '../../components/common/SectionHeader';
import UnsecureComponent from '../../components/UnsecureComponent';
import { withRouter } from 'react-router-dom';

class ForgotPassword extends Component {
  onSubmit = () => {
    this.props.history.replace(RouteConstants.LOGIN);
  };

  render() {
    return (
      <IntroLayout>
        <SectionHeader title='Forgot Password' subTitle='Enter your email ID to reset your password' />
        <Row>
          <Col span={24}>
            <ForgotPasswordForm redirect={this.onSubmit} />
          </Col>

          <Col span={24}>
            <div className='app-images-wrap wrap-spacer-large pt-lg-5'>
              <BrandingImages />
            </div>
          </Col>
        </Row>
      </IntroLayout>
    );
  }
}

export default UnsecureComponent(withRouter(ForgotPassword));
