let PlanDetails = (props) => {
  return (
    <div className='plan-price'>
      <div>
        <span className='font-MuliBold plan-price-type'>{props.data.plan}</span>
      </div>
      <div>
        {props.data.price === 0.0 ? (
          <div className='price-perMonth-space font-MuliBold'>
            <span>FREE</span>
          </div>
        ) : props.data.price < 10.0 ? (
          <div className='price-perMonth font-MuliBold'>
            <sup className='span'>$</sup>
            <sub className='span'> </sub>
            <sub className='span'> </sub>
            <sub className='span'> </sub>
            <sub className='span'> </sub>
            <span>{props.data.price}</span>
            <sub className='span'> </sub>
            <sub className='span'> </sub>
            <sub className='span'> </sub>
            <sub className='span'> </sub>
            <sub className='span'>/month</sub>
          </div>
        ) : (
          <div className='price-perMonth font-MuliBold'>
            <sup className='span'>$</sup>
            <span>{props.data.price}</span>
            <sub className='span'>/month</sub>
          </div>
        )}
      </div>
    </div>
  );
};
export default PlanDetails;
