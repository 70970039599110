import React, { Component } from 'react';

import BrandingImages from '../../components/common/branding/BrandingImages';
import IntroLayout from './common/IntroLayout';
import SectionHeader from '../../components/common/SectionHeader';
import SignupForm from './signup/SignupForm';
import SecureComponent from '../../components/SecureComponent';
import { withRouter } from 'react-router-dom';
import TrackingUtils from '../../utils/TrackingUtils';

// Handle Square Onboarding Logic
class SecureSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticatedUser: true,
      isPreRegisteredUser: this.props.location.state?.isPreRegisteredUser || false,
    };
  }

  componentDidMount() {
    TrackingUtils.eventMerchantOnboardingStep1YourDetailsSSO();
  }

  render() {
    return (
      <IntroLayout>
        <SectionHeader
          title='Let’s get your account set up.'
          subTitle='Sign up to e.pop and start benefiting from these today:'
        />
        <BrandingImages />
        <SignupForm
          isPreRegisteredUser={this.state.isPreRegisteredUser}
          isAuthenticatedUser={this.state.isAuthenticatedUser}
        />
      </IntroLayout>
    );
  }
}

export default SecureComponent(withRouter(SecureSignup));
