import { Col, Row } from 'antd';
import { inject, observer } from 'mobx-react';

import Loading from '../../../components/Loading';
import OnboardingFooterBtn from '../common/OnboardingFooterBtn';
import PosService from '../../../api/service/PosService';
import React from 'react';
import RouteConstants from '../../../constants/RouteConstants';
import SectionHeader from '../../../components/common/SectionHeader';
import SecureCallbackComponent from '../../../components/SecureCallbackComponent';
import UrlGenerator from '../../../api/UrlGenerator';
import UrlUtils from '../../../utils/UrlUtils';
import { isEmpty } from 'lodash';
import shopifyPosImg from '../../../assets/images/Shopify_POS@2x.png';
import { withRouter } from 'react-router-dom';
import ConsoleSafe from '../../../utils/ConsoleSafe';

@inject('dataStore')
@observer
class OnboardingShopifyCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      directInstall: false,
    };
  }

  componentDidMount() {
    const parameters = UrlUtils.getAllParamsFromUrl(this.props.location.search);
    if (isEmpty(parameters)) {
      const errorObj = {
        type: 'Invalid Request',
        description: 'Please check',
      };
      this.setState({ error: true, errorDetail: errorObj, loading: false });
    } else if (parameters.hasOwnProperty('error')) {
      //Verify if Permission has been granted  or not
      const errorObj = {
        type: parameters.error,
        description: parameters.error_description,
      };
      this.setState({
        error: true,
        errorDetail: errorObj,
        loading: false,
      });
    } else {
      // Check if its an Connect to Shopify request
      if (parameters.hasOwnProperty('code')) {
        PosService.publishShopifyCallbackData(this.props.dataStore.user.isUserSet(), parameters)
          .then(() => {
            this.setState({
              error: false,
              loading: false,
            });
          })
          .catch((err) => {
            const errorObj = {
              type: 'Shopify Connection Failed',
              description:
                err?.data && err.data.message ? err.data.message : 'Please try to connect your Shopify app again',
            };
            this.setState({
              error: true,
              errorDetail: errorObj,
              loading: false,
            });
          });
      } else {
        // Check if this is an installation request from shopify
        PosService.getShopifyOauthUrl({
          shop: parameters.shop,
        })
          .then((response) => {
            window.location = response.url;
          })
          .catch((err) => {
            ConsoleSafe.log(err);
            const errorObj = {
              type: 'Shopify Installation Failed',
              description:
                err?.data && err.data.message ? err.data.message : 'Please try to install the app again from Shopify',
            };
            this.setState({
              error: true,
              errorDetail: errorObj,
              loading: false,
            });
          });
      }
    }
  }

  navigate = async () => {
    this.setState({ navigateLoading: true });
    if (this.props.dataStore.user.isUserSet()) {
      const user = await this.props.dataStore.user.getDetails();
      if (user.business && user.business.default_membership_plan) {
        this.props.history.replace(RouteConstants.MERCHANT.DASHBOARD);
      } else {
        this.props.history.replace(
          UrlGenerator.getUiUrlWithPathParams(RouteConstants.ONBOARDING.EPOP_PLAN, { id: user.business.id }),
        );
      }
    } else {
      //this cannot work anymore
      this.props.history.replace(RouteConstants.SIGNUP + '?r=shopify');
    }
  };

  navigateBack = async () => {
    this.setState({ navigateLoading: true });
    if (this.props.dataStore.user.isUserSet()) {
      const user = await this.props.dataStore.user.getDetails();
      this.props.history.replace(
        UrlGenerator.getUiUrlWithPathParams(RouteConstants.ONBOARDING.CHOOSE_POS, { id: user.business.id }),
      );
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    const { loading, error, errorDetail, navigateLoading } = this.state;
    if (loading) {
      return (
        <>
          <SectionHeader
            title='Connecting to Shopify'
            alignCenter={true}
            subTitle='Trying to connect to your shopify store'
          />

          <Row className='text-center mt-3 mt-lg-5 px-lg-5' gutter={[0, 20]}>
            <Col span={24}>
              <img src={shopifyPosImg} alt='Shopify' className='store-circle' />
            </Col>
            <Col span={24}>
              <Loading tip='Processing request...' noMinHeight={true} />
            </Col>
          </Row>
        </>
      );
    } else if (error) {
      return (
        <>
          <SectionHeader title={errorDetail.type} alignCenter={true} subTitle={errorDetail.description} />

          <Row className='text-center mt-3 mt-lg-5 px-lg-5'>
            <Col span={24}>
              <img src={shopifyPosImg} alt='Shopify' className='store-circle' />
            </Col>
            <Col span={24} className='mt-5'>
              <p className='privacy_des text-light-dark text-center mt-4 mb-5'>
                We were unable to process your request to connect to shopify
              </p>
            </Col>
          </Row>
          <OnboardingFooterBtn
            text='Reconnect to Shopify'
            alignCenter={true}
            loading={navigateLoading}
            onClick={this.navigateBack}
          />
        </>
      );
    } else
      return (
        <>
          <SectionHeader
            title='Congratulations'
            alignCenter={true}
            subTitle='You have successfully connected your Shopify account to e.pop'
          />

          <Row className='text-center mt-3 mt-lg-5 px-lg-5'>
            <Col span={24}>
              <img src={shopifyPosImg} alt='Shopify' className='store-circle' />
            </Col>
            <Col span={24} className='mt-5'>
              <p className='privacy_des text-light-dark text-center mt-4 mb-5'>
                We have created your store from the POS information
              </p>
            </Col>
          </Row>

          <OnboardingFooterBtn text='Continue' alignCenter={true} loading={navigateLoading} onClick={this.navigate} />
        </>
      );
  }
}

export default SecureCallbackComponent(withRouter(OnboardingShopifyCallback));
