import React, { Component } from 'react';
import * as Yup from 'yup';

import { Button, message } from 'antd';
import { Field, Form, Formik } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { inject, observer } from 'mobx-react';

import { AiFillEye } from 'react-icons/ai';
import { BsEyeSlashFill } from 'react-icons/bs';
import ErrorMsgConstants from '../../../constants/ErrorMsgConstants';
import RouteConstants from '../../../constants/RouteConstants';
import firebase from '../../../firebase';
import UserService from '../../../api/service/UserService';
import TrackingUtils from '../../../utils/TrackingUtils';
import ResponseUtils from '../../../utils/ResponseUtils';

const validateSchema = Yup.object().shape({
  email: Yup.string().required('Please enter your email ID').email('Please enter a valid email.'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password between 8 and 20 characters')
    .max(20, 'Password between 8 and 20 characters'),
});

@inject('dataStore')
@observer
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      loading: false,
    };
  }

  toggleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  onSubmit = (values) => {
    if (this.props.reauthenticate) {
      return this.onReauthenticate(values);
    } else {
      return this.onLogin(values);
    }
  };

  onReauthenticate = (values) => {
    this.setState({ loading: true });
    const user = firebase.auth.currentUser;
    const credential = firebase.getCredential(values.email, values.password);
    // Now you can use that to reauthenticate
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        message.success('Reauthentication Successful');
        this.setState({ loading: false });
        this.props.onSuccess();
      })
      .catch(() => {
        message.error('Invalid Credentials');
        firebase.signOut();
        TrackingUtils.resetTracking();
      });
  };

  onLogin = (values) => {
    this.setState({ loading: true });
    firebase
      .signInWithEmailAndPassword(values.email, values.password)
      .then((userCredentials) => {
        message.success('Successfully logged in');
        //check that the user does not exist in our dbs
        const data = { email: values.email };
        UserService.checkStatus(data)
          .then((response) => {
            TrackingUtils.identify(response.epopId, userCredentials.user.emailVerified);
            if (response.isKnownUser === false) {
              //the user is known in SSO but has never been registered or preregistered with this app
              message.warning(
                'Welcome back! You have been identified as an e.pop customer but you need to signup to this app.',
                10,
              );
              this.setState({ loading: false });
              this.props.history.push(RouteConstants.SECURESIGNUP, {
                isPreRegisteredUser: false,
              });
              TrackingUtils.eventSignInToMerchantAppFailed('Tried to log in with a consumer account');
            } else {
              this.setState({ loading: false });
              if (response.isActiveUser) {
                this.props.history.replace({
                  pathname: RouteConstants.REDIRECT,
                  search: this.props.location.search,
                });
                TrackingUtils.eventUserSignedInToMerchantApp();
              } else {
                this.props.history.push(RouteConstants.SECURESIGNUP, {
                  isPreRegisteredUser: true,
                });
                TrackingUtils.eventMerchantOnboardingStartedPreRegistered();
              }
            }
          })
          .catch((err) => {
            //something went wrong when connecting to the API
            const apiErrorMessage = ResponseUtils.extractPublicAPIErrorMessage(err);
            message.error(apiErrorMessage);
            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        var errorMessage = ErrorMsgConstants.LOGIN[error.code] ? ErrorMsgConstants.LOGIN[error.code] : error.message;
        TrackingUtils.eventSignInToMerchantAppFailed(errorMessage);
        message.error(errorMessage);
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, showPassword } = this.state;
    const { reauthenticate } = this.props;

    const shopifyReinstallCookie = this.props.cookies.get('shopify_reinstall');

    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validateSchema}
        onSubmit={this.onSubmit}
      >
        {({ errors, touched }) => (
          <Form className='login-Form'>
            <div className='fieldContainer'>
              <Field
                className='mt-2'
                type='email'
                placeholder={reauthenticate ? 'Registered Email Address' : 'Email Address'}
                name='email'
              />
              <small className='text-danger'>{errors.email && touched.email ? <div>{errors.email}</div> : null}</small>
            </div>
            <div className='fieldContainer mt-3'>
              <span className='eye-show' onClick={this.toggleShowPassword}>
                {showPassword ? <AiFillEye /> : <BsEyeSlashFill />}
              </span>
              <Field
                type={showPassword ? 'text' : 'password'}
                className='mt-2 pr-5'
                placeholder={reauthenticate ? 'Current Passsword' : 'Password'}
                name='password'
              />
              <small className='text-danger'>
                {errors.password && touched.password ? <div>{errors.password}</div> : null}
              </small>
            </div>
            {!reauthenticate && (
              <div className='fieldContainer mt-3 text-right'>
                <Link to={RouteConstants.FORGOT_PASSWORD}>Forgot Password ?</Link>
              </div>
            )}
            <div className='d-flex flex-wrap wrap-spacer-medium pt-1 pb-1'>
              {!reauthenticate && (
                <div className=' terms-conditions mt-2'>
                  {"Don't have an account? "}
                  <Link
                    style={{ color: '#4DA59E', textDecoration: 'none' }}
                    to={shopifyReinstallCookie ? RouteConstants.SHOPIFY_SIGNUP : RouteConstants.SIGNUP}
                  >
                    Sign Up Now
                  </Link>
                </div>
              )}
              <div className='button-wrap ml-auto text-right'>
                <Button htmlType='submit' className='success-submit px-4 px-lg-5' loading={loading}>
                  Sign In
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(withCookies(LoginForm));
