import React, { Component } from 'react';
import { filter, isEmpty, some } from 'lodash';

import RouteConstants from '../../../constants/RouteConstants';
import { Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import { withRouter } from 'react-router-dom';
import UrlGenerator from '../../../api/UrlGenerator';

class AccountSetupCompletePrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [],
      stepExists: false,
      businessId: '',
    };
  }

  async componentDidMount() {
    const steps = [
      {
        name: 'Complete your details',
        route: RouteConstants.MERCHANT.SETTINGS,
        visible: this.checkIfDetailsAreComplete(),
      },
      {
        name: 'Add a new store location',
        route: RouteConstants.ONBOARDING.CHOOSE_POS,
        visible: this.checkIfStoreExists(),
      },
    ];
    const stepExists = some(steps, { visible: true });

    const { merchant } = this.props;

    if (isEmpty(merchant)) {
      this.setState({ steps: steps, stepExists: stepExists });
    } else {
      this.setState({
        steps: steps,
        stepExists: stepExists,
        businessId: merchant.business.id,
      });
    }
  }

  checkIfStoreExists() {
    const { merchant } = this.props;
    return isEmpty(merchant.roles);
  }

  checkIfDetailsAreComplete = () => {
    const { business } = this.props.merchant;
    if (
      business &&
      business.email &&
      business.name &&
      business.address?.address_line_1 &&
      business.address?.city &&
      business.address?.province &&
      business.address?.zip &&
      business.category
    )
      return false;
    return true;
  };

  render() {
    const { steps, stepExists, businessId } = this.state;

    return stepExists ? (
      <>
        <div style={{ fontSize: '1.15rem' }} className='font-muli-Regular text-light-dark text-left mt-2'>
          <p>
            We just need a little more information so you can make the most of the e.pop Integration, we will get you up
            and running soon.
          </p>
        </div>
        <Title className='privacy_heading text-light-dark mt-3 mb-3' level={5}>
          Complete a few more steps to finish setting up your account
        </Title>
        <div className='epop-dashboard-circle-wrap d-flex flex-wrap'>
          <Space size='large'>
            {filter(steps, { visible: true }).map((step, index) => {
              return (
                <div
                  onClick={() => {
                    if (step.route === RouteConstants.ONBOARDING.CHOOSE_POS)
                      this.props.history.push(
                        UrlGenerator.getUiUrlWithPathParams(RouteConstants.ONBOARDING.CHOOSE_POS, { id: businessId }),
                        { isStoreCreation: true },
                      );
                    else this.props.history.push(step.route);
                  }}
                  key={index}
                >
                  <div className='epop-dashboard-circle green'>
                    <p className='bold'>{index + 1}</p>
                    <p className='det'>{step.name}</p>
                  </div>
                </div>
              );
            })}
          </Space>
        </div>
      </>
    ) : (
      <> </>
    );
  }
}

export default withRouter(AccountSetupCompletePrompt);
