import { Column, Row } from 'simple-flexbox';

import React from 'react';
import { Typography } from 'antd';

function Loading(props) {
  return (
    <Row
      vertical='center'
      horizontal='center'
      style={props.noMinHeight ? {} : props.minHeight ? { height: props.minHeight } : { height: '70vh' }}
    >
      <Column vertical='center' horizontal='center'>
        <img src={require('../assets/images/loading.svg').default} alt='Loading' style={{ width: 100, height: 100 }} />
        <Typography.Text type='secondary'>{props.tip ? props.tip : 'Loading ...'}</Typography.Text>
      </Column>
    </Row>
  );
}

export default Loading;
