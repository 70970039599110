import * as Yup from 'yup';

import { Button, Col, Row, message } from 'antd';
import { Country, State } from 'country-state-city';
import { Field, Form, Formik } from 'formik';
import { inject, observer } from 'mobx-react';

import React from 'react';
import StoreService from '../../../api/service/StoreService';

const validateSchema = Yup.object().shape({
  name: Yup.string().required('Store name is required'),
  address_line_1: Yup.string().required('Address Line 1 is required').nullable(),
  city: Yup.string().required('City is required').nullable(),
  province: Yup.string().required('State is required').nullable(),
  country: Yup.string().required('Country is required').nullable(),
  zip: Yup.string().required('ZipCode is required').nullable(),
  email: Yup.string().required('Email is required').email('Please enter a valid email.').nullable(),
  phone: Yup.string().nullable(),
});

@inject('dataStore')
@observer
class StoreContactDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onSubmit = (values) => {
    this.setState({ loading: true });
    StoreService.updateStore(this.props.detail.id, values)
      .then((response) => {
        this.props.dataStore.shop.setDetails(response);
        this.setState({ loading: false });
        this.props.onSubmit();
      })
      .catch(() => {
        message.error("Couldn't update category at the moment");
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { detail, loading } = this.props;
    return (
      <Formik
        initialValues={{
          name: detail.name,
          address_line_1: detail.address.address_line_1,
          address_line_2: detail.address.address_line_2,
          city: detail.address.city,
          province: detail.address.province,
          zip: detail.address.zip,
          country: detail.address.country_code,
          email: detail.email,
          phone: detail.phone,
          facebook: detail.facebook_url,
          instagram: detail.instagram_handle,
          website: detail.website,
        }}
        validationSchema={validateSchema}
        onSubmit={this.onSubmit}
      >
        {({ errors, touched, values }) => {
          return (
            <Form className='login-Form w-75'>
              <Row>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>Store Name</span>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' type='text' placeholder='Store Name' name='name' />
                    <small className='text-danger'>
                      {errors.name && touched.name ? <div>{errors.name}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>Address Line 1</span>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' type='text' placeholder='Address Line 1' name='address_line_1' />
                    <small className='text-danger'>
                      {errors.address_line_1 && touched.address_line_1 ? <div>{errors.address_line_1}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>Address Line 2</span>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' type='text' placeholder='Address Line 2' name='address2' />
                    <small className='text-danger'>
                      {errors.address2 && touched.address2 ? <div>{errors.address2}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>Country</span>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' as='select' placeholder='Country' name='country'>
                      <option>Select</option>
                      {Country.getAllCountries().map((country_, index) => {
                        return (
                          <option value={country_.isoCode} key={index}>
                            {country_.name}
                          </option>
                        );
                      })}
                    </Field>
                    <small className='text-danger'>
                      {errors.country && touched.country ? <div>{errors.country}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>State</span>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' as='select' placeholder='State' name='province'>
                      <option>Select</option>
                      {State.getStatesOfCountry(values.country).map((state, index) => {
                        return (
                          <option value={state.name} key={index}>
                            {state.name}
                          </option>
                        );
                      })}
                    </Field>
                    <small className='text-danger'>
                      {errors.province && touched.province ? <div>{errors.province}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row className='align-items-center'>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>City</span>
                  </div>
                </Col>
                <Col span={7}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' type='text' placeholder='City' name='city' />
                    {/* <Select
                    placeholder='State'
                    style={{ width: '100%', height: '100%' }}
                    onChange={handleChange}
                    className='antdDropDown mt-2'
                  >
                    <Option value='State 1'>State 1</Option>
                  </Select> */}

                    <small className='text-danger'>
                      {errors.city && touched.city ? <div>{errors.city}</div> : null}
                    </small>
                  </div>
                </Col>
                <Col span={4}>
                  <div className='store-field-labels'>
                    <span>Zip</span>
                  </div>
                </Col>
                <Col span={5}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' type='text' placeholder='Zip' name='zip' />
                    <small className='text-danger'>{errors.zip && touched.zip ? <div>{errors.zip}</div> : null}</small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>Email</span>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' type='text' placeholder='email' name='email' />
                    <small className='text-danger'>
                      {errors.email && touched.email ? <div>{errors.email}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>Phone Number</span>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' type='text' placeholder='Phone Number' name='phone' />
                    <small className='text-danger'>
                      {errors.phone && touched.phone ? <div>{errors.phone}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>Facebook</span>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' type='text' placeholder='FB URL' name='facebook' />
                    <small className='text-danger'>
                      {errors.facebook && touched.facebook ? <div>{errors.facebook}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>Instagram</span>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' type='text' placeholder='Instagram Handle' name='instagram' />
                    <small className='text-danger'>
                      {errors.instagram && touched.instagram ? <div>{errors.instagram}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div className='store-field-labels'>
                    <span>Website</span>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='fieldContainer'>
                    <Field className='mt-2' type='text' placeholder='Website URL' name='website' />
                    <small className='text-danger'>
                      {errors.website && touched.website ? <div>{errors.website}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              {/* *********************************** */}
              <Row>
                <Col span={24}>
                  <div className='agree-terms-wrap d-flex align-items-center mt-5'>
                    <div className='button-wrap'>
                      <Button className='gray-submit px-lg-5' onClick={this.props.onCancel} disabled={loading}>
                        Cancel
                      </Button>
                    </div>
                    <div className='button-wrap'>
                      <Button htmlType='submit' className='success-submit px-lg-5' loading={loading}>
                        Save
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default StoreContactDetailsForm;
