import { Button, Col, Row, message } from 'antd';
import { inject, observer } from 'mobx-react';

import Loading from '../../components/Loading';
import MembershipSelect from '../../components/membership/MembershipSelect';
import MerchantService from '../../api/service/MerchantService';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import { withRouter } from 'react-router-dom';
import StringConstants from '../../constants/StringConstants';
import TrackingUtils from '../../utils/TrackingUtils';

const { MEMBERSHIP_PLANS } = StringConstants;

@inject('dataStore')
@observer
class MerchantMembership extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: MEMBERSHIP_PLANS.PREMIUM,
      currentPlan: '',
      loading: true,
      submitLoading: false,
      businessId: null,
    };
  }

  async componentDidMount() {
    TrackingUtils.eventMerchantPagesEPopPlan();
    const { business } = await this.props.dataStore.user.getDetails();
    this.setState({
      selectedPlan: business.default_membership_plan,
      currentPlan: business.default_membership_plan,
      loading: false,
      businessId: business.id,
    });
  }

  onPlanChange = (plan) => {
    this.setState({ selectedPlan: plan });
  };

  onSubmit = () => {
    const { selectedPlan, businessId } = this.state;
    this.setState({ submitLoading: true });
    MerchantService.updateBusiness({
      membership_plan: selectedPlan,
      ':id': businessId,
    })
      .then((response) => {
        this.props.dataStore.user.setBusiness(response);
        this.props.dataStore.shop.reset();
        message.success('Membership plan updated successfully');
        this.setState({ submitLoading: false });
        this.props.history.replace(RouteConstants.MERCHANT.DASHBOARD);
      })
      .catch(() => {
        message.error("Couldn't update membership plan at the moment");
        this.setState({ submitLoading: false });
      });
  };

  render() {
    let { selectedPlan, currentPlan, loading, submitLoading } = this.state;
    return (
      <>
        <SectionHeader
          heading='My Membership Plan'
          subTitle='Choose which plan suits you best. You are not locked into any
                one plan and can change this at anytime.'
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            <MembershipSelect
              plan={selectedPlan}
              onChange={this.onPlanChange}
              upgrade={true}
              currentPlan={currentPlan}
            />

            <Row className='button-wrap wrap-spacer-large d-flex align-items-center'>
              <Col flex='auto'>
                <Button
                  className='success-submit mx-auto ml-lg-auto'
                  onClick={() => this.props.history.push(RouteConstants.MERCHANT.BILLING)}
                >
                  View Billing Information
                </Button>
              </Col>
              <Col flex='auto' className='text-right'>
                <Button type='submit' className='success-submit ' onClick={this.onSubmit} loading={submitLoading}>
                  Save &amp; Exit
                </Button>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default SecureComponent(withRouter(MerchantMembership));
