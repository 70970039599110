import ApiManager from '../ApiManager';
import HttpConstants from '../../constants/HttpConstants';
import UrlConstants from '../../constants/UrlConstants';

export const getAllActiveMemberships = (source) => {
  return ApiManager.makeApiCallWithAuthentication(
    UrlConstants.GET_ACTIVE_MEMBERSHIP_TYPES.USECASE,
    HttpConstants.GET_METHOD,
    {
      source: source,
    },
  );
};

export const createShopifyMembership = (data) => {
  return ApiManager.makeApiCallWithAuthentication(
    UrlConstants.CREATE_SHOPIFY_MEMBERSHIP.USECASE,
    HttpConstants.POST_METHOD,
    data,
  );
};

export const activateShopifyMembership = (data) => {
  return ApiManager.makeApiCallWithAuthentication(
    UrlConstants.ACTIVATE_SHOPIFY_MEMBERSHIP.USECASE,
    HttpConstants.PATCH_METHOD,
    data,
  );
};

export const getActiveMembershipForUser = (user_id) => {
  return ApiManager.makeApiCallWithAuthentication(
    UrlConstants.GET_ACTIVE_MEMBERSHIP_FOR_USER.USECASE,
    HttpConstants.GET_METHOD,
    {
      user_id,
    },
  );
};

export const cancelMembership = (data) => {
  return ApiManager.makeApiCallWithAuthentication(
    UrlConstants.CANCEL_MEMBERSHIP.USECASE,
    HttpConstants.PATCH_METHOD,
    data,
  );
};

export const createStripeCheckoutOrFreeMembership = (data) => {
  return ApiManager.makeApiCallWithAuthentication(
    UrlConstants.CREATE_STRIPE_CHECKOUT.USECASE,
    HttpConstants.POST_METHOD,
    data,
  );
};

export const createStripePaidMembership = (data) => {
  return ApiManager.makeApiCallWithAuthentication(
    UrlConstants.CREATE_STRIPE_PAID_MEMBERSHIP.USECASE,
    HttpConstants.POST_METHOD,
    data,
  );
};

export const cancelStripeMembership = (data) => {
  return ApiManager.makeApiCallWithAuthentication(
    UrlConstants.CANCEL_STRIPE_MEMBERSHIP.USECASE,
    HttpConstants.PATCH_METHOD,
    data,
  );
};
