import { inject, observer } from 'mobx-react';

import MembershipSelect from '../../components/membership/MembershipSelect';
import MerchantService from '../../api/service/MerchantService';
import OnboardingFooterBtn from './common/OnboardingFooterBtn';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

@inject('dataStore')
@observer
class OnboardingMembership extends React.Component {
  constructor(props) {
    super(props);
    //ensures that the back and forth through the process works
    this.state = this.props.location.state || {
      selectedPlan: 'PREMIUM',
      loading: false,
    };
    var alreadyChosenPlan = this.props.dataStore.user.business.default_membership_plan;
    if (!isEmpty(alreadyChosenPlan)) {
      this.state.selectedPlan = alreadyChosenPlan;
    }
    //rebinds the routing/navigation history to this state
    this.props.history.replace(this.props.location.pathname, this.state);
  }

  // Called when props change (Props change when Route changes).
  componentDidUpdate(prevProps) {
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.location !== prevProps.location) {
      this.setState(this.props.location.state);
    }
  }

  onPlanChange = (plan) => {
    this.setState({ selectedPlan: plan });
  };

  onSubmit = () => {
    const { selectedPlan } = this.state;
    this.setState({ loading: true });
    MerchantService.updateBusiness({
      membership_plan: selectedPlan,
      ':id': this.props.match.params.id,
    })
      .then((response) => {
        this.props.dataStore.user.setBusiness(response);
        this.setState({
          loading: false,
          selectedPlan: this.props.dataStore.user.business.default_membership_plan,
        });
        //make sure to rebind (in case of loss of connection or internal refresh)
        this.props.history.replace(this.props.location.pathname, this.state);
        this.props.history.push(RouteConstants.ONBOARDING.PAYMENT_METHODS);
      })
      .catch(() => {
        var errMgs = "Couldn't update membership plan at the moment";
        message.error(errMgs);
        this.setState({ loading: false });
      });
  };

  render() {
    let { selectedPlan, loading } = this.state;
    return (
      <>
        <SectionHeader
          title='Choose your membership plan'
          subTitle='Choose which plan suits you best. It will be applied as a default to each store (please contact us for enterprise plans). Be sure that you are not locked into any
                one plan and can change this at anytime from <span class="font-MuliBold">your e.pop Dashboard</span>, either as a default, or individually for each of your connected store.'
        />
        <MembershipSelect plan={selectedPlan} onChange={this.onPlanChange} />
        <OnboardingFooterBtn text='Next' onClick={this.onSubmit} loading={loading} showBack={true} />
      </>
    );
  }
}

export default SecureComponent(withRouter(OnboardingMembership));
