import { Button, Col, DatePicker, Row, Space, Table, Tooltip, message } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { isEmpty, orderBy } from 'lodash';

import { CloudDownloadOutlined } from '@ant-design/icons';
import ExcelUtils from '../../utils/ExcelUtils';
import SectionHeaderWithStore from '../../components/common/SectionHeaderWithStore';
import SecureComponent from '../../components/SecureComponent';
import StoreService from '../../api/service/StoreService';
import moment from 'moment';
import TrackingUtils from '../../utils/TrackingUtils';

@inject('dataStore')
@observer
class StoreTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      transactions: [],
      startDate: undefined,
      endDate: undefined,
      selectedStartDate: moment().startOf('month'),
      selectedEndDate: moment(),
      downloadLoading: false,
    };
  }

  componentDidMount() {
    TrackingUtils.eventStorePagesTransactions();
    this.props.dataStore.shop.getStoreDetails(this.props.id);
    this.fetchTransactions();
  }

  fetchTransactions = () => {
    const { selectedStartDate, selectedEndDate } = this.state;
    this.setState({ loading: true });
    StoreService.getTransactions(
      this.props.id,
      selectedStartDate.format('MM/DD/YYYY'),
      selectedEndDate.format('MM/DD/YYYY'),
    )
      .then((response) => {
        this.setState({
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          transactions: !isEmpty(response) ? orderBy(response, 'transaction_date', 'desc') : [],
          loading: false,
        });
      })
      .catch(() => {
        message.error('Unable to fetch transactions');
        this.setState({ loading: false, transactions: [] });
      });
  };

  getColumnForTable = () => {
    const columns = [
      {
        key: 'transaction_date',
        dataIndex: 'transaction_date',
        title: 'Date & Time',
        render: (value) => moment(value).format('MMM DD, hh:mm A'),
        sorter: (a, b) => moment(a.transaction_date).diff(moment(b.transaction_date), 'second'),
      },
      {
        key: 'source_id',
        dataIndex: 'source_id',
        title: 'Original Transaction ID',
        render: (value) => value,
      },
      {
        key: 'transaction_id',
        dataIndex: 'transaction_id',
        title: 'e.pop Transaction ID',
        render: (value) => value,
      },
      {
        key: 'epop_customer',
        dataIndex: 'epop_customer',
        title: 'e.pop Customer',
        render: (value) => {
          if (value.includes('-')) {
            return 'YES';
          } else {
            return 'NO';
          }
        },
      },
      {
        key: 'total_amount',
        dataIndex: 'total_amount',
        title: 'Amount',
        render: (value) => value,
        sorter: (a, b) => a.total_amount - b.total_amount,
      },
      {
        key: 'currency',
        dataIndex: 'currency',
        title: 'Currency',
        render: (value) => value,
      },
      {
        key: 'payment_type',
        dataIndex: 'payment_type',
        title: 'Payment Type',
        render: (value) => value,
      },
      {
        key: 'last_4',
        dataIndex: 'last_4',
        title: 'Credit Card (last 4)',
        render: (value) => value,
      },
    ];
    return columns;
  };

  exportDataToExcel = async () => {
    this.setState({ downloadLoading: true });
    const { transactions } = this.state;
    const store = await this.props.dataStore.shop.getStoreDetails(this.props.id);
    const data = [];
    transactions.forEach((transaction) => {
      const obj_ = {
        'Transaction Date': moment(transaction.transaction_date).format('lll'),
        'Source Provider': transaction.source_provider,
        'Source Provider Id': transaction.source_provider_id,
        'Source Transaction Id': transaction.source_id,
        'Customer e.pop ID': transaction.epop_customer,
        'Total Amount': transaction.total_amount,
        Currency: transaction.currency,
        'Type of Payment': transaction.payment_type,
        'Credit Card (last 4)': transaction.last_4,
        'Authorization Id': transaction.authorization_id,
      };
      data.push(obj_);
    });
    ExcelUtils.downloadExcel(data, store.name + '-Transactions');
    this.setState({ downloadLoading: false });
  };

  render() {
    const { loading, transactions, selectedStartDate, selectedEndDate, startDate, endDate, downloadLoading } =
      this.state;
    console.log('*** transactions ***', transactions);
    return (
      <>
        <SectionHeaderWithStore heading='My transactions' />
        <Row className='mt-2 mb-3'>
          <Col xs={24} md={20}>
            <Space>
              <DatePicker.RangePicker
                value={[selectedStartDate, selectedEndDate]}
                onChange={(range) =>
                  this.setState({
                    selectedStartDate: range[0],
                    selectedEndDate: range[1],
                  })
                }
                format={'MM/DD/YYYY'}
                disabledDate={(current) => {
                  // Can not select days before today and today
                  return current && current > moment().endOf('day');
                }}
              />
              <Button
                type='primary'
                className='primary-green-btn'
                onClick={this.fetchTransactions}
                disabled={selectedStartDate.isSame(startDate, 'day') && selectedEndDate.isSame(endDate, 'day')}
                loading={loading}
              >
                Apply
              </Button>
            </Space>
          </Col>
          <Col xs={24} md={4} className='text-right'>
            <Tooltip title='Export data as excel'>
              <Button
                onClick={this.exportDataToExcel}
                icon={<CloudDownloadOutlined />}
                loading={downloadLoading}
                disabled={isEmpty(transactions)}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className='tableReponsive'>
              <Table
                dataSource={transactions}
                className='transaction-table'
                columns={this.getColumnForTable()}
                size='small'
                loading={loading}
                pagination={{
                  showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total}`,
                }}
                rowKey={(row) => row.source_id + row.source_provider_id + row.total_amount + row.currency}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default SecureComponent(StoreTransactions);
