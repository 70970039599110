import { Col, Progress, Row, Typography } from 'antd';
import React, { Component } from 'react';

import RouteConstants from '../../../constants/RouteConstants';
import UrlGenerator from '../../../api/UrlGenerator';
import getSymbolFromCurrency from 'currency-symbol-map';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

const { Title } = Typography;
class MembershipProgressSection extends Component {
  render() {
    const { store } = this.props;
    let membershipDaysLeft = 0,
      percentageProgress = 0;
    if (store.subscription) {
      membershipDaysLeft = moment.unix(store.subscription.trial_end).diff(moment(), 'days');
      percentageProgress = ((365 - membershipDaysLeft) / 365) * 100;
    }
    return (
      !isEmpty(store.subscription) && (
        <Row>
          <Col xs={24}>
            <Title className='privacy_heading text-light-dark mt-5 mb-0 font-weight-bold' level={5}>
              {store.subscription.status === 'trialing' ? 'Membership Trial (12 Months)' : 'Membership Active'}
            </Title>
            <div className='store-monthly dashbordContainerDetails w-100 mt-3'>
              <Row className='mt-3'>
                <Col xs={24} md={18}>
                  <Title className='privacy_heading text-light-dark mb-0 font-weight-bold' level={5}>
                    {store.subscription?.plan && `${membershipDaysLeft} days left`}
                  </Title>
                </Col>
                <Col xs={24} md={6} className='text-right'>
                  <p
                    className='font-16 font-MuliBold underline hyperlink'
                    onClick={() => {
                      const id = this.props.match.params.id;
                      this.props.history.push(
                        UrlGenerator.getUiUrlWithPathParams(RouteConstants.STORE.MEMBERSHIP, {
                          id,
                        }),
                      );
                    }}
                  >
                    Change
                  </p>
                </Col>
              </Row>
              <div className='mt-3'>
                <Progress percent={percentageProgress} status='active' showInfo={false} />
              </div>
              <div className='mt-3 mb-3'>
                <p className='font-16 font-Muli'>
                  {store.subscription?.plan &&
                    `Payment starts ${moment
                      .unix(store.subscription.trial_end)
                      .add(1, 'days')
                      .format('LL')} (${getSymbolFromCurrency(store.subscription.plan.currency)}${(
                      store.subscription.plan.amount / 100
                    ).toFixed(2)}/${store.subscription.plan.interval})`}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      )
    );
  }
}

export default withRouter(MembershipProgressSection);
