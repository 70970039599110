import axios from 'axios';
import { isEmpty } from 'lodash';
import { makeAutoObservable } from 'mobx';
import ConsoleSafe from '../utils/ConsoleSafe';

class ContextStore {
  geolocalisationData = {};

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.geolocalisationData = {};
  }

  setContextDetails(data) {
    this.geolocalisationData = data;
  }

  getContextDetails = () => {
    return {
      geolocalisationData: this.geolocalisationData,
    };
  };

  getDetails = (forceReload = false) => {
    return new Promise((resolve, reject) => {
      if (!forceReload && !isEmpty(this.geolocalisationData)) {
        const contextDetails = this.getContextDetails();
        resolve(contextDetails);
      } else {
        const config = {
          withCredentials: false,
        };
        axios
          .get('https://geolocation-db.com/json/', config)
          .then((response) => {
            ConsoleSafe.log(response.data);
            this.setContextDetails(response.data);
            resolve(this.getContextDetails());
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  };
}

export default ContextStore;
