import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Col, Row } from 'antd';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import TrackingUtils from '../../utils/TrackingUtils';
import IntroLayout from './common/IntroLayout';
import SectionHeader from '../../components/common/SectionHeader';
import RouteConstants from '../../constants/RouteConstants';
import SignupForm from './signup/SignupForm';
import logoBeOne from '../../assets/images/logoBeOne.png';

const ShopifySignup = () => {
  const [cookies, setCookie] = useCookies(['shopify_create']);
  const screenWidth = useWindowWidth();
  const history = useHistory();
  const searchParams = new URLSearchParams(history?.location?.search);
  const shop = searchParams.get('shop');
  const token = searchParams.get('token');
  const expiryDate = new Date();
  expiryDate.setHours(expiryDate.getHours() + 2);

  useEffect(() => {
    TrackingUtils.eventMerchantOnboardingStep1YourDetails();
    TrackingUtils.eventMerchantOnboardingStep1RedirectedFromShopify(shop);
    if (shop && token && !cookies.shopify_create) {
      setCookie('shopify_create', JSON.stringify({ shop, token }), { expires: expiryDate });
    }
  }, []);

  return (
    <IntroLayout>
      <SectionHeader
        title='Next step: connect your e.pop merchant account'
        subTitle='Thank you for installing the e.pop Shopify app. To complete the process please create your e.pop merchant account.'
      />
      <div>
        <p className='terms-conditions'>
          <span className='mr-1'>Already have an account?</span>
          <NavLink
            to={{
              pathname: RouteConstants.LOGIN,
            }}
          >
            Sign In Now
          </NavLink>
        </p>
      </div>
      {screenWidth < 500 ? (
        <Row justify='space-around' align='middle'>
          <Col span='24'>
            <div className='imgAlignCenter mt-3'>
              <img src={logoBeOne} alt='Logo BeOne' />
            </div>
            <div className={`font-muli-Regular text-light-dark text-left mt-1`}>
              <p>
                If you already have a <span className='font-MuliBold'>merchant account</span> at
                <span className='font-MuliBold'> BeOne</span>, our community site, then you can
                <a href='../'> sign in </a>
                with the same email and password.
              </p>
            </div>
          </Col>
        </Row>
      ) : (
        <Row justify='space-around' align='middle'>
          <Col span='16'>
            <div className={`font-muli-Regular text-light-dark text-left mt-3`}>
              <p>
                If you already have a <span className='font-MuliBold'>merchant account</span> at
                <span className='font-MuliBold'> BeOne</span>, our community site, then you can
                <a href='../'> sign in </a>
                with the same email and password.
              </p>
            </div>
          </Col>
          <Col span='8'>
            <div className='img mt-3'>
              <img src={logoBeOne} alt='Logo BeOne' />
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <p>
            <span className='font-MuliBold'>Shopify store: </span>
            {shop || cookies?.shopify?.shop || 'missing shop data'}
          </p>
        </Col>
      </Row>
      <SignupForm shopifyFlow />
    </IntroLayout>
  );
};

export default ShopifySignup;
