const StringConstants = {
  SQUARE_AUTH_STATE: 'Auth_State',
  POS_PROVIDERS: {
    SQUARE: 'SQUARE',
    SHOPIFY: 'SHOPIFY',
  },
  MEMBERSHIP_PLANS: {
    BASE: 'BASE',
    STARTER: 'STARTER',
    PRO: 'PRO',
    PREMIUM: 'PREMIUM',
  },
  COMMUNITY_IMPACT_URL: 'https://epoppay.com/environmental-partners?utm_medium=merchants_signup',
  TERMS_AND_CONDITION_URL: 'https://epoppay.com/merchant-terms-conditions?utm_medium=merchants_signup',
  PRIVACY_POLICY_URL: 'https://epoppay.com/privacy-policy?utm_medium=merchants_signup',
};

export default StringConstants;
