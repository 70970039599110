import { Button, Col, Row } from 'antd';
import { GoCheck, GoX } from 'react-icons/go';
import React, { Component } from 'react';

import MembershipPlans from './MembershipPlanConstants';
import PlanDetails from './membershipSelect/PlanDetails';
import PropTypes from 'prop-types';
import StringConstants from '../../constants/StringConstants';

const { MEMBERSHIP_PLANS } = StringConstants;

const membershipPlanOrder = [
  MEMBERSHIP_PLANS.BASE,
  MEMBERSHIP_PLANS.STARTER,
  MEMBERSHIP_PLANS.PRO,
  MEMBERSHIP_PLANS.PREMIUM,
];

class MembershipSelect extends Component {
  static propTypes = {
    plan: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    upgrade: PropTypes.bool,
    currentPlan: PropTypes.string,
  };

  static defaultProps = {
    upgrade: false,
    plan: MEMBERSHIP_PLANS.PREMIUM,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: props.plan,
    };
  }

  isPlanSelected = (plan) => {
    return this.state.selectedPlan === plan;
  };

  selectPlan = (plan) => {
    this.setState({ selectedPlan: null });
    setTimeout(this.setState({ selectedPlan: plan }), 10);
    this.props.onChange(plan);
  };

  getDisplayForColumn = (value, highlight, index) => {
    if (typeof value === 'boolean') {
      return (
        <div className={`tableCell ${index % 3 === 0 ? 'active' : ''}`} key={index}>
          <span className={`tablePlantick ${value ? 'checked' : 'unchecked'}`}>{value ? <GoCheck /> : <GoX />}</span>
        </div>
      );
    } else {
      return (
        <div className={`tableCell ${index % 3 === 0 ? 'active' : ''}`} key={index}>
          <span className={`tablePlantick checked`}>{highlight ? <strong>{value}</strong> : { value }}</span>
        </div>
      );
    }
  };

  getSelectButton = (plan) => {
    function isUpgrade(selectedPlan, possiblePlan) {
      const selectedPlanIndex = membershipPlanOrder.findIndex((value) => value === selectedPlan);
      const possiblePlanIndex = membershipPlanOrder.findIndex((value) => value === possiblePlan);
      return selectedPlanIndex < possiblePlanIndex;
    }

    return (
      <Button type='submit' className={`btnCustom planSelectbtn ${this.isPlanSelected(plan) ? 'active' : ''}`}>
        {this.props.upgrade
          ? this.state.selectedPlan === plan
            ? this.state.selectedPlan === this.props.currentPlan
              ? 'MY PLAN'
              : 'SELECTED'
            : this.props.currentPlan === plan
            ? 'MY PLAN'
            : isUpgrade(this.state.selectedPlan, plan)
            ? 'UPGRADE'
            : 'DOWNGRADE'
          : this.state.selectedPlan === plan
          ? 'SELECTED'
          : 'SELECT'}
      </Button>
    );
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={4.8}>
            <div className='planListWrap text-left'>
              <div className='Plan-list-container justify-content-end'>
                <div className='plan-list-wrap mt-4 mb-2'>
                  <span className='font-MuliBold font-weight-bold'>Monthly</span>
                </div>
              </div>
              <div className='wrapTableCell'>
                {MembershipPlans.map(({ feature, highlight }, index) => {
                  return (
                    <div className={`tableCell ${index % 3 === 0 ? '' : 'active'} justify-content-start`} key={index}>
                      {highlight ? <strong>{feature}</strong> : feature}
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col span={4.8}>
            <div
              onClick={() => this.selectPlan(MEMBERSHIP_PLANS.BASE)}
              className={`planListWrap text-center ${this.isPlanSelected(MEMBERSHIP_PLANS.BASE) ? 'activeplan' : ''}`}
            >
              <div className='Plan-list-container'>
                <div className='plan-list-wrap'>
                  <div className='BASE-des bg'>
                    <small>MISSION MEMBER</small>
                  </div>
                  <PlanDetails data={{ plan: MEMBERSHIP_PLANS.BASE, price: 0.0 }} />
                </div>
                <div className='button-wrap mt-xl-n2 pb-2'>{this.getSelectButton(MEMBERSHIP_PLANS.BASE)}</div>
              </div>
              <div className='wrapTableCell'>
                {MembershipPlans.map(({ BASE, highlight }, index) => {
                  return this.getDisplayForColumn(BASE, highlight, index);
                })}
              </div>
            </div>
          </Col>
          <Col span={4.8}>
            <div
              onClick={() => this.selectPlan(MEMBERSHIP_PLANS.STARTER)}
              className={`planListWrap text-center ${
                this.isPlanSelected(MEMBERSHIP_PLANS.STARTER) ? 'activeplan' : ''
              }`}
            >
              <div className='Plan-list-container'>
                <div className='plan-list-wrap'>
                  <div className='BASE-des bg'>
                    <small>INTEGRATED MEMBER</small>
                  </div>
                  <PlanDetails data={{ plan: MEMBERSHIP_PLANS.STARTER, price: 4.99 }} />
                </div>
                <div className='button-wrap mt-xl-n2 pb-2'>{this.getSelectButton(MEMBERSHIP_PLANS.STARTER)}</div>
              </div>
              <div className='wrapTableCell'>
                {MembershipPlans.map(({ STARTER, highlight }, index) => {
                  return this.getDisplayForColumn(STARTER, highlight, index);
                })}
              </div>
            </div>
          </Col>
          <Col span={4.8}>
            <div
              onClick={() => this.selectPlan(MEMBERSHIP_PLANS.PRO)}
              className={`planListWrap text-center ${this.isPlanSelected(MEMBERSHIP_PLANS.PRO) ? 'activeplan' : ''}`}
            >
              <div className='Plan-list-container'>
                <div className='plan-list-wrap'>
                  <div className='BASE-des bg'>
                    <small>INTEGRATED MEMBER</small>
                  </div>
                  <PlanDetails data={{ plan: MEMBERSHIP_PLANS.PRO, price: 9.99 }} />
                </div>
                <div className='button-wrap mt-xl-n2 pb-2'>{this.getSelectButton(MEMBERSHIP_PLANS.PRO)}</div>
              </div>
              <div className='wrapTableCell'>
                {MembershipPlans.map(({ PRO, highlight }, index) => {
                  return this.getDisplayForColumn(PRO, highlight, index);
                })}
              </div>
            </div>
          </Col>
          <Col span={4.8}>
            <div
              onClick={() => this.selectPlan(MEMBERSHIP_PLANS.PREMIUM)}
              className={`planListWrap text-center ${
                this.isPlanSelected(MEMBERSHIP_PLANS.PREMIUM) ? 'activeplan' : ''
              }`}
            >
              <div className='Plan-list-container'>
                <div className='plan-list-wrap'>
                  <div className='BASE-des bg'>
                    <small>INTEGRATED MEMBER</small>
                  </div>
                  <PlanDetails data={{ plan: MEMBERSHIP_PLANS.PREMIUM, price: 34.99 }} />
                </div>
                <div className='button-wrap mt-xl-n2 pb-2'>{this.getSelectButton(MEMBERSHIP_PLANS.PREMIUM)}</div>
              </div>
              <div className='wrapTableCell'>
                {MembershipPlans.map(({ PREMIUM, highlight }, index) => {
                  return this.getDisplayForColumn(PREMIUM, highlight, index);
                })}
              </div>
            </div>
          </Col>
          <Col span={24}>
            <small>* additional receipts are charged at 2c each</small>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MembershipSelect;
