import { Col, Row } from 'antd';

import { BsList } from 'react-icons/bs';
import OnboardingMenu from '../../../components/menu/OnboardingMenu';
import StoreCreationMenu from '../../../components/menu/StoreCreationMenu';
import PropTypes from 'prop-types';
import React from 'react';
import RightSideCard from '../../../components/common/RightSideCard';
import RouteConstants from '../../../constants/RouteConstants';
import { findIndex } from 'lodash';
import { withRouter } from 'react-router-dom';

const { ONBOARDING } = RouteConstants;

const steps = [
  { url: ONBOARDING.BUSINESS_DETAILS, step: 1 },
  { url: ONBOARDING.FEATURES, step: 2 },
  { url: ONBOARDING.CHOOSE_POS, step: 3 },
  { url: ONBOARDING.SQUARE_CALLBACK, step: 3 },
  { url: ONBOARDING.SHOPIFY_CALLBACK, step: 3 },
  { url: ONBOARDING.OTHER_POS, step: 3 },
  { url: ONBOARDING.OTHER_POS_SUCCESS, step: 3 },
];

const stepsStoreCreation = [
  { url: ONBOARDING.CHOOSE_POS, step: 1 },
  { url: ONBOARDING.SQUARE_CALLBACK, step: 1 },
  { url: ONBOARDING.SHOPIFY_CALLBACK, step: 1 },
  { url: ONBOARDING.OTHER_POS, step: 1 },
  { url: ONBOARDING.OTHER_POS_SUCCESS, step: 1 },
];

class OnboardingLayout extends React.Component {
  static propTypes = {
    showBackBtn: PropTypes.bool,
  };

  static defaultProps = {
    showBackBtn: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      mobile: false,
      isStoreCreation: this.props.location.state?.isStoreCreation || false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    var x = window.innerWidth;
    if (x < 991) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  showSideBar = (e) => {
    this.setState({
      sidebarVisible: e,
    });
  };

  render() {
    const { url, params } = this.props.match;
    const { mobile, sidebarVisible, isStoreCreation } = this.state;

    let currentSteps = isStoreCreation ? stepsStoreCreation : steps;

    let objIndex = findIndex(currentSteps, (step) => step.url.replace(':id', params.id) === url);

    let stepIndex = -1;
    if (objIndex >= 0) stepIndex = currentSteps[objIndex].step;

    return (
      <>
        {mobile ? (
          <Row>
            <Col span={24}>
              <div className='d-flex'>
                <div className={sidebarVisible ? 'leftBarSliders active' : 'leftBarSliders'}>
                  {isStoreCreation ? (
                    <StoreCreationMenu activeStep={stepIndex} toggleSidebar={this.showSideBar} showBack={mobile} />
                  ) : (
                    <OnboardingMenu activeStep={stepIndex} toggleSidebar={this.showSideBar} showBack={mobile} />
                  )}
                </div>
                <div className='w-100'>
                  <Row className='mb-5'>
                    <Col span={24}>
                      <div className='showNavContainer'>
                        <div onClick={() => this.showSideBar(true)}>
                          <BsList />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <RightSideCard isError={stepIndex === -1}>
                    <div className='wrapperNameCategory px-3'>{this.props.children}</div>
                  </RightSideCard>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className='full-content'>
            <Col md={{ span: 6 }} lg={{ span: 7 }} xxl={{ span: 8 }} span={24} className='left-side-content px-0'>
              {isStoreCreation ? (
                <StoreCreationMenu activeStep={stepIndex} />
              ) : (
                <OnboardingMenu activeStep={stepIndex} />
              )}
            </Col>

            <Col md={{ span: 18 }} lg={{ span: 17 }} xxl={{ span: 16 }} span={24} className='right-side-content'>
              <RightSideCard isError={stepIndex === -1}>
                <div className='wrapperNameCategory px-3'>{this.props.children}</div>
              </RightSideCard>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default withRouter(OnboardingLayout);
