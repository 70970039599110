import ApiManager from '../ApiManager';
import HttpConstants from '../../constants/HttpConstants';
import UrlConstants from '../../constants/UrlConstants';

const MerchantService = {
  getAllCategories: () => {
    return ApiManager.makeApiCallWithAuthentication(UrlConstants.GET_ALL_CATEGORIES.USECASE, HttpConstants.GET_METHOD);
  },
  createBusiness: (values) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.CREATE_BUSINESS.USECASE,
      HttpConstants.POST_METHOD,
      values,
    );
  },
  updateBusiness: (values) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_BUSINESS.USECASE,
      HttpConstants.PATCH_METHOD,
      values,
    );
  },
  uploadBusinessLogo: (file) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPLOAD_BUSINESS_LOGO.USECASE,
      HttpConstants.PATCH_METHOD,
      file,
    );
  },
  posNotConnectedEvent: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.POS_NOT_CONNECTED.USECASE,
      HttpConstants.POST_METHOD,
      data,
    );
  },
  getAllReceiptsCount: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_RECEIPTS_COUNT.USECASE,
      HttpConstants.GET_METHOD,
    );
  },
};

export default MerchantService;
