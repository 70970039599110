import 'semantic-ui-css/semantic.min.css';

import * as Yup from 'yup';

import { /*Col, Row, Upload,*/ message } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';
// import ImgCrop from "antd-img-crop";
import MerchantService from '../../../api/service/MerchantService';
import MiscUtil from '../../../utils/MiscUtils';
import OnboardingFooterBtn from '../common/OnboardingFooterBtn';
import RouteConstants from '../../../constants/RouteConstants';
import UrlGenerator from '../../../api/UrlGenerator';
import { isEmpty } from 'lodash';
// import uploadImge from "../../../assets/images/Top@2x.png";
import { withRouter } from 'react-router-dom';
import ConsoleSafe from '../../../utils/ConsoleSafe';
import TrackingUtils from '../../../utils/TrackingUtils';

const validateSchema = Yup.object().shape({
  name: Yup.string().required('Business name is required.'),
  category: Yup.string().required('Please select one.'),
});

@inject('dataStore')
@observer
class OnboardingBusinessForm extends Component {
  constructor(props) {
    super(props);
    //ensures that the back and forth through the process works
    this.state = this.props.location.state || {
      loading: false,
      fileList: [],
      logoUrl: null,
      categories: [],
      name: '',
      category: '',
    };
    //now verify if we did not already recorded the state in the backend,
    //and uses the recorded information otherwise
    var userBusiness = this.props.dataStore.user.business;
    if (!isEmpty(userBusiness) && !isEmpty(userBusiness.id)) {
      this.state.name = userBusiness.name;
      this.state.category = userBusiness.category.id;
    }
    //rebinds the routing/navigation history to this state
    this.props.history.replace(this.props.location.pathname, this.state);
  }

  componentDidMount() {
    this.setState({ loading: true });
    MerchantService.getAllCategories()
      .then((response) => {
        this.setState({
          categories: MiscUtil.getValuesForCategoryDropdown(response),
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false, categories: [] });
      });
  }

  // Called when props change (Props change when Route changes).
  componentDidUpdate(prevProps) {
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.location !== prevProps.location) {
      this.setState(this.props.location.state);
    }
  }

  onSubmit = (values) => {
    const { fileList } = this.state;
    values.epop_id = this.props.dataStore?.user?.epopId;
    this.setState({ loading: true });
    MerchantService.createBusiness(values)
      .then(async (response) => {
        this.props.dataStore.user.setBusiness(response);
        this.setState({
          name: this.props.dataStore.user.business.name,
          category: this.props.dataStore.user.business.default_category_id,
        });
        let logoS3URL;
        if (!isEmpty(fileList)) {
          try {
            const formData = new FormData();
            formData.append(':id', response.id);
            formData.append('file', fileList[0]);
            logoS3URL = await MerchantService.uploadBusinessLogo(formData);
          } catch (err) {
            ConsoleSafe.log(err);
            message.error("Couldn't upload logo at the moment, try again later");
          }
        }
        this.setState({ loading: false });
        //make sure to rebind (in case of loss of connection or internal refresh)
        this.props.history.replace(this.props.location.pathname, this.state);
        this.props.history.push(
          UrlGenerator.getUiUrlWithPathParams(RouteConstants.ONBOARDING.FEATURES, { id: response.id }),
        );
      })
      .catch((err) => {
        ConsoleSafe.log(err);
        this.setState({ loading: false });
      });
  };

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ logoUrl: reader.result });
    };
    reader.onerror = () => this.setState({ fileList: [], logoUrl: null });
  }

  onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  render() {
    let { loading, /*fileList, logoUrl,*/ categories, name, category } = this.state;
    // const props = {
    //   accept: "image/png, image/jpeg",
    //   onRemove: () => {
    //     this.setState({
    //       fileList: [],
    //       logoUrl: null,
    //     });
    //   },
    //   beforeUpload: (file) => {
    //     const isLt2M = file.size / 1024 / 1024 < 2;
    //     if (!isLt2M) {
    //       message.error("Image must smaller than 2MB!");
    //       return;
    //     }
    //     this.setState({ fileList: [file] });
    //     this.getBase64(file);
    //     return false;
    //   },
    //   fileList,
    // };
    return (
      <Formik
        initialValues={{
          name: name,
          category: category,
        }}
        validationSchema={validateSchema}
        onSubmit={this.onSubmit}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className='login-Form'>
            <div className='pt-4'>
              <div className='fieldContainer'>
                <Field
                  className='ant-input'
                  type='text'
                  placeholder={name ? name : 'Business name'}
                  name='name'
                  disabled={loading}
                />
                <small className='text-danger'>{errors.name && touched.name ? <div>{errors.name}</div> : null}</small>
              </div>

              <div className='fieldContainer mt-5'>
                <p className='mt-5 font-muli-Regular text-light-dark'>What type of business do you run?</p>

                <div className='mt-1'>
                  <Dropdown
                    className='image-drop-down'
                    placeholder='Category of your business'
                    fluid
                    search
                    selection
                    defaultValue={category}
                    options={categories}
                    disabled={loading}
                    onChange={(_, data) => {
                      setFieldValue('category', data.value);
                    }}
                  />
                </div>
                <small className='text-danger'>
                  {errors.category && touched.category ? <div>{errors.category}</div> : null}
                </small>
              </div>

              {/* <Row>
                <Col xl="12">
                  <div className="mt-5">
                    <p className="text-light-dark font-MuliBold">
                      Logo (Optional)
                    </p>
                    <p className="font-muli-Regular text-light-dark">
                      To display your custom branding please upload your logo
                      (ideally in a square format). Upload images less than 2MB
                      in size and in png or jpeg format. You will also be able
                      to apply custom logos for each of your stores later.
                    </p>
                  </div>
                </Col>
                <Col span="24">
                  <div className="image-upload-container">
                    {logoUrl != null && (
                      <img
                        src={logoUrl}
                        className="image-preview-container"
                        alt="uploaded"
                      />
                    )}
                    <ImgCrop
                      rotate
                      quality={0.8}
                      modalTitle={"Crop and select business logo"}
                      modalOk="Select"
                    >
                      <Upload
                        {...props}
                        maxCount={1}
                        onPreview={this.onPreview}
                      >
                        <img
                          src={uploadImge}
                          alt="Click to Upload"
                          className="logo-upload"
                        />
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
              </Row> */}
            </div>

            <OnboardingFooterBtn text='Continue' loading={loading} showBack={false} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(OnboardingBusinessForm);
