import { Col, Row } from 'antd';

import React from 'react';
import TickImge from '../../assets/images/Signup Bar Checked.png';
import leftBackGroundImge from '../../assets/images/Left Menu Blue Circles@2x.png';
import logo from '../../assets/images/Group 1208.png';
import phoneImge from '../../assets/images/Group 22.png';

const IntroMenu = () => {
  return (
    <div
      className='left-img-container intro-left-contaimer'
      style={{
        backgroundImage: `url('${leftBackGroundImge}')`,
      }}
    >
      <>
        <Row>
          <Col span={24}>
            <div className='info-container'>
              <div className='logo-container'></div>
              <div className='logo-container'>
                <img src={logo} alt='e.pop' />
              </div>
            </div>
          </Col>

          <Col span={24}>
            <div className='des-container'>
              <h2>
                Welcome to e.pop <br />
                <span>Your digital partner</span>
              </h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            className='adjustOffset'
            span={24}
            md={{ span: 22, offset: 2 }}
            lg={{ span: 19, offset: 5 }}
            xxl={{ span: 15, offset: 9 }}
          >
            <div className='des-info-container mt-5 mr-0'>
              <div className='des-info-list'>
                <div>
                  <img src={TickImge} alt='Integration' />
                </div>
                <div>
                  <p>One simple integration for instant digital receipts</p>
                </div>
              </div>
              <div className='des-info-list'>
                <div>
                  <img src={TickImge} alt='Engage' />
                </div>
                <div>
                  <p>Engage with customers and grow your business</p>
                </div>
              </div>
              <div className='des-info-list'>
                <div>
                  <img src={TickImge} alt='Reduce Cost' />
                </div>
                <div>
                  <p>Reduce costs and paper waste </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
      <div className='left-img-wrap  mt-5 mb-3'>
        <img src={phoneImge} alt='Mobile App' />
      </div>
    </div>
  );
};

export default IntroMenu;
