import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SecureComponent from '../../../components/SecureComponent';
import SectionHeader from '../../../components/common/SectionHeader';
import styles from './offers.module.scss';
import { inject, observer } from 'mobx-react';

const MerchantOffers = inject('dataStore')(
  observer(({ dataStore }) => {
    const [user, setUser] = useState({});

    useEffect(() => {
      const fetchUser = async () => {
        const userData = await dataStore?.user?.getDetails();
        setUser(userData);
      };
      fetchUser();
    }, []);

    return (
      <>
        <SectionHeader
          heading='Offers'
          subTitle='Want to get your business in front of new customers? Create your offer today!'
        />
        <div className={styles.listContainer}>
          <h4>{"Here's how it works:"}</h4>
          <ul className={styles.list}>
            <li>Submit your offer details</li>
            <li>We will manually review the offers to set them up for success</li>
            <li>Your offer will be visible in the offer section on our mobile app</li>
          </ul>
        </div>
        {user?.business?.default_membership_plan === 'BASE' ? (
          <p className={styles.baseCopy}>
            Offers are available only for the Growth plan. Try our offers for free for 30 days. Start a free trial
            <Link to='/merchant/subscription'> here</Link>! Cancel anytime.
          </p>
        ) : (
          <a
            className={styles.callToAction}
            href='https://form.typeform.com/to/Ykp3mFsH'
            rel='noopener noreferrer'
            target='_blank'
          >
            Create your offer
          </a>
        )}
      </>
    );
  }),
);
export default SecureComponent(withRouter(MerchantOffers));
