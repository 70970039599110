import { Col, Row } from 'antd';
import React, { Component } from 'react';

import { AiOutlineArrowRight } from 'react-icons/ai';
import moment from 'moment';

class UsageStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: moment(),
    };
  }
  render() {
    const { currentDate } = this.state;
    const { stats = {} } = this.props;

    return (
      <Row className='mt-3'>
        <Col span={24}>
          <Row className='p-1 dashbordContainerDetails' style={{ background: '#fff', border: 'none' }}>
            <Col sm={7} span={24} className='epop-dashboard-location'>
              <div className='h-100 d-flex align-items-center justify-content-center'>
                <Row className='align-items-center'>
                  <Col className='font-weight-bold pr-sm-2' style={{ fontSize: '1.75rem' }}>
                    {currentDate.format('DD')}
                  </Col>
                  <Col className='pl-sm-2'>
                    <p className='font-weight-bold m-0'>{currentDate.format('dddd')}</p>
                    <p className='mb-0'>{currentDate.format('MMMM YYYY')}</p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col sm={7} span={24} className='epop-dashboard-location text-center'>
              <div className='h-100 d-flex align-items-center justify-content-center flex-column'>
                <p className='dashborderCus mb-0'>{stats && stats.receipts ? stats.receipts : 0}</p>
                <p className='mb-0' style={{ color: '#4DA59E' }}>
                  e.pop Receipts
                </p>
              </div>
            </Col>
            <Col sm={7} span={24} className='text-center'>
              <div className='h-100 d-flex align-items-center justify-content-center flex-column'>
                <p className='dashborderCus mb-0'>{stats && stats.customers ? stats.customers : 0}</p>
                <p className='mb-0' style={{ color: '#4DA59E' }}>
                  e.pop Customers
                </p>
              </div>
            </Col>
            <Col sm={3} span={24} className='text-center'>
              <div className='d-flex align-items-center justify-content-center'>
                <div className='epop-dashboard-arrow my-3' onClick={this.props.onRightArrowClick}>
                  <AiOutlineArrowRight />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default UsageStatistics;
