import { Col, Row, Upload, message } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ImgCrop from 'antd-img-crop';
import Loading from '../../components/Loading';
import MerchantService from '../../api/service/MerchantService';
import MiscUtil from '../../utils/MiscUtils';
import SectionHeaderWithStore from '../../components/common/SectionHeaderWithStore';
import SecureComponent from '../../components/SecureComponent';
import StoreCategoryForm from './storeDetails/StoreCategoryForm';
import StoreContactDetails from './storeDetails/StoreContactDetails';
import StoreContactDetailsForm from './storeDetails/StoreContactDetailsForm';
import StorePosDetails from './storeDetails/StorePosDetails';
import UrlConstants from '../../constants/UrlConstants';
import UrlGenerator from '../../api/UrlGenerator';
import firebase from '../../firebase';
import uploadImge from '../../assets/images/Top@2x.png';
import { withRouter } from 'react-router-dom';
import TrackingUtils from '../../utils/TrackingUtils';

@inject('dataStore')
@observer
class StoreDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isEditDetails: false,
      categories: [],
      logoUrl: null,
      // Firebase Token stored in state on Upload
    };
  }

  componentDidMount = () => {
    TrackingUtils.eventStorePagesStoreInformation();
    this.setState({ loading: true });
    this.fetchStoreDetails()
      .then((details) => {
        MerchantService.getAllCategories()
          .then((response) => {
            this.setState({
              categories: MiscUtil.getValuesForCategoryDropdown(response),
              loading: false,
              logoUrl: details.logo_url,
            });
          })
          .catch(() => {
            this.setState({
              loading: false,
              categories: [details.category],
              logoUrl: details.logo_url,
            });
          });
      })
      .catch(() => {
        message.error('Store detail not found');
      });
  };

  fetchStoreDetails = (refresh = false) => {
    return this.props.dataStore.shop.getStoreDetails(this.props.id, refresh);
  };

  editDetails = () => {
    this.setState({ isEditDetails: true });
  };

  onEditSubmit = () => {
    this.setState({ isEditDetails: false });
  };

  onEditCancel = () => {
    this.setState({ isEditDetails: false });
  };

  uploadFailed = () => {
    this.setState({ loading: false, firebaseIdToken: null });
    message.error('Logo upload failed');
  };

  uploadSuccess = () => {
    message.success('Logo uploaded successfully');
    this.fetchStoreDetails(true).then((details) =>
      this.setState({
        logoUrl: details.logo_url,
        loading: false,
        firebaseIdToken: null,
      }),
    );
  };

  onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  render() {
    const { isEditDetails, loading, logoUrl, categories, firebaseIdToken } = this.state;

    const uploadProps = {
      beforeUpload: async (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
          return false;
        }
        const firebaseIdToken = await firebase.getIdToken();
        this.setState({ firebaseIdToken });
        return true;
      },
    };

    return (
      <>
        <SectionHeaderWithStore heading='Store information' />
        {loading ? (
          <Loading tip='Fetching Store Details' />
        ) : (
          <Row>
            <Col span={24}>
              <p className='font-MuliBold'>
                <b>Store Contact Details</b>
              </p>
              {!isEditDetails ? (
                <StoreContactDetails detail={this.props.dataStore.shop.getDetails()} onEdit={this.editDetails} />
              ) : (
                <Row>
                  <Col xs={24} lg={20} xxl={18}>
                    <div className='store-container'>
                      <StoreContactDetailsForm
                        detail={this.props.dataStore.shop.getDetails()}
                        loading={loading}
                        onSubmit={this.onEditSubmit}
                        onCancel={this.onEditCancel}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col span={24} className='mt-5'>
              <Row>
                <Col xl='16'>
                  <div>
                    <p className='font-MuliBold'>Logo (Optional)</p>
                    <p className='pr-lg-5'>
                      To display your custom branding please upload your logo (ideally in a square format). Upload
                      images less than 2MB in size and in png or jpeg format.
                    </p>
                  </div>
                </Col>
                <Col span='24'>
                  <div className='image-upload-container'>
                    {logoUrl != null && <img src={logoUrl} className='image-preview-container' alt='uploaded' />}
                    <ImgCrop rotate quality={0.8} modalTitle={'Crop Logo and Upload'} modalOk='Upload'>
                      <Upload
                        {...uploadProps}
                        maxCount={1}
                        accept='image/png, image/jpeg'
                        showUploadList={false}
                        method='PATCH'
                        action={
                          UrlGenerator.updatePathParamsForApiUrl(
                            UrlGenerator.generateUrl(UrlConstants.UPLOAD_STORE_LOGO.USECASE),
                            { ':id': this.props.id },
                          ).url
                        }
                        headers={{
                          Authorization: 'Bearer ' + firebaseIdToken,
                        }}
                        onStart={() => this.setState({ loading: true })}
                        onError={this.uploadFailed}
                        onSuccess={this.uploadSuccess}
                        onPreview={this.onPreview}
                      >
                        <img src={uploadImge} alt='Click to Upload' className='logo-upload' />
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col span={24} className='mt-5'>
              {/* Store POS Details */}
              <StorePosDetails store={this.props.dataStore.shop.getDetails()} />
            </Col>

            <Col span={24} className='mt-3'>
              <StoreCategoryForm
                storeId={this.props.id}
                categories={categories}
                detail={this.props.dataStore.shop.getDetails()}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default SecureComponent(withRouter(StoreDetails));
