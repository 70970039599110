import { Col, Row } from 'antd';

import IntroMenu from '../../../components/menu/IntroMenu';
import { MdArrowBack } from 'react-icons/md';
import PropTypes from 'prop-types';
import React from 'react';
import RightSideCard from '../../../components/common/RightSideCard';
import { withRouter, useHistory } from 'react-router-dom';

const IntroLayout = (props) => {
  const history = useHistory();

  return (
    <Row>
      <Col md={{ span: 7 }} lg={{ span: 7 }} xxl={{ span: 8 }} xs={{ span: 24 }} className='px-0'>
        <IntroMenu />
      </Col>

      <Col md={{ span: 17 }} lg={{ span: 17 }} xxl={{ span: 16 }} span={24}>
        {props.showBackBtn && (
          <Row>
            <Col md={{ span: 24 }}>
              <div className='backArrowWrap'>
                <span
                  onClick={() => {
                    history.goBack();
                  }}
                  className='backArrow'
                >
                  <MdArrowBack />
                </span>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col
            className='adjustOffset'
            md={{ span: 24, offset: 0 }}
            lg={{ span: 22, offset: 1 }}
            xl={{ span: 20, offset: 2 }}
            xxl={{ span: 16, offset: 2 }}
            span={24}
          >
            <RightSideCard noMarginTop={props.showBackBtn} isError={props.isError}>
              {props.children}
            </RightSideCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

IntroLayout.propTypes = {
  showBackBtn: PropTypes.bool,
  isError: PropTypes.bool,
};

IntroLayout.defaultProps = {
  showBackBtn: false,
  isError: false,
};

export default withRouter(IntroLayout);
