import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import IntroLayout from '../pages/auth/common/IntroLayout';
import Loading from './Loading';
import RouteConstants from '../constants/RouteConstants';
import firebase from '../firebase';
import { withRouter } from 'react-router-dom';

const UnsecureComponent = (Page) => {
  @inject('dataStore')
  @observer
  class UnsecureComponentClass extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoggedIn: true,
        isActiveUser: true,
      };
    }

    componentDidMount = () => {
      this.listener = firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.props.dataStore.user
            .getDetails()
            .then((user) => {
              this.setState({ isLoggedIn: true, isActiveUser: user.is_active });
              this.props.history.replace({
                pathname: RouteConstants.REDIRECT,
                search: this.props.location.search,
              });
            })
            .catch(() => {
              this.setState({ isLoggedIn: true, isActiveUser: false });
              this.props.history.replace({
                pathname: RouteConstants.REDIRECT,
                search: this.props.location.search,
              });
            });
        } else {
          this.setState({ isLoggedIn: false, isActiveUser: false });
        }
      });
    };

    componentWillUnmount = () => {
      this.listener();
    };

    render() {
      if (!this.state.isLoggedIn || (this.state.isLoggedIn && !this.state.isActiveUser)) {
        return <Page {...this.state} {...this.props} />;
      }
      return (
        <IntroLayout>
          <Loading tip='Processing ...' />
        </IntroLayout>
      );
    }
  }

  return withRouter(UnsecureComponentClass);
};

export default UnsecureComponent;
