/* eslint-disable no-loop-func */
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import moment from 'moment';

const downloadExcel = (data, filename) => {
  var arrData = typeof data != 'object' ? JSON.parse(data) : data;
  var CSV = [];
  let fileName = filename;
  CSV = arrData;
  let currentDate = moment();
  fileName += ' - ' + moment(currentDate).format('MMM DD, YYYY');
  downloadExcelFile(fileName, CSV);
};

function downloadExcelFile(fileName, data) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(data);

  var range = XLSX.utils.decode_range(ws['!ref']);
  for (var r = range.s.r; r <= range.e.r; r++) {
    for (var c = range.s.c; c <= range.e.c; c++) {
      var cellName = XLSX.utils.encode_cell({ c: c, r: r });
      ws[cellName].z = '@';
    }
  }

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const excelData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(excelData, fileName + fileExtension);
}

const ExcelUtils = {
  downloadExcel,
};

export default ExcelUtils;
