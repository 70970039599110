import React from 'react';
import { Col, Input, Row, message, Typography } from 'antd';
import { inject, observer } from 'mobx-react';
import { isEmpty, upperCase } from 'lodash';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import OnboardingFooterBtn from './common/OnboardingFooterBtn';
import PosService from '../../api/service/PosService';
import RouteConstants from '../../constants/RouteConstants';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import StringConstants from '../../constants/StringConstants';
import MerchantService from '../../api/service/MerchantService';
import UrlUtils from '../../utils/UrlUtils';
import chooseLaterActiveImg from '../../assets/images/Connect_Later_Selected.png';
import chooseLaterImg from '../../assets/images/Connect_Later.png';
import squareActiveImg from '../../assets/images/Square_POS_Selected@2x.png';
import squareImg from '../../assets/images/Square_POS@2x.png';
import shopifyImg from '../../assets/images/Shopify_POS@2x.png';
import shopifyActiveImg from '../../assets/images/Shopify_POS_Selected@2x.png';
import TrackingUtils from '../../utils/TrackingUtils';
import Loading from '../../components/Loading';
import TagManager from 'react-gtm-module';

const { Title } = Typography;

@inject('dataStore')
@observer
class OnboardingChoosePos extends React.Component {
  constructor(props) {
    super(props);
    //ensures that the back and forth through the process works
    this.state = this.props.location.state || {
      selectedPOS: '',
      shopifyDomain: '',
      loading: false,
      squarePartner: false,
      storeExists: false,
      shopifyStoreInstalled: false,
    };
    if (this.checkIfStoreExists()) {
      this.state.storeExists = true;
    }

    //rebinds the routing/navigation history to this state
    this.props.history.replace(this.props.location.pathname, this.state);
  }

  // Called when props change (Props change when Route changes).
  componentDidUpdate(prevProps) {
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.location !== prevProps.location) {
      this.setState(this.props.location.state);
    }
  }

  checkIfStoreExists() {
    const { merchant } = this.props;
    if (isEmpty(merchant)) return false;
    else return isEmpty(merchant.roles);
  }

  async componentDidMount() {
    TrackingUtils.eventMerchantOnboardingStep4Features();

    if (this.props.cookies.get('shopify_create')) {
      await this.installShopifyStoreAndEntities();
    }

    if (this.props.cookies.get('shopify_reinstall')) {
      await this.installShopifyStoreAndEntities();
    }

    this.setState({ loading: true });

    //in case we are coming back to this page, we need to check if we went through the POS connection process or not
    if (!this.state.storeExists) {
      this.setState({ storeExists: this.checkIfStoreExists() });
    }

    let squarePartner = false;
    const partnerAccount = UrlUtils.getParamsFromUrl(this.props.location.search, 'p');
    if (partnerAccount && upperCase(partnerAccount) === 'SQUARE') squarePartner = true;
    await this.props.dataStore.user.getDetails();
    this.setState({ squarePartner, loading: false });
  }

  onSubmit = async () => {
    const { selectedPOS, shopifyDomain } = this.state;
    if (selectedPOS === 'LATER') {
      this.onSkip();
    } else if (selectedPOS === 'square') {
      TrackingUtils.eventMerchantOnboardingStep4POSConnectionStarted(selectedPOS);
      this.processConnectToSquare();
    } else if (selectedPOS === 'shopify' && !isEmpty(shopifyDomain)) {
      TrackingUtils.eventMerchantOnboardingStep4POSConnectionStarted(selectedPOS);
      this.processConnectToShopify();
    }
  };

  onSkip = async () => {
    MerchantService.posNotConnectedEvent({ epop_id: this.props.dataStore.user.epopId });
    const tagManagerArgs = {
      dataLayer: {
        event: 'onboardingCompleted', // event name declared during initialization
        userId: this.props.dataStore.user.epopId,
        // add more data you want here
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    //make sure to rebind (in case of loss of connection or internal refresh)
    this.props.history.replace(this.props.location.pathname, this.state);
    this.props.history.replace(RouteConstants.MERCHANT.DASHBOARD);
    this.setState({ shopifyStoreInstalled: false });
  };

  processConnectToSquare = () => {
    this.setState({ loading: true });
    PosService.getSquareOauthUrl()
      .then((response) => {
        this.setState({ loading: false });
        //make sure to rebind (in case of loss of connection or internal refresh)
        this.props.history.replace(this.props.location.pathname, this.state);
        this.props.cookies.set(StringConstants.SQUARE_AUTH_STATE, response.state, {
          maxAge: 300,
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          secure: true,
          sameSite: 'none',
        });
        window.location = response.url;
      })
      .catch((err) => {
        let errorMsg = 'Cannot connect to square at the moment';
        if (err.data && err.data.message) {
          errorMsg = err.data.message;
        }
        message.error(errorMsg);
        TrackingUtils.eventMerchantOnboardingStep4POSConnectionFailed('square', errorMsg);
        this.setState({ loading: false });
      });
  };

  processConnectToShopify = async () => {
    const { shopifyDomain } = this.state;
    this.setState({ loading: true });
    const user = await this.props.dataStore.user.getDetails();
    const value = {};
    value.shop = shopifyDomain + '.myshopify.com'; // MM: potential issue for custom domain owners to be tested
    value.user_id = user.id;
    try {
      const response = await PosService.getShopifyOauthUrl(value);
      this.setState({ loading: false });
      //make sure to rebind (in case of loss of connection or internal refresh)
      this.props.history.replace(this.props.location.pathname, this.state);
      window.location = response.url;
    } catch (err) {
      let errorMsg = 'Cannot connect to shopify at the moment';
      if (err.data && err.data.message) {
        errorMsg = err.data.message;
      }
      message.error(errorMsg);
      TrackingUtils.eventMerchantOnboardingStep4POSConnectionFailed('shopify', errorMsg);
      this.setState({ loading: false });
    }
  };

  installShopifyStoreAndEntities = async () => {
    this.setState({ loading: true });
    const shopifyCookie = this.props.cookies.get('shopify_create');
    const shopifyReinstallCookie = this.props.cookies.get('shopify_reinstall');
    const userDetails = await this.props.dataStore.user.getDetails();
    if (shopifyCookie) {
      const response = await PosService.createShopifyStoreAndEntities({
        userId: userDetails?.id,
        shop: shopifyCookie?.shop,
        token: shopifyCookie?.token,
      });
      if (response?.success) {
        this.setState({ shopifyStoreInstalled: true });
        this.props.cookies.remove('shopify_create');
        // tracking utils on successfull connection
      }
    } else if (shopifyReinstallCookie) {
      const response = await PosService.reinstallShopifyAppNewBusiness({
        userId: userDetails?.id,
        shop: shopifyReinstallCookie?.shop,
        token: shopifyReinstallCookie?.token,
      });
      if (response?.success) {
        this.setState({ shopifyStoreInstalled: true });
        this.props.cookies.remove('shopify_reinstall');
      }
    }
    this.setState({ loading: false });
  };

  isDisabled = () => {
    const { selectedPOS, shopifyDomain, storeExists } = this.state;
    if (storeExists) return false;
    else if (selectedPOS === 'LATER') return false;
    else if (!selectedPOS) return true;
    else if (selectedPOS === 'shopify') return isEmpty(shopifyDomain);
  };

  navigateToDashboard = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'onboardingCompleted', // event name declared during initialization
        userId: this.props.dataStore.user.epopId,
        // add more data you want here
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    this.props.history.replace(RouteConstants.MERCHANT.DASHBOARD);
  };

  render() {
    const {
      selectedPOS,
      loading,
      /*squarePartner,*/
      shopifyDomain,
      storeExists,
    } = this.state;

    const shopifyCookie = this.props.cookies.get('shopify_create');
    const shopifyReinstallCookie = this.props.cookies.get('shopify_reinstall');

    return (
      <>
        {shopifyCookie || shopifyReinstallCookie || this.state.shopifyStoreInstalled ? (
          <>
            <SectionHeader
              title='We are now connecting your Shopify store'
              subTitle="Please wait while we're registering the account"
            />
            {loading ? (
              <Loading minHeight='30vh' />
            ) : (
              <Row>
                <Col span={24}>
                  <div className={'d-flex justify-content-center'}>
                    <h4>Your store has been successfully registered with your e.pop merchant account.</h4>
                  </div>
                </Col>
              </Row>
            )}
            <OnboardingFooterBtn
              text='Continue to dashboard'
              disabled={loading}
              onClick={this.navigateToDashboard}
              showBack={true}
            />
          </>
        ) : (
          <>
            <SectionHeader
              title='Connect your POS to set up your e.pop account'
              subTitle="Don't worry, we'll show you what to do step by step"
            />
            <Row>
              <Col span={24}>
                <div>
                  <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
                    Enabling you to issue smart receipts at your store(s), we collect transactions within your POS. This
                    permits us to offer you:
                  </p>
                  <ul>
                    <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
                      <li>Customer insights that include buying behavior to help increase sales</li>
                      <li>Provide targeted offers to attract new customers and increase spend from existing</li>
                    </p>
                  </ul>
                  <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
                    This also allows you to build better engagement and communication strategies to grow your business.
                  </p>
                </div>
                <div className={'d-flex space-evenly justify-content-md-evenly pos-type mt-5'}>
                  {!storeExists && (
                    <div className='imgAlignCenter'>
                      {selectedPOS === 'square' ? (
                        <img
                          onClick={() => this.setState({ selectedPOS: '' })}
                          src={squareActiveImg}
                          alt='Square'
                          className='store-circle'
                        />
                      ) : (
                        <img
                          onClick={() =>
                            this.setState({
                              selectedPOS: 'square',
                            })
                          }
                          src={squareImg}
                          alt='Square'
                          className='store-circle'
                        />
                      )}
                    </div>
                  )}

                  {!storeExists && (
                    <div className='imgAlignCenter'>
                      {selectedPOS === 'shopify' ? (
                        <img
                          onClick={() => this.setState({ selectedPOS: '' })}
                          src={shopifyActiveImg}
                          alt='Shopify'
                          className='store-circle'
                        />
                      ) : (
                        <img
                          onClick={() =>
                            this.setState({
                              selectedPOS: 'shopify',
                            })
                          }
                          src={shopifyImg}
                          alt='Shopify'
                          className='store-circle'
                        />
                      )}
                    </div>
                  )}

                  {/* Other POS */}
                  {/* <div className='imgAlignCenter'>
                {selectedPOS === 'others' ? (
                  <img
                    onClick={() => this.setState({ selectedPOS: '' })}
                    src={otherPosActiveImg}
                    alt='Others'
                    className='store-circle'
                  />
                ) : (
                  <img
                    onClick={() => this.setState({ selectedPOS: 'others' })}
                    src={otherPosImg}
                    alt='Others'
                    className='store-circle'
                  />
                )}
              </div> */}

                  {!storeExists && (
                    <div className='imgAlignCenter'>
                      {selectedPOS === 'LATER' ? (
                        <img
                          onClick={() => this.setState({ selectedPOS: '' })}
                          src={chooseLaterActiveImg}
                          alt='Choose Later'
                          className='store-circle'
                        />
                      ) : (
                        <img
                          onClick={() =>
                            this.setState({
                              selectedPOS: 'LATER',
                            })
                          }
                          src={chooseLaterImg}
                          alt='Choose Later'
                          className='store-circle'
                        />
                      )}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className='store-exists'>
              {storeExists && (
                <Col span={24} md={{ span: 12, offset: 6 }} style={{ justifyContent: 'space-evenly' }}>
                  <Title className='privacy_heading text-light-dark mt-5' level={5}>
                    Congratulations, you have already connected a store! We will be able to make modifications to this
                    store or connect additional stores later.
                  </Title>
                </Col>
              )}
            </Row>
            <Row className='mt-5'>
              {!storeExists && selectedPOS === 'shopify' && (
                <Col span={24} md={{ span: 12, offset: 6 }} style={{ justifyContent: 'space-evenly' }}>
                  <p className='privacy_des text-light-dark mb-2'>Please enter your shopify store domain</p>
                  <div>
                    <Input
                      placeholder='Name of Shopify Store'
                      value={shopifyDomain}
                      onChange={(e) => this.setState({ shopifyDomain: e.target.value })}
                      suffix={'.myshopify.com'}
                      className='pos-input'
                    />
                  </div>
                </Col>
              )}
            </Row>
            {selectedPOS === 'square' && (
              <Row className='store-terms'>
                <Col span={24}>
                  <div className='terms-conditions w-100'>
                    We have you covered! Given that your Square account might contains multiple locations, we will
                    create one Store for each Square location. Do not worry, if you do not want to keep all Stores
                    active in e.pop, you will always be able to desactivate them individually later!
                  </div>
                </Col>
              </Row>
            )}
            <OnboardingFooterBtn
              text='Continue'
              disabled={this.isDisabled()}
              loading={loading}
              onClick={this.onSubmit}
              showBack={true}
            />
          </>
        )}
      </>
    );
  }
}

export default SecureComponent(withRouter(withCookies(OnboardingChoosePos)));
