import ApiManager from '../ApiManager';
import HttpConstants from '../../constants/HttpConstants';
import UrlConstants from '../../constants/UrlConstants';

const getUserDetails = () => {
  return ApiManager.makeApiCallWithAuthentication(UrlConstants.GET_USER_DETAIL.USECASE, HttpConstants.GET_METHOD);
};

const updateUserDetails = (data) => {
  return ApiManager.makeApiCallWithAuthentication(UrlConstants.UPDATE_USER_DETAIL.USECASE, HttpConstants.PATCH_METHOD, {
    ...data,
  });
};

const signUp = (data) => {
  return ApiManager.makeApiCall(UrlConstants.CREATE_USER.USECASE, HttpConstants.POST_METHOD, data);
};

const authSignUp = (data) => {
  return ApiManager.makeApiCall(UrlConstants.CREATE_AUTH_USER.USECASE, HttpConstants.POST_METHOD, data);
};

const checkStatus = (data) => {
  return ApiManager.makeApiCall(UrlConstants.CHECK_USER_STATUS.USECASE, HttpConstants.POST_METHOD, data);
};

const activate = (data) => {
  return ApiManager.makeApiCall(UrlConstants.ACTIVATE_USER.USECASE, HttpConstants.POST_METHOD, data);
};

const getLatestAgreement = () => {
  return ApiManager.makeApiCallWithAuthentication(UrlConstants.GET_AGREEMENT.USECASE, HttpConstants.GET_METHOD);
};

const acceptAgreement = (agreementId) => {
  return ApiManager.makeApiCallWithAuthentication(UrlConstants.ACCEPT_AGREEMENT.USECASE, HttpConstants.POST_METHOD, {
    agreement_id: agreementId,
  });
};

const UserService = {
  getUserDetails,
  updateUserDetails,
  authSignUp,
  signUp,
  activate,
  checkStatus,
  getLatestAgreement,
  acceptAgreement,
};

export default UserService;
