import React from 'react';

const MembershipContent = ({ tier }) => {
  const content = {
    base: ['Issue digital receipts', 'Listing on e.pop merchant directory', 'Access to BeOne Shopping Community'],
    growth: [
      'Issue digital receipts',
      'Listing on e.pop merchant directory',
      'Access to BeOne Shopping Community',
      'Send offers to shoppers',
      'Shopper analytics and insights (Coming Soon!)',
      'Priority email support',
      '30-day free trial',
    ],
    ffl: [
      'Enjoy the e.pop merchant offering FREE-FOR-LIFE',
      'Be the first to access new features & services',
      'Contribute to shaping the development of the e.pop offering',
      'Earn rewards for inviting new merchant members & end users',
      'Connect up to five stores under your Founding Member account is the best',
    ],
  };

  return tier === 'GROWTH' ? (
    <div className='epop-dashboard-member mt-3'>
      <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
        <span className='font-MuliBold'>What are Growth Member perks?</span>
      </p>
      <ul>
        <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
          {content.growth.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </p>
      </ul>
    </div>
  ) : tier === 'BASE' ? (
    <div className='epop-dashboard-member mt-3'>
      <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
        <span className='font-MuliBold'>What are Base Member perks?</span>
      </p>
      <ul>
        <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
          {content.base.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </p>
      </ul>
      <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
        <span className='font-MuliBold'>Want to try our offers for free?</span>
      </p>
      <p style={{ fontSize: '1.15rem' }} className='text-left mt-2 mb-2'>
        Start your 30-day free trial today on our Growth plan to try out our offers. Promote your business on our e.pop
        mobile app. Cancel anytime.
      </p>
    </div>
  ) : (
    <div className='epop-dashboard-member mt-3'>
      <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
        <span className='font-MuliBold'>What is the benefit of being a Founding Member?</span>
      </p>
      <ul>
        <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
          {content.ffl.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </p>
      </ul>
    </div>
  );
};

export default MembershipContent;
