import { Col, Descriptions, Row } from 'antd';
import React, { Component } from 'react';

import shopifyPosImg from '../../../../assets/images/Shopify_POS@2x.png';

class ShopifyDetails extends Component {
  render() {
    const { pos } = this.props;
    return (
      <Row className='p-3'>
        <Col xs={24}>
          <div className='flex align-center'>
            <img src={shopifyPosImg} alt='Shopify' className='store-pos-circle mr-3' />
            <p className='font-MuliBold'>Shopify Store</p>
          </div>
        </Col>

        <Col xs={24} className='mt-3'>
          <Descriptions size='small' column={1}>
            <Descriptions.Item label='Business Name'>{pos.name}</Descriptions.Item>

            <Descriptions.Item label='Billing Currency'>{pos.currency}</Descriptions.Item>

            <Descriptions.Item label='Country'>{pos.address.country}</Descriptions.Item>

            <Descriptions.Item label='Shopify Domain'>{pos.platform_identifier}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  }
}

export default ShopifyDetails;
