import { Col, Row } from 'antd';
import React, { Component } from 'react';

import OnboardingFooterBtn from '../common/OnboardingFooterBtn';
import RouteConstants from '../../../constants/RouteConstants';
import SectionHeader from '../../../components/common/SectionHeader';
import otherPosActiveImg from '../../../assets/images/Other_POS_Selected@2x.png';
import { withRouter } from 'react-router-dom';

class OnboardingOtherPosSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    return (
      <>
        <SectionHeader title='Thank you!' subTitle='We will come back shortly with an estimate.' alignCenter={true} />

        <Row className='text-center mt-3 mt-lg-5'>
          <Col span={24} className='mb-5'>
            <img src={otherPosActiveImg} alt='other POS' className='store-circle' />
          </Col>
        </Row>

        <Row>
          <Col className='mt-5' span={24}>
            <p className='privacy_des text-light-dark text-center mb-2'>
              You can still get started and manually create a store from your e.pop Dashboard.
            </p>
          </Col>
        </Row>

        <OnboardingFooterBtn
          text='Continue'
          onClick={() => this.props.history.replace(RouteConstants.ONBOARDING.EPOP_PLAN)}
        />
      </>
    );
  }
}

export default withRouter(OnboardingOtherPosSuccess);
