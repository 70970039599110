import StringConstants from '../../constants/StringConstants';

const { BASE, STARTER, PRO, PREMIUM } = StringConstants.MEMBERSHIP_PLANS;

const MembershipPlans = [
  {
    feature: 'e.pop merchant partner status',
    [[BASE]]: true,
    [[STARTER]]: true,
    [[PRO]]: true,
    [[PREMIUM]]: true,
    highlight: false,
  },
  {
    feature: 'Merchant listing on e.pop website',
    [[BASE]]: true,
    [[STARTER]]: true,
    [[PRO]]: true,
    [[PREMIUM]]: true,
    highlight: false,
  },
  {
    feature: 'Enhanced contactless experience',
    [[BASE]]: false,
    [[STARTER]]: true,
    [[PRO]]: true,
    [[PREMIUM]]: true,
    highlight: false,
  },
  {
    feature: 'e.pop/digital receipts*',
    [[BASE]]: false,
    [[STARTER]]: '250',
    [[PRO]]: '1,000',
    [[PREMIUM]]: '5,000',
    highlight: true,
  },
  {
    feature: 'BeOne community access',
    [[BASE]]: true,
    [[STARTER]]: true,
    [[PRO]]: true,
    [[PREMIUM]]: true,
    highlight: true,
  },
  {
    feature: 'Digital engagement via receipt',
    [[BASE]]: false,
    [[STARTER]]: true,
    [[PRO]]: true,
    [[PREMIUM]]: true,
    highlight: false,
  },
  {
    feature: 'Drive sales with targeted offers',
    [[BASE]]: false,
    [[STARTER]]: true,
    [[PRO]]: true,
    [[PREMIUM]]: true,
    highlight: true,
  },
  {
    feature: 'Enhanced data analytics & insights',
    [[BASE]]: false,
    [[STARTER]]: false,
    [[PRO]]: false,
    [[PREMIUM]]: true,
    highlight: true,
  },
  {
    feature: 'Price per receipt for plan',
    [[BASE]]: false,
    [[STARTER]]: '$0.02',
    [[PRO]]: '$0.01',
    [[PREMIUM]]: '<$0.01',
    highlight: true,
  },
];

export default MembershipPlans;
