import React, { Component } from 'react';

import Barcode from 'react-barcode';
import { Row } from 'antd';
import facebookLogo from '../../assets/images/facebook_icon.png';
import instaLogo from '../../assets/images/instagram_icon.png';
import { isEmpty } from 'lodash';
import logoFooter from '../../assets/images/logo-footer.png';
import mcLogo from '../../assets/images/mcLogo.png';
import moment from 'moment';
import websiteLogo from '../../assets/images/website.png';
import ConsoleSafe from '../../utils/ConsoleSafe';

class ReceiptComponent extends Component {
  render() {
    const {
      categoryId,
      categories,
      show_barcode,
      show_return_policy,
      show_address_line1,
      show_business_name,
      show_city,
      show_state,
      show_phone_number,
      show_website,
      show_instagram,
      show_facebook,
      show_category,
      show_logo,
      return_policy,
      logoUrl,
      contact_details,
      logoUrlType,
      show_marketing_message,
      marketing_message,
      return_policy_message,
      barcodeUUID,
    } = this.props;
    let img_url = '';
    for (var i = 0; i < categories.length; i++) {
      if (categories[i]['key'] === categoryId) {
        img_url = categories[i]['image']['src'];
      }
    }
    ConsoleSafe.logNamedObject('contact details', contact_details);

    return (
      <div>
        <div className='receipt-border pb-4'>
          {show_category && (
            <Row className='flex justify-content-end'>
              {categoryId != null && <img className='float-right ui avatar image m-3' src={img_url} alt='oops'></img>}
            </Row>
          )}
          {show_logo && (
            <Row className='justify-content-center'>
              {logoUrlType ? (
                <div
                  style={{
                    width: 70,
                    height: 70,
                  }}
                  className='image-upload-container mt-1'
                >
                  {logoUrl != null && (
                    <img
                      src={logoUrl}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      className='image-preview-container'
                      alt='uploaded'
                    />
                  )}
                </div>
              ) : (
                <div
                  style={{
                    width: 70,
                    height: 70,
                    padding: 10,
                  }}
                  className='image-upload-container receipt-logo mt-1'
                >
                  {logoUrl != null && (
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      src={logoUrl}
                      className='image-preview-container receipt-logo'
                      alt='uploaded'
                    />
                  )}
                </div>
              )}
            </Row>
          )}
          <div>
            <div className='text-center ml-4 mr-4 mt-3'>
              <sup style={{ fontSize: 26 }} className='privacy_heading text-light-dark'>
                $
              </sup>
              <span className='privacy_heading text-light-dark' style={{ fontSize: '26pt' }}>
                13.09
              </span>
              <hr className='receipt-divider' />
            </div>

            <div className='flex flex-row justify-content-between ml-4 mr-4 mt-3 receipt-item-content'>
              <div>
                <span>Item Name</span> <br />
                <p>(Item description)</p>
              </div>
              <div>
                <span>$12.00</span>
              </div>
            </div>
            <div className='flex flex-row justify-content-between ml-4 mr-4 mt-3 receipt-item-content'>
              <div>
                <span>Subtotal</span> <br />
                <p>Taxes (8.75%)</p>
              </div>
              <div>
                <span>$12.00</span>
                <br />
                <p>$ 1.09</p>
              </div>
            </div>
            <div className=' ml-4 mr-4 mt-3 receipt-item-content'>
              <div className='flex flex-row justify-content-between'>
                <div>
                  <b>Total</b> <br />
                </div>
                <div>
                  <b>$13.09</b>
                </div>
              </div>
            </div>
          </div>
          <div className='ml-4 mr-4 receipt-card-content'>
            <hr className='receipt-divider' />
          </div>

          <div className='text-center ml-4 mr-4 receipt-card-content'>
            <div className='block text-center'>
              {show_barcode && (
                <>
                  <p className='receipt-form-contentsBold mt-4 mb-0' style={{ fontSize: 8 }}>
                    Return Barcode
                  </p>
                  <Barcode value={barcodeUUID} height={25} width={0.38} fontSize={8} margin={0} marginBottom={10} />
                </>
              )}
            </div>
            {show_business_name && <div className='receipt-form-contentsBold text-center '>{contact_details.name}</div>}
            {!isEmpty(contact_details.address) ? (
              <div className='receipt-form-contents   receipt-contact-detail-info'>
                <span>
                  {show_address_line1 && contact_details.address.address_line_1 ? (
                    <span>{contact_details.address.address_line_1}</span>
                  ) : null}
                  {show_address_line1 &&
                  (show_city || show_state) &&
                  contact_details.address.address_line_1 &&
                  contact_details.address.city ? (
                    <span>,</span>
                  ) : null}
                  {show_city && contact_details.address.city ? (
                    <span className='ml-1'>{contact_details.address.city}</span>
                  ) : null}
                  {show_state &&
                  show_city &&
                  (contact_details.address.address_line_1 || contact_details.address.city) &&
                  contact_details.address.province ? (
                    <span>,</span>
                  ) : null}
                  {show_state && contact_details.address.province ? (
                    <span className='ml-1'>{contact_details.address.province}</span>
                  ) : null}
                </span>
              </div>
            ) : null}

            {show_phone_number && <div className='receipt-form-contents text-center'>{contact_details.phone}</div>}
            <div className='receipt-form-contents flex flex-row justify-content-center'>
              {show_website && contact_details.website ? (
                <a href={contact_details.website} rel='noopener noreferrer' target='_blank'>
                  <img style={{ height: '2rem', margin: '0.5rem' }} src={websiteLogo} alt='website'></img>
                </a>
              ) : null}
              {show_facebook && contact_details.facebook_url ? (
                <a href={contact_details.facebook_url} rel='noopener noreferrer' target='_blank'>
                  <img style={{ height: '2rem', margin: '0.5rem' }} src={facebookLogo} alt='facebook'></img>
                </a>
              ) : null}
              {show_instagram && contact_details.instagram_handle ? (
                <a href={contact_details.instagram_handle} rel='noopener noreferrer' target='_blank'>
                  <img style={{ height: '2rem', margin: '0.5rem' }} src={instaLogo} alt='instagram'></img>
                </a>
              ) : null}
            </div>
            {show_business_name ||
            show_address_line1 ||
            show_city ||
            show_state ||
            show_phone_number ||
            show_website ||
            show_instagram ||
            show_facebook ||
            show_barcode ? (
              <hr className='receipt-divider' />
            ) : null}
          </div>
          <div>
            <div className='flex flex-row justify-content-between ml-4 mr-4 receipt-card-content'>
              <div style={{ fontSize: 12 }}>•••4314</div>
              <div>{moment().format(' MMM DD, YYYY LT')}</div>
            </div>
            <div
              className='flex flex-row justify-content-between ml-4 mr-4 receipt-card-content'
              style={{ height: '1.5rem' }}
            >
              <img src={mcLogo} alt='Card issuer' className='receipt-card-issuer'></img>
            </div>
          </div>
          <div className='ml-4 mr-4 receipt-card-content'>
            <hr className='receipt-divider' />
          </div>

          <div className='ml-4 mr-4 pt-2 pb-2'>
            <div className='flex flex-row justify-content-between' style={{ placeItems: 'center' }}>
              <div className='receipt-card-content'>
                <span>AID : </span>
                <span>AID000000123</span>
              </div>

              <div className='flex flex-row' style={{ placeItems: 'center' }}>
                <img style={{ height: 25, width: 85 }} src={logoFooter} alt='e.pop Logo'></img>
              </div>
            </div>
            <div>
              <hr className='receipt-divider' />
            </div>
          </div>
          <div className='ml-4 mr-4 pt-0 pb-0 receipt-card-content'>
            {show_return_policy && (
              <>
                <div className='receipt-return-policy'>
                  <span>Return Policy </span>
                  <span>{return_policy}</span>
                </div>
                <div className='receipt-return-policy justify-content-left'>
                  <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{return_policy_message}</span>
                </div>
              </>
            )}
          </div>
          {show_marketing_message ? (
            <>
              <div className=' ml-4 mr-4'>
                <hr className='receipt-divider' />
              </div>
              <Row className='justify-content-center ml-4 mr-4 receipt-card-content'>
                <div className='text-center'>
                  <span
                    style={{
                      fontFamily: 'SourceSansPro-Bold',
                      color: '#666666 ',
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                    }}
                  >
                    {marketing_message}
                  </span>
                </div>
              </Row>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ReceiptComponent;
