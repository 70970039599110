import { Route, Switch, withRouter } from 'react-router-dom';

import ErrorPage from '../ErrorPage';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import StoreDashboard from './StoreDashboard';
import StoreDetails from './StoreDetails';
import MerchantOffers from '../merchantContent/offers/Offers';
import StoreReceiptSettings from './StoreReceiptSettings';
import StoreTransactions from './StoreTransactions';

const StoreRouter = (props) => {
  const { id } = props.match.params;
  return (
    <Switch>
      <Route exact path={RouteConstants.STORE.DASHBOARD}>
        <StoreDashboard id={id} />
      </Route>
      <Route exact path={RouteConstants.STORE.TRANSACTIONS}>
        <StoreTransactions id={id} />
      </Route>
      <Route exact path={RouteConstants.STORE.RECEIPT_SETTINGS}>
        <StoreReceiptSettings id={id} />
      </Route>
      <Route exact path={RouteConstants.STORE.OFFERS}>
        <MerchantOffers id={id} />
      </Route>
      <Route exact path={RouteConstants.STORE.STORE_INFORMATION}>
        <StoreDetails id={id} />
      </Route>
      <Route exact path={RouteConstants.STORE.RECEIPT_SETTINGS}>
        <StoreDetails id={id} />
      </Route>
      <Route exact path='*'>
        <ErrorPage />
      </Route>
    </Switch>
  );
};

export default withRouter(StoreRouter);
