const identify = (userId, emailVerified) => {
  const props = {
    emailVerified,
  };

  const integrations = {
    integrations: {
      Salesforce: true,
      SalesforceTrigger: '1',
    },
  };

  if (emailVerified === null || emailVerified === undefined) {
    delete props.emailVerified;
  }

  window.analytics.identify(userId, props, integrations);
};

const resetTracking = () => {
  window.analytics.reset();
};

const eventMerchantAppLoginPageViewed = () => {
  window.analytics.page('Merchant app - Login');
};

const eventSignInToMerchantAppFailed = (errorMessage) => {
  window.analytics.track('Sign in to merchant app failed', {
    errorMessage,
  });
};

const eventUserSignedInToMerchantApp = () => {
  window.analytics.track('User signed in to merchant app');
};

const eventMerchantOnboardingStartedPreRegistered = () => {
  window.analytics.track('Merchant onboarding started (pre-registered)');
};

const eventMerchantOnboardingStep1YourDetails = () => {
  window.analytics.page('Merchant onboarding', 'Merchant onboarding - Step 1 - Your details');
};

const eventMerchantOnboardingStep1YourDetailsSSO = () => {
  window.analytics.page('Merchant onboarding', 'Merchant onboarding - Step 1 - Your details (SSO)');
};

const eventMerchantOnboardingStep1RedirectedFromShopify = (shop) => {
  window.analytics.page('Merchant onboarding', `Merchant onboarding - Step 1 - Shopify store: ${shop}`);
};

const eventMerchantOnboardingStep1Completed = (email, firstName, lastName, businessCountry, businessCountryCode) => {
  window.analytics.track('Merchant onboarding - Step 1 completed', {
    email,
    firstName,
    lastName,
    businessCountry,
    businessCountryCode,
  });
};

const eventMerchantOnboardingStep1CompletedSSO = (businessCountry, businessCountryCode) => {
  window.analytics.track('Merchant onboarding - Step 1 completed', {
    businessCountry,
    businessCountryCode,
  });
};

const eventMerchantOnboardingStep1PasswordSetupFailed = () => {
  window.analytics.track('Merchant password setup failed');
};

// membership tracking events

const eventManageMyMembership = () => {
  window.analytics.page('Manage my membership');
};

const eventMembershipOptions = () => {
  window.analytics.page('Membership options');
};

const identifyStep1 = (
  userId,
  email,
  firstName,
  lastName,
  businessCountry,
  businessCountryCode,
  externalUUID,
  legalMerchantTermsAccepted,
  legalPrivacyPolicyAccepted,
  isConsumer,
  isMerchant,
) => {
  const props = {
    email,
    firstName,
    lastName,
    businessCountry,
    businessCountryCode,
    externalUUID,
    legalMerchantTermsAccepted,
    legalPrivacyPolicyAccepted,
    isConsumer,
    isMerchant,
    onboardingStatus: 'In progress - Step 1 completed',
  };

  const integrations = {
    integrations: {
      Salesforce: true,
      SalesforceTrigger: '1',
    },
  };

  if (!email) {
    delete props.email;
  }
  if (!firstName) {
    delete props.firstName;
  }
  if (!lastName) {
    delete props.lastName;
  }
  if (!businessCountry) {
    delete props.businessCountry;
  }
  if (!businessCountryCode) {
    delete props.businessCountryCode;
  }
  if (!externalUUID) {
    delete props.externalUUID;
  }
  if (!legalMerchantTermsAccepted) {
    delete props.legalMerchantTermsAccepted;
  }
  if (!legalPrivacyPolicyAccepted) {
    delete props.legalPrivacyPolicyAccepted;
  }
  if (!isConsumer) {
    delete props.isConsumer;
  } else {
    props.isConsumer = isConsumer === 'true';
  }
  if (!isMerchant) {
    delete props.isMerchant;
  } else {
    props.isMerchant = isMerchant === 'true';
  }

  window.analytics.identify(userId, props, integrations);
};

const eventMerchantOnboardingStep2NameAndCategory = () => {
  window.analytics.page('Merchant onboarding', 'Merchant onboarding - Step 2 - Name and category');
};

const eventMerchantOnboardingStep2Completed = (
  businessName,
  businessCategory,
  businessLogoIsAdded,
  businessLogoURL,
) => {
  window.analytics.track('Merchant onboarding - Step 2 completed', {
    businessName,
    businessCategory,
    businessLogoIsAdded,
    businessLogoURL,
  });
};

const identifyStep2 = (userId, businessName, businessCategory, businessLogoIsAdded, businessLogoURL) => {
  const props = {
    businessName,
    businessCategory,
    businessLogoIsAdded,
    businessLogoURL,
    onboardingStatus: 'Completed',
  };

  const integrations = {
    integrations: {
      Salesforce: true,
      SalesforceTrigger: '1',
    },
  };

  if (!businessName) {
    delete props.businessName;
  }
  if (!businessCategory) {
    delete props.businessCategory;
  }
  if (!businessLogoIsAdded) {
    delete props.businessLogoIsAdded;
  } else {
    props.businessLogoIsAdded = businessLogoIsAdded === 'true';
  }
  if (!businessLogoURL) {
    delete props.businessLogoURL;
  }

  window.analytics.identify(userId, props, integrations);
};

const eventMerchantOnboardingStep3Features = () => {
  window.analytics.page('Merchant onboarding', 'Merchant onboarding - Step 3 - Features');
};

const eventMerchantOnboardingStep3Completed = (
  featureDigitalReceiptsSelected,
  featureOffersSelected,
  featureAdvertisingSelected,
  featureOtherSelected,
) => {
  window.analytics.track('Merchant onboarding - Step 3 completed', {
    featureDigitalReceiptsSelected,
    featureOffersSelected,
    featureAdvertisingSelected,
    featureOtherSelected,
  });
};

const identifyStep3 = (
  userId,
  businessFeatureDigitalReceiptsSelected,
  businessFeatureOffersSelected,
  businessFeatureAdvertisingSelected,
  businessFeatureOtherSelected,
) => {
  const props = {
    businessFeatureDigitalReceiptsSelected,
    businessFeatureOffersSelected,
    businessFeatureAdvertisingSelected,
    businessFeatureOtherSelected,
    onboardingStatus: 'In progress - Step 3 completed',
  };

  const integrations = {
    integrations: {
      Salesforce: true,
      SalesforceTrigger: '1',
    },
  };

  if (!businessFeatureDigitalReceiptsSelected) {
    delete props.businessFeatureDigitalReceiptsSelected;
  } else {
    props.businessFeatureDigitalReceiptsSelected = businessFeatureDigitalReceiptsSelected === 'true';
  }
  if (!businessFeatureOffersSelected) {
    delete props.businessFeatureOffersSelected;
  } else {
    props.businessFeatureOffersSelected = businessFeatureOffersSelected === 'true';
  }
  if (!businessFeatureAdvertisingSelected) {
    delete props.businessFeatureAdvertisingSelected;
  } else {
    props.businessFeatureAdvertisingSelected = businessFeatureAdvertisingSelected === 'true';
  }
  if (!businessFeatureOtherSelected) {
    delete props.businessFeatureOtherSelected;
  } else {
    props.businessFeatureOtherSelected = businessFeatureOtherSelected === 'true';
  }

  window.analytics.identify(userId, props, integrations);
};

const eventMerchantOnboardingStep4Features = () => {
  window.analytics.page('Merchant onboarding', 'Merchant onboarding - Step 4 - Create your store');
};

const eventMerchantOnboardingStep4POSConnectionStarted = (POSName) => {
  window.analytics.track('Merchant onboarding - POS connection started', {
    POSName,
  });
};

const eventMerchantOnboardingStep4POSConnectionShopify = (shop) => {
  window.analytics.track('Merchant onboarding - POS connection for shopify store started', {
    shop,
  });
};

const eventMerchantOnboardingStep4POSConnected = (POSName) => {
  window.analytics.track('Merchant onboarding - POS connected', {
    POSName,
  });
};

const eventMerchantOnboardingStep4POSConnectionFailed = (POSName, POSError) => {
  window.analytics.track('Merchant onboarding - POS connection failed', {
    POSName,
    POSError,
  });
};

const identifyStep4POSConnected = (
  userId,
  businessPOSConnected,
  businessPOSName,
  businessPOSBusinessName,
  businessPOSCountryCode,
  businessPOSAddressLine1,
  businessPOSAddressLine2,
  businessPOSAddressLine3,
  businessPOSCity,
  businessPOSState,
  businessPOSPostcode,
  businessPOSStoreCountTotal,
  businessPOSStoreCountActive,
  businessPOSStoreCountInactive,
) => {
  const props = {
    businessPOSConnected,
    businessPOSName,
    businessPOSBusinessName,
    businessPOSCountryCode,
    businessPOSAddressLine1,
    businessPOSAddressLine2,
    businessPOSAddressLine3,
    businessPOSCity,
    businessPOSState,
    businessPOSPostcode,
    businessPOSStoreCountTotal,
    businessPOSStoreCountActive,
    businessPOSStoreCountInactive,
  };

  const integrations = {
    integrations: {
      Salesforce: true,
      SalesforceTrigger: '1',
    },
  };

  if (!businessPOSConnected) {
    delete props.businessPOSConnected;
  } else {
    props.businessPOSConnected = businessPOSConnected === 'true';
  }
  if (!businessPOSName) {
    delete props.businessPOSName;
  }
  if (!businessPOSBusinessName) {
    delete props.businessPOSBusinessName;
  }
  if (!businessPOSCountryCode) {
    delete props.businessPOSCountryCode;
  }
  if (!businessPOSAddressLine1) {
    delete props.businessPOSAddressLine1;
  }
  if (!businessPOSAddressLine2) {
    delete props.businessPOSAddressLine2;
  }
  if (!businessPOSAddressLine3) {
    delete props.businessPOSAddressLine3;
  }
  if (!businessPOSCity) {
    delete props.businessPOSCity;
  }
  if (!businessPOSState) {
    delete props.businessPOSState;
  }
  if (!businessPOSPostcode) {
    delete props.businessPOSPostcode;
  }
  if (businessPOSStoreCountTotal !== 0 && !businessPOSStoreCountTotal) {
    delete props.businessPOSStoreCountTotal;
  }
  if (businessPOSStoreCountActive !== 0 && !businessPOSStoreCountActive) {
    delete props.businessPOSStoreCountActive;
  }
  if (businessPOSStoreCountInactive !== 0 && !businessPOSStoreCountInactive) {
    delete props.businessPOSStoreCountInactive;
  }

  window.analytics.identify(userId, props, integrations);
};

const eventMerchantOnboardingStep4Completed = (POSConnected, POSName) => {
  const props = {
    POSConnected,
    POSName,
  };

  if (!POSName) {
    delete props.POSName;
  }

  window.analytics.track('Merchant onboarding - Step 4 completed', props);
};

const identifyStep4 = (userId, businessPOSConnected) => {
  const props = {
    businessPOSConnected,
    planName: 'Base',
    onboardingStatus: 'Completed',
  };

  const integrations = {
    integrations: {
      Salesforce: true,
      SalesforceTrigger: '1',
    },
  };

  if (!businessPOSConnected) {
    delete props.businessPOSConnected;
  } else {
    props.businessPOSConnected = businessPOSConnected === 'true';
  }

  window.analytics.identify(userId, props, integrations);
};

const eventMerchantPagesDashboard = () => {
  window.analytics.page('Merchant pages', 'Dashboard');
};

const eventMerchantPagesManageMyStore = () => {
  window.analytics.page('Merchant pages', 'Manage my store');
};

const eventMerchantPagesEPopPlan = () => {
  window.analytics.page('Merchant pages', 'e.pop plan');
};

const eventMerchantPagesPaymentMethod = () => {
  window.analytics.page('Merchant pages', 'Billing');
};

const eventMerchantPagesSubscriptions = () => {
  window.analytics.page('Merchant pages', 'Subscriptions');
};

const eventMerchantPagesReceiptPreferences = () => {
  window.analytics.page('Merchant pages', 'Receipt preferences');
};

const eventMerchantPagesReceiptAccountSettings = () => {
  window.analytics.page('Merchant pages', 'Account settings');
};

const eventStorePagesStoreDashboard = () => {
  window.analytics.page('Store pages', 'Store dashboard');
};

const eventStorePagesTransactions = () => {
  window.analytics.page('Store pages', 'Transactions');
};

const eventStorePagesEPopPlan = () => {
  window.analytics.page('Store pages', 'e.pop plan');
};

const eventStorePagesPaymentMethod = () => {
  window.analytics.page('Store pages', 'Billing');
};

const eventStorePagesReceiptPreferences = () => {
  window.analytics.page('Store pages', 'Receipt preferences');
};

const eventStorePagesOffers = () => {
  window.analytics.page('Store pages', 'Offers');
};

const eventStorePagesStoreInformation = () => {
  window.analytics.page('Store pages', 'Store information');
};

const eventMerchantDetailsUpdated = (
  businessName,
  businessAddressStreet,
  businessAddressStreet2,
  businessAddressState,
  businessAddressCity,
  businessAddressPostalCode,
  firstName,
  lastName,
  phone,
  website,
  socialInstagram,
  socialFacebook,
  businessCategory,
) => {
  const props = {
    businessName,
    businessAddressStreet,
    businessAddressStreet2,
    businessAddressState,
    businessAddressCity,
    businessAddressPostalCode,
    firstName,
    lastName,
    phone,
    website,
    socialInstagram,
    socialFacebook,
    businessCategory,
  };

  if (!businessName) {
    delete props.businessName;
  }
  if (!businessAddressStreet) {
    delete props.businessAddressStreet;
  }
  if (!businessAddressStreet2) {
    delete props.businessAddressStreet2;
  }
  if (!businessAddressState) {
    delete props.businessAddressState;
  }
  if (!businessAddressCity) {
    delete props.businessAddressCity;
  }
  if (!businessAddressPostalCode) {
    delete props.businessAddressPostalCode;
  }
  if (!firstName) {
    delete props.firstName;
  }
  if (!lastName) {
    delete props.lastName;
  }
  if (!phone) {
    delete props.phone;
  }
  if (!website) {
    delete props.website;
  }
  if (!socialInstagram) {
    delete props.socialInstagram;
  }
  if (!socialFacebook) {
    delete props.socialFacebook;
  }
  if (!businessCategory) {
    delete props.businessCategory;
  }

  window.analytics.track('Merchant details updated', props);
};

const identifyMerchantDetailsUpdated = (
  userId,
  businessName,
  businessAddressStreet,
  businessAddressStreet2,
  businessAddressState,
  businessAddressCity,
  businessAddressPostalCode,
  firstName,
  lastName,
  phone,
  website,
  socialInstagram,
  socialFacebook,
  businessCategory,
) => {
  const props = {
    businessName,
    businessAddressStreet,
    businessAddressStreet2,
    businessAddressState,
    businessAddressCity,
    businessAddressPostalCode,
    firstName,
    lastName,
    phone,
    website,
    socialInstagram,
    socialFacebook,
    businessCategory,
  };

  const integrations = {
    integrations: {
      Salesforce: true,
      SalesforceTrigger: '1',
    },
  };

  if (!businessName) {
    delete props.businessName;
  }
  if (!businessAddressStreet) {
    delete props.businessAddressStreet;
  }
  if (!businessAddressStreet2) {
    delete props.businessAddressStreet2;
  }
  if (!businessAddressState) {
    delete props.businessAddressState;
  }
  if (!businessAddressCity) {
    delete props.businessAddressCity;
  }
  if (!businessAddressPostalCode) {
    delete props.businessAddressPostalCode;
  }
  if (!firstName) {
    delete props.firstName;
  }
  if (!lastName) {
    delete props.lastName;
  }
  if (!phone) {
    delete props.phone;
  }
  if (!website) {
    delete props.website;
  }
  if (!socialInstagram) {
    delete props.socialInstagram;
  }
  if (!socialFacebook) {
    delete props.socialFacebook;
  }
  if (!businessCategory) {
    delete props.businessCategory;
  }

  window.analytics.identify(userId, props, integrations);
};

const identifyUTMParameters = (
  campaignLastSource,
  campaignLastMedium,
  campaignLastName,
  campaignLastTerm,
  campaignLastContent,
) => {
  const props = {
    campaignLastSource,
    campaignLastMedium,
    campaignLastName,
    campaignLastTerm,
    campaignLastContent,
  };

  if (!campaignLastSource) {
    delete props.campaignLastSource;
  }
  if (!campaignLastMedium) {
    delete props.campaignLastMedium;
  }
  if (!campaignLastName) {
    delete props.campaignLastName;
  }
  if (!campaignLastTerm) {
    delete props.campaignLastTerm;
  }
  if (!campaignLastContent) {
    delete props.campaignLastContent;
  }

  if (props) {
    window.analytics.identify(null, props);
  }
};

const TrackingUtils = {
  identify,
  resetTracking,
  eventMerchantAppLoginPageViewed,
  eventSignInToMerchantAppFailed,
  eventUserSignedInToMerchantApp,
  eventMerchantOnboardingStartedPreRegistered,
  eventMerchantOnboardingStep1YourDetails,
  eventMerchantOnboardingStep1Completed,
  eventMerchantOnboardingStep1PasswordSetupFailed,
  eventMerchantOnboardingStep1YourDetailsSSO,
  eventMerchantOnboardingStep1RedirectedFromShopify,
  identifyStep1,
  eventMerchantOnboardingStep1CompletedSSO,
  eventMerchantOnboardingStep2NameAndCategory,
  eventMerchantOnboardingStep2Completed,
  identifyStep2,
  eventMerchantOnboardingStep3Features,
  eventMerchantOnboardingStep3Completed,
  identifyStep3,
  eventMerchantOnboardingStep4Features,
  eventMerchantOnboardingStep4POSConnectionStarted,
  eventMerchantOnboardingStep4POSConnectionShopify,
  eventMerchantOnboardingStep4POSConnectionFailed,
  eventMerchantOnboardingStep4Completed,
  identifyStep4,
  eventMerchantOnboardingStep4POSConnected,
  identifyStep4POSConnected,
  eventMerchantPagesDashboard,
  eventMerchantPagesManageMyStore,
  eventMerchantPagesEPopPlan,
  eventMerchantPagesPaymentMethod,
  eventMerchantPagesSubscriptions,
  eventMerchantPagesReceiptPreferences,
  eventMerchantPagesReceiptAccountSettings,
  eventStorePagesStoreDashboard,
  eventStorePagesTransactions,
  eventStorePagesEPopPlan,
  eventStorePagesPaymentMethod,
  eventStorePagesReceiptPreferences,
  eventStorePagesOffers,
  eventStorePagesStoreInformation,
  eventMerchantDetailsUpdated,
  identifyMerchantDetailsUpdated,
  eventManageMyMembership,
  eventMembershipOptions,
  identifyUTMParameters,
};

export default TrackingUtils;
