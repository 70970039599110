import * as Yup from 'yup';

import { Button, Col, Modal, Row, Typography, message } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';

import { AiFillEye } from 'react-icons/ai';
import { BsEyeSlashFill } from 'react-icons/bs';
import { MdArrowBack } from 'react-icons/md';
import firebase from '../../../firebase';

const { Title } = Typography;

const validateSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Please enter your new password')
    .min(8, 'Password between 8 and 20 characters')
    .max(20, 'Password between 8 and 20 characters'),
  confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
    };
  }

  onSubmit = (values) => {
    if (values.newPassword === values.confirmNewPassword) {
      this.setState({ loading: true });
      firebase
        .updatePassword(values.newPassword)
        .then(() => {
          message.success('Password updated successfully');
          this.setState({ loading: false });
          this.props.onCancel();
        })
        .catch((err) => {
          let errorMessage = 'Unable to update password at the moment';
          if (err.code === 'auth/requires-recent-login') errorMessage = 'Please relogin to update password';
          message.error(errorMessage);
          this.setState({ loading: false });
        });
    }
  };

  toggleShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  toggleShowConfirmNewPassword = () => {
    this.setState({
      showConfirmNewPassword: !this.state.showConfirmNewPassword,
    });
  };

  render() {
    const { loading, showNewPassword, showConfirmNewPassword } = this.state;

    const { isVisible } = this.props;

    return (
      <Modal
        visible={isVisible}
        className='epop-modal'
        maskClosable={false}
        closable={false}
        footer={null}
        title={
          <Row gutter={[20, 0]} className='align-center'>
            <Col span='auto'>
              <div className='backArrowWrap'>
                <span
                  onClick={() => {
                    this.props.onCancel();
                  }}
                  className='backArrow  m-0'
                >
                  <MdArrowBack />
                </span>
              </div>
            </Col>
            <Col span='auto'>
              <Title className='privacy_heading text-light-dark mb-0' level={4}>
                Change Password
              </Title>
            </Col>
          </Row>
        }
      >
        <Row style={{ paddingLeft: 30, paddingRight: 30 }}>
          <Col span={20} offset={2}>
            <Formik
              initialValues={{
                newPassword: '',
                confirmNewPassword: '',
              }}
              validationSchema={validateSchema}
              onSubmit={this.onSubmit}
            >
              {({ errors, touched }) => {
                return (
                  <Form className='login-Form'>
                    <div className='fieldContainer mt-3'>
                      <span className='eye-show' onClick={this.toggleShowNewPassword}>
                        {showNewPassword ? <AiFillEye /> : <BsEyeSlashFill />}
                      </span>
                      <Field
                        type={showNewPassword ? 'text' : 'password'}
                        className='mt-2 pr-5'
                        placeholder='New Password'
                        name='newPassword'
                      />
                      <small className='text-danger'>
                        {errors.newPassword && touched.newPassword ? <div>{errors.newPassword}</div> : null}
                      </small>
                    </div>
                    <div className='fieldContainer mt-3'>
                      <span className='eye-show' onClick={this.toggleShowConfirmNewPassword}>
                        {showConfirmNewPassword ? <AiFillEye /> : <BsEyeSlashFill />}
                      </span>
                      <Field
                        type={showConfirmNewPassword ? 'text' : 'password'}
                        className='mt-2 pr-5'
                        placeholder='Confirm New Password'
                        name='confirmNewPassword'
                      />
                      <small className='text-danger'>
                        {errors.confirmNewPassword && touched.confirmNewPassword ? (
                          <div>{errors.confirmNewPassword}</div>
                        ) : null}
                      </small>
                    </div>

                    <div className='button-wrap ml-auto text-center mt-5'>
                      <Button htmlType='submit' className='success-submit px-4 px-lg-5' loading={loading}>
                        Change Password
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default ChangePasswordModal;
