import React from 'react';
import styles from './index.module.scss';

export const Spinner = (props) => (
  <div className={styles.spinner}>
    <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='50' cy='50' r='46' fill='none' stroke='currentColor' strokeWidth='8px' />
    </svg>
  </div>
);
