import StoreService from '../api/service/StoreService';
import { isEmpty } from 'lodash';
import { makeAutoObservable } from 'mobx';
import ConsoleSafe from '../utils/ConsoleSafe';

class ShopStore {
  id = null;
  name = null;
  email = null;
  billing_currency = null;
  phone = null;
  logo_url = null;
  website = null;
  facebook_url = null;
  instagram_handle = null;
  is_active = true;
  address = {};
  category = {};
  membership_plan = null;
  features = [];
  merchant_pos = [];
  monthly_allowance = {};
  subscription = {};

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.id = null;
    this.name = null;
    this.email = null;
    this.billing_currency = null;
    this.phone = null;
    this.logo_url = null;
    this.website = null;
    this.facebook_url = null;
    this.instagram_handle = null;
    this.is_active = true;
    this.address = {};
    this.category = {};
    this.pointOfSales = [];
    this.membership_plan = null;
    this.features = [];
    this.monthly_allowance = {};
    this.subscription = {};
  }

  setDetails(data) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.billing_currency = data.billing_currency;
    this.phone = data.phone;
    this.logo_url = data.logo_url;
    this.website = data.website;
    this.facebook_url = data.facebook_url;
    this.instagram_handle = data.instagram_handle;
    this.is_active = data.is_active;
    this.address = data.address;
    this.category = data.category;
    this.pointOfSales = data.pointOfSales || [];
    this.membership_plan = data.membership_plan;
    this.features = data.features;
    this.monthly_allowance = {};
    this.subscription = data.subscription;
  }

  getDetails = () => {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      billing_currency: this.billing_currency,
      phone: this.phone,
      logo_url: this.logo_url,
      website: this.website,
      facebook_url: this.facebook_url,
      instagram_handle: this.instagram_handle,
      is_active: this.is_active,
      address: this.address,
      category: this.category,
      pointOfSales: this.pointOfSales,
      membership_plan: this.membership_plan,
      features: this.features,
      subscription: this.subscription,
    };
  };

  getStoreDetails = (storeId, refresh = false) => {
    return new Promise((resolve, reject) => {
      if (storeId === this.id && !refresh) {
        const storeDetails = this.getDetails();
        resolve(storeDetails);
      } else {
        StoreService.getStoreDetail(storeId)
          .then((response) => {
            this.setDetails(response);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  };

  getMonthlyAllowance = () => {
    return new Promise((resolve, reject) => {
      if (!isEmpty(this.monthly_allowance)) {
        resolve(this.monthly_allowance);
      } else {
        StoreService.getMonthlyUsageStats(this.id)
          .then((response) => {
            this.monthly_allowance = response;
            resolve(response);
          })
          .catch((err) => {
            this.monthly_allowance = {};
            ConsoleSafe.logError('Error in receipt count : ', err);
            reject(err);
          });
      }
    });
  };
}

export default ShopStore;
