import 'antd/dist/antd.css';
import './App.css';
import './defaults.css';

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorPage from './pages/ErrorPage';
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/Login';
import MerchantContent from './pages/MerchantContent';
import OnboardingContent from './pages/OnboardingContent';
import PrivacyPolicy from './pages/auth/PrivacyPolicy';
import Redirect from './pages/auth/Redirect';
import RouteConstants from './constants/RouteConstants';
import Signout from './pages/auth/Signout';
import Signup from './pages/auth/Signup';
import SecureSignup from './pages/auth/SecureSignup';
import StoreContent from './pages/StoreContent';
import TermsConditions from './pages/auth/TermsConditions';
import ShopifySignup from './pages/auth/ShopifySignup';

function App() {
  return (
    <Switch>
      <Route exact path={RouteConstants.LOGIN} component={Login} />
      <Route exact path={RouteConstants.SHOPIFY_LOGIN} component={Login} />
      <Route exact path={RouteConstants.SIGNUP} component={Signup} />
      <Route exact path={RouteConstants.SHOPIFY_SIGNUP} component={ShopifySignup} />
      <Route exact path={RouteConstants.SECURESIGNUP} component={SecureSignup} />
      <Route exact path={RouteConstants.SIGNOUT} component={Signout} />

      <Route exact path={RouteConstants.FORGOT_PASSWORD} component={ForgotPassword} />

      <Route exact path={RouteConstants.REDIRECT} component={Redirect} />

      <Route exact path={RouteConstants.TERMS_CONDITIONS_VIEW} component={TermsConditions} />
      <Route exact path={RouteConstants.PRIVACY_POLICY_VIEW} component={PrivacyPolicy} />

      <Route exact path={RouteConstants.ONBOARDING_PROGRESS_ROUTER}>
        <OnboardingContent />
      </Route>

      <Route exact path={RouteConstants.ONBOARDING_ROUTER}>
        <OnboardingContent />
      </Route>

      <Route exact path={RouteConstants.MERCHANT_ROUTER}>
        <MerchantContent />
      </Route>

      <Route exact path={RouteConstants.STORE_ROUTER}>
        <StoreContent />
      </Route>

      <Route exact path='*'>
        <ErrorPage isIntroPage={true} />
      </Route>
    </Switch>
  );
}

export default App;
