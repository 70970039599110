const ErrorMsgConstants = {
  LOGIN: {
    'auth/invalid-email': 'Invalid Username/Password',
    'auth/user-disabled': 'Account disabled, please contact support',
    'auth/user-not-found': 'Invalid Username/Password',
    'auth/wrong-password': 'Invalid Username/Password',
  },
  FORGOT_PASSWORD: {
    'auth/invalid-email': 'Account does not exist.',
    'auth/user-disabled': 'Account disabled, please contact support',
    'auth/user-not-found': 'Account does not exist.',
    'auth/wrong-password': 'Account does not exist.',
  },
};

export default ErrorMsgConstants;
