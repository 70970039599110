import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useCookies } from 'react-cookie';
import { Col, Row } from 'antd';
import IntroLayout from './common/IntroLayout';
import LoginForm from './login/LoginForm';
import SectionHeader from '../../components/common/SectionHeader';
import TrackingUtils from '../../utils/TrackingUtils';

const Login = () => {
  const [cookies, setCookie] = useCookies(['shopify_reinstall']);
  const history = useHistory();
  const searchParams = new URLSearchParams(history?.location?.search);
  const shop = searchParams.get('shop');
  const token = searchParams.get('token');
  const campaignLastSource = searchParams.get('utm_source');
  const campaignLastMedium = searchParams.get('utm_medium');
  const campaignLastName = searchParams.get('utm_campaign');
  const campaignLastTerm = searchParams.get('utm_term');
  const campaignLastContent = searchParams.get('utm_content');

  const expiryDate = new Date();
  expiryDate.setHours(expiryDate.getHours() + 2);

  useEffect(() => {
    TrackingUtils.eventMerchantAppLoginPageViewed();
    TrackingUtils.identifyUTMParameters(
      campaignLastSource,
      campaignLastMedium,
      campaignLastName,
      campaignLastTerm,
      campaignLastContent,
    );
    if (shop && token && !cookies.shopify_reinstall) {
      setCookie('shopify_reinstall', JSON.stringify({ shop, token }), { expires: expiryDate });
    }
  }, []);

  return (
    <IntroLayout>
      <SectionHeader
        title='Welcome to the e.pop Merchant Portal'
        subTitle='Please sign in to your account. If you already have a <span class="font-MuliBold">merchant account</span> at <span class="font-MuliBold">BeOne</span>, our community site, then you can use the same email and password here.'
      />
      <Row>
        <Col span={24}>
          <LoginForm />
        </Col>
        <Col span={24}>
          <div className='epop-dashboard-impact mt-3'>
            <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
              <span className='font-MuliBold'>Log in now to:</span>
            </p>
            <ul>
              <p style={{ fontSize: '1.0rem' }} className='text-left mt-2'>
                <li>Begin issuing digital receipts</li>
                <li>Create a listing on the e.pop directory</li>
                <li>Get Access to the BeOne Community</li>
                <li>Send Offers to Shoppers</li>
              </p>
            </ul>
            <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
              <span className='font-MuliBold'>Coming soon:</span>
            </p>
            <ul>
              <p style={{ fontSize: '1.0rem' }} className='text-left mt-2'>
                <li>Local retail Insights</li>
                <li>Customer analytics: including spend by demographic, interests and items</li>
              </p>
            </ul>
          </div>
        </Col>
      </Row>
    </IntroLayout>
  );
};

export default Login;
