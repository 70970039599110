import { Col, Modal, Row, Typography } from 'antd';
import React, { Component } from 'react';

import LoginForm from '../../../pages/auth/login/LoginForm';
import { MdArrowBack } from 'react-icons/md';

const { Title } = Typography;

class LoginFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    const { isVisible } = this.props;
    return (
      <Modal
        visible={isVisible}
        className='epop-modal'
        maskClosable={false}
        closable={false}
        footer={null}
        destroyOnClose={true}
        title={
          <Row gutter={[20, 0]} className='align-center'>
            <Col span='auto'>
              <div className='backArrowWrap'>
                <span
                  onClick={() => {
                    this.props.onCancel();
                  }}
                  className='backArrow  m-0'
                >
                  <MdArrowBack />
                </span>
              </div>
            </Col>
            <Col span='auto'>
              <Title className='privacy_heading text-light-dark mb-0' level={4}>
                Re-login to update password
              </Title>
            </Col>
          </Row>
        }
      >
        <Row style={{ paddingLeft: 30, paddingRight: 30 }}>
          <Col xs={20} offset={2}>
            <LoginForm reauthenticate={true} onSuccess={this.props.onSuccess} />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default LoginFormModal;
