import { inject, observer } from 'mobx-react';

import Loading from './Loading';
import React from 'react';
import RouteConstants from '../constants/RouteConstants';
import UrlGenerator from '../api/UrlGenerator';
import firebase from '../firebase';
import { withRouter } from 'react-router-dom';

const SecureCallbackComponent = (Page) => {
  @inject('dataStore')
  @observer
  class SecureCallbackComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoggedIn: null,
      };
    }

    componentDidMount() {
      this.listener = firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          if (!this.props.dataStore.user.isUserSet()) {
            const rediretTo = encodeURIComponent(window.location.pathname + window.location.search);
            this.props.history.replace(
              UrlGenerator.addQueryParamsToUrl(RouteConstants.REDIRECT, {
                redirect: rediretTo,
              }),
            );
          }
          this.setState({ authUser, isLoggedIn: true });
        } else {
          this.setState({
            authUser: null,
            isLoggedIn: false,
          });
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      if (this.state.isLoggedIn === null) return <Loading tip='Loading'></Loading>;
      else {
        return <Page {...this.state} {...this.props} />;
      }
    }
  }

  return withRouter(SecureCallbackComponent);
};

export default SecureCallbackComponent;
