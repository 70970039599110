import './epop.css';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Elements } from '@stripe/react-stripe-js';
import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import dataStore from './datastore';
import { loadStripe } from '@stripe/stripe-js';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

window.analytics._writeKey = process.env.REACT_APP_SEGMENT_KEY;
window.analytics.load(process.env.REACT_APP_SEGMENT_KEY);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  events: {
    onboardingCompleted: 'onboarding_completed',
  },
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <CookiesProvider>
      <Provider dataStore={dataStore}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  </Elements>,
  document.getElementById('root'),
);

reportWebVitals();
