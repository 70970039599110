import 'semantic-ui-css/semantic.min.css';

import { Col, Row } from 'antd';

import { Dropdown } from 'semantic-ui-react';
import OnboardingFooterBtn from '../common/OnboardingFooterBtn';
import React from 'react';
import RouteConstants from '../../../constants/RouteConstants';
import SectionHeader from '../../../components/common/SectionHeader';
// icons
import otherPosActiveImg from '../../../assets/images/Other_POS_Selected@2x.png';
import { withRouter } from 'react-router-dom';

const businessList = [
  {
    key: 'Carbon (Verifone)',
    text: 'Carbon (Verifone)',
    value: 'Carbon (Verifone)',
    image: { avatar: true, src: '/img/path/img.png' },
  },
  {
    key: 'Ruby (Verifone)',
    text: 'Ruby (Verifone)',
    value: 'Ruby (Verifone)',
    image: { avatar: true, src: '/img/path/img.png' },
  },
  {
    key: 'Verifone - Other',
    text: 'Verifone - Other',
    value: 'Verifone - Other',
    image: { avatar: true, src: '/img/path/img.png' },
  },
  {
    key: 'Lightspeed',
    text: 'Lightspeed',
    value: 'Lightspeed',
    image: { avatar: true, src: '/img/path/img.png' },
  },
  {
    key: 'Aloha (NCR)',
    text: 'Aloha (NCR)',
    value: 'Aloha (NCR)',
    image: { avatar: true, src: '/img/path/img.png' },
  },
  {
    key: 'Emerald (NCR)',
    text: 'Emerald (NCR)',
    value: 'Emerald (NCR)',
    image: { avatar: true, src: '/img/path/img.png' },
  },
];

class OnboardingOtherPos extends React.Component {
  state = {
    selectedFlag: '',
  };

  render() {
    return (
      <>
        <SectionHeader
          title='Select your POS system'
          subTitle='Please indicate which POS system you are using. While your POS
          is not yet connected to e.pop. We will prioritize it and will
          come back to you with estimates shortly.'
        />

        <Row className='text-center mt-3 mt-lg-5'>
          <Col span={24} className='mb-5'>
            <img src={otherPosActiveImg} alt='Other POS' className='store-circle' />
          </Col>
        </Row>
        <Row>
          <Col className='mt-5' span={24}>
            <p className='privacy_des text-light-dark mb-2'>Which POS are you currently using?</p>
            <div>
              <Dropdown
                className='image-drop-down borders'
                placeholder='Name of POS system'
                fluid
                selection
                options={businessList}
              />
            </div>
          </Col>
        </Row>
        <OnboardingFooterBtn
          text='Continue'
          onClick={() => this.props.history.replace(RouteConstants.ONBOARDING.OTHER_POS_SUCCESS)}
        />
      </>
    );
  }
}

export default withRouter(OnboardingOtherPos);
