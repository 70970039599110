import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { getActiveMembershipForUser } from '../../../api/service/MembershipService';
import SecureComponent from '../../../components/SecureComponent';
import SectionHeader from '../../../components/common/SectionHeader';
import moment from 'moment';
import styles from './merchant-subscription-settings.module.scss';
import { inject, observer } from 'mobx-react';
import TrackingUtils from '../../../utils/TrackingUtils';

const MerchantSubscriptionSettings = inject('dataStore')(
  observer(({ dataStore }) => {
    const [membership, setMembership] = useState({});

    const isInTrialAndActive = (trialEnd, status) => new Date(trialEnd) >= new Date() && status === 'active';

    const isInCancellation = (cancellsOn, status) =>
      cancellsOn && new Date(cancellsOn) >= new Date() && status === 'cancelled';

    const isFoundingMember = membership?.name === 'Founding Member';

    useEffect(() => {
      const fetchData = async () => {
        const userData = await dataStore?.user?.getDetails();
        const response = await getActiveMembershipForUser(userData?.id);
        if (response) {
          setMembership(response);
        }
      };
      fetchData();
      TrackingUtils.eventManageMyMembership();
    }, []);

    return (
      <>
        {isFoundingMember ? (
          <SectionHeader
            heading='My membership plan'
            subTitle='Congrats! You got the the Free-for-life plan. Your membership will always be free and you will have access to all our features.'
          />
        ) : (
          <SectionHeader
            heading='Manage my membership plan'
            subTitle='Select the plan that suits you best. We understand that requirements evolve with your business. If you need to, simply change your plan at any time.'
          />
        )}
        <h3>Current membership details:</h3>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsHeader}>Tier</div>
          <div className={styles.detailsContent}>{membership?.name}</div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsHeader}>Price</div>
          <div className={styles.detailsContent}>
            ${membership?.price?.toFixed(2)}
            {isInTrialAndActive(membership?.trial_ends_on, membership?.status) &&
              ` - First payment on ${moment(membership?.billing_date).format('YYYY-MM-DD')}`}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsHeader}>Status</div>
          <div className={styles.detailsContent}>
            {membership?.status}
            {isInCancellation(membership?.cancel_on, membership?.status) &&
              ` - Active until ${moment(membership?.cancel_on).format('YYYY-MM-DD')}`}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsHeader}>Activated on</div>
          <div className={styles.detailsContent}>
            {membership?.activated_on && moment(membership?.activated_on).format('YYYY-MM-DD')}
          </div>
        </div>
        {isInTrialAndActive(membership?.trial_ends_on, membership?.status) && (
          <div className={styles.detailsContainer}>
            <div className={styles.detailsHeader}>Trial ends on</div>
            <div className={styles.detailsContent}>{moment(membership?.trial_ends_on).format('YYYY-MM-DD')}</div>
          </div>
        )}
        {membership?.cancel_on && (
          <div className={styles.detailsContainer}>
            <div className={styles.detailsHeader}>Expires on</div>
            <div className={styles.detailsContent}>{moment(membership?.cancel_on).format('YYYY-MM-DD')}</div>
          </div>
        )}
        {!isFoundingMember && (
          <>
            <hr className={styles.hr} />
            <div className={styles.footerContainer}>
              <h3>Available membership:</h3>
              <div className={styles.detailsContainer}>
                <div className={styles.detailsHeader}>Tier</div>
                <div className={styles.detailsContent}>{membership?.name === 'Growth' ? 'Base' : 'Growth'}</div>
              </div>
              <div className={styles.detailsContainer}>
                <div className={styles.detailsHeader}>Price</div>
                <div className={styles.detailsContent}>
                  {membership?.name === 'Growth' ? '$0.00' : '$9.99/month following a 30-day free trial'}
                </div>
              </div>
              {isInCancellation(membership?.cancel_on, membership?.status) ? (
                <div className={styles.detailsContainer}>
                  <div className={styles.detailsHeader}>Activates on</div>
                  <div className={styles.detailsContent}>{moment(membership?.cancel_on).format('YYYY-MM-DD')}</div>
                </div>
              ) : (
                <div className={styles.detailsContainer}>
                  <div className={styles.detailsHeader}>Status</div>
                  <div className={styles.detailsContent}>
                    Available for {membership?.name === 'Growth' ? 'downgrade' : 'upgrade'}
                  </div>
                </div>
              )}
            </div>
            <hr className={styles.hr} />
            <div className={styles.footerContainer}>
              <h3>Manage your membership:</h3>
              <div className={styles.buttonContainer}>
                <Link to='/merchant/subscription'>
                  <button>Change plan</button>
                </Link>
              </div>
            </div>
          </>
        )}
      </>
    );
  }),
);
export default SecureComponent(withRouter(MerchantSubscriptionSettings));
