import { Button, Card, Col, message, Row as RowAnt, Space, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { withRouter } from 'react-router-dom';
import UserService from '../api/service/UserService';
import UrlGenerator from '../api/UrlGenerator';
import RouteConstants from '../constants/RouteConstants';
import Loading from './Loading';
import ConsoleSafe from '../utils/ConsoleSafe';

@inject('dataStore')
@observer
class UserAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      submitLoading: false,
      agreement: {},
    };
  }

  componentDidMount() {
    UserService.getLatestAgreement()
      .then((agreement) => {
        this.setState({ agreement, loading: false });
      })
      .catch(() => {
        message.error('Unable to fetch agreement at the moment, please try again later');
        this.setState({ agreement: {}, loading: false });
      });
  }

  handleAcceptAgreement = () => {
    const { agreement } = this.state;
    this.setState({ submitLoading: true });
    UserService.acceptAgreement(agreement.id)
      .then(() => {
        this.setState({ submitLoading: false });
        this.props.dataStore.user.has_agreed = true;
        const rediretTo = encodeURIComponent(window.location.pathname + window.location.search);
        this.props.history.replace(
          UrlGenerator.addQueryParamsToUrl(RouteConstants.REDIRECT, {
            redirect: rediretTo,
          }),
        );
      })
      .catch((err) => {
        ConsoleSafe.log(err);
        message.error('Failed to accept terms and conditions, try again');
        this.setState({ submitLoading: false });
      });
  };

  render() {
    const { loading, submitLoading, agreement } = this.state;
    if (loading) return <Loading tip='Loading latest agreements' />;
    return (
      <>
        <RowAnt>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              User Agreement / Terms &amp; Conditions
            </Typography.Text>
          </Col>
        </RowAnt>
        <RowAnt>
          <Col xs={24} className='space-top'>
            <Card
              style={{ overflowY: 'scroll', maxHeight: '80vh' }}
              actions={[
                <Space key={'card-actions'}>
                  <Button
                    type='primary'
                    danger
                    disabled={isEmpty(agreement) || submitLoading}
                    onClick={() => message.warning('Sorry, you have to accept the terms and conditions to proceed')}
                  >
                    Disagree
                  </Button>
                  <Button
                    type='primary'
                    disabled={isEmpty(agreement)}
                    loading={submitLoading}
                    onClick={this.handleAcceptAgreement}
                  >
                    I Agree
                  </Button>
                </Space>,
              ]}
            >
              <div>{renderHTML(agreement.html)}</div>
            </Card>
          </Col>
        </RowAnt>
      </>
    );
  }
}

export default withRouter(UserAgreement);
