import { orderBy } from 'lodash';

const MiscUtil = {
  getValuesForCategoryDropdown: (values) => {
    let dropDownOptions = [];
    values.forEach((element) => {
      const row = {
        key: element.id,
        text: element.name,
        value: element.id,
        description: element.description ? '(' + element.description + ')' : null,
      };
      if (element.icon_url) row.image = { avatar: true, src: element.icon_url };
      dropDownOptions.push(row);
    });
    dropDownOptions = orderBy(dropDownOptions, ['text'], ['asc']);
    return dropDownOptions;
  },
};

export default MiscUtil;
