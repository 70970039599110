import React, { Component } from 'react';

import BrandingImages from '../../components/common/branding/BrandingImages';
import IntroLayout from './common/IntroLayout';
import { Link } from 'react-router-dom';
import RouteConstants from '../../constants/RouteConstants';
import SectionHeader from '../../components/common/SectionHeader';

class Signout extends Component {
  render() {
    return (
      <IntroLayout>
        <div className='text-center'>
          <SectionHeader heading='You are now signed out' headingAlignCenter={true} />
        </div>

        <div className='button-wrap wrap-spacer-medium ml-lg-auto text-center mt-10 mb-5'>
          <Link className='ant-btn success-submit px-4' to={RouteConstants.LOGIN}>
            Sign in again
          </Link>
        </div>

        <div className='mt-10'>
          <BrandingImages />
        </div>
      </IntroLayout>
    );
  }
}

export default Signout;
