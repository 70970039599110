import React from 'react';
import MerchantLayout from './merchantContent/common/MerchantLayout';
import MerchantRouter from './merchantContent/MerchantRouter';

const MerchantContent = () => {
  return (
    <MerchantLayout>
      <MerchantRouter />
    </MerchantLayout>
  );
};

export default MerchantContent;
