import { isNil } from 'lodash';

const _baseErrorMessage = 'Unknown Error.';

const extractInternalAPIErrorMessage = (error, defaultMessage = null) => {
  return isNil(defaultMessage) ? _baseErrorMessage : defaultMessage;
};

const extractPublicAPIErrorMessage = (error, defaultMessage = null) => {
  if (error.data && error.data.message) {
    return error.data.message;
  } else {
    return isNil(defaultMessage) ? _baseErrorMessage : defaultMessage;
  }
};

const ResponseUtils = {
  extractInternalAPIErrorMessage,
  extractPublicAPIErrorMessage,
};

export default ResponseUtils;
