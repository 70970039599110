import { Button, Col, Row, Tooltip, message } from 'antd';
import { inject, observer } from 'mobx-react';
import { isEmpty, isNull } from 'lodash';

import Loading from '../../components/Loading';
import { MdAdd } from 'react-icons/md';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import StoreService from '../../api/service/StoreService';
import UrlGenerator from '../../api/UrlGenerator';
import { withRouter } from 'react-router-dom';
import ConsoleSafe from '../../utils/ConsoleSafe';
import TrackingUtils from '../../utils/TrackingUtils';

@inject('dataStore')
@observer
class MerchantStores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedStoreId: null,
      stores: [],
      inactive_stores: [],
    };
  }

  componentDidMount() {
    TrackingUtils.eventMerchantPagesManageMyStore();
    this.getStores();
  }

  getStores = () => {
    this.setState({ loading: true });
    StoreService.getAllStores()
      .then((response) => {
        let active_stores = [];
        let inactive_stores = [];
        for (let store of response) {
          if (store.is_active) {
            active_stores.push(store);
          } else {
            inactive_stores.push(store);
          }
        }
        this.setState({
          stores: active_stores,
          inactive_stores: inactive_stores,
          loading: false,
        });
      })
      .catch((err) => {
        ConsoleSafe.log(err);
        message.error('Cannot retrieve stores at the moment');
        this.setState({ loading: false });
      });
  };

  setSelectedStore = (storeId) => {
    if (storeId === this.state.selectedStoreId) this.setState({ selectedStoreId: null });
    else this.setState({ selectedStoreId: storeId });
  };

  onSubmit = () => {
    const { selectedStoreId } = this.state;
    if (selectedStoreId === 'new')
      this.props.history.push(
        UrlGenerator.getUiUrlWithPathParams(RouteConstants.ONBOARDING.CHOOSE_POS, {
          id: this.props.dataStore.user.business.id,
        }),
        { isStoreCreation: true },
      );
    else if (!isNull(selectedStoreId))
      this.props.history.push(
        UrlGenerator.getUiUrlWithPathParams(RouteConstants.STORE.DASHBOARD, {
          id: selectedStoreId,
        }),
      );
  };

  render() {
    const { selectedStoreId, stores, inactive_stores, loading } = this.state;
    return (
      <>
        <SectionHeader
          heading={stores.length > 0 ? 'Manage my stores' : 'Connect Your POS'}
          subTitle='Please choose your store location or add a new one.'
        />
        {loading ? (
          <Loading tip='Fetching Stores' />
        ) : (
          <>
            <Row className='mt-5'>
              <Col span={24}>
                <div className='store-list-circle space-evenly mb-5 pb-5'>
                  {stores.map((store, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => this.setSelectedStore(store.id)}
                        style={selectedStoreId === store.id ? { background: '#DBEDEC' } : {}}
                      >
                        <Tooltip title={store.name}>
                          <p>{store.name}</p>
                          {store.address && store.address.city && <p>{store.address.city}</p>}
                          {store.address && store.address.province && <p>{store.address.province}</p>}
                        </Tooltip>
                      </div>
                    );
                  })}
                  <div
                    onClick={() => this.setSelectedStore('new')}
                    style={selectedStoreId === 'new' ? { background: '#DBEDEC' } : {}}
                  >
                    <div className='addNewStore'>
                      <span className='iconAdd'>
                        <MdAdd />
                      </span>
                      <span>Add a New Store</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <div className='button-wrap wrap-spacer-medium ml-lg-auto text-center text-lg-right'>
                  <Button
                    type='submit'
                    className='success-submit px-4 px-lg-5'
                    disabled={isEmpty(selectedStoreId)}
                    onClick={this.onSubmit}
                  >
                    Continue
                  </Button>
                </div>
              </Col>
            </Row>

            {inactive_stores.length > 0 ? (
              <>
                <SectionHeader
                  heading='Inactive stores'
                  subTitle='If these stores have been connected through a POS, they will automatically be activated as you reactivate them in your POS. You will soon be able to activate your store within e.pop only when other methods of integrations will be available in e.pop.'
                />

                <Row className='mt-5'>
                  <Col span={24}>
                    <div className='store-list-circle space-evenly mb-5 pb-5'>
                      {inactive_stores.map((store, index) => {
                        return (
                          <div key={index} style={{ background: '#CCCCCC' }}>
                            <Tooltip title={store.name}>
                              <p>{store.name}</p>
                              {store.address && store.address.city && <p>{store.address.city}</p>}
                              {store.address && store.address.province && <p>{store.address.province}</p>}
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  }
}

export default SecureComponent(withRouter(MerchantStores));
