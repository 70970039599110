import { Col, Row, Typography } from 'antd';
import React, { Component } from 'react';

import AddStoreLocationForm from './merchantAddStore/AddStoreLocationForm';
import SectionHeader from '../../../components/common/SectionHeader';

class MerchantAddStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    return (
      <>
        <SectionHeader title='Add new store' subTitle='We just need a few details to add your new store location.' />
        <Row>
          <Col xs={24} lg={22} xxl={20}>
            <div className='store-container px-4 px-lg-5'>
              <Typography.Title className='font-MuliBold' level={5}>
                Add Store Details
              </Typography.Title>
              <AddStoreLocationForm />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default MerchantAddStore;
