import { Col, Row } from 'antd';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import Passtick from '../../assets/images/Signup Bar Active.png';
import PropTypes from 'prop-types';
import React from 'react';
import SquareLogo from '../../assets/images/Group 2034@2x.png';
import TickImge from '../../assets/images/Signup Bar Checked.png';
import UrlUtils from '../../utils/UrlUtils';
import leftBackGroundImge from '../../assets/images/Left Menu Blue Circles@2x.png';
import logo from '../../assets/images/Group 1208.png';
import newTabCircal from '../../assets/images/Signup Bar Next.png';
import { upperCase } from 'lodash';
import { withRouter } from 'react-router-dom';

class OnboardingMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      squarePartner: false,
    };
  }

  static propTypes = {
    activeStep: PropTypes.number,
  };

  static defaultProps = {
    activeStep: 2,
  };

  componentDidMount() {
    const partnerAccount = UrlUtils.getParamsFromUrl(this.props.location.search, 'p');
    if (partnerAccount && upperCase(partnerAccount) === 'SQUARE') this.setState({ squarePartner: true });
  }

  getStepImage = (currentStep) => {
    const { activeStep } = this.props;
    if (parseInt(activeStep) > currentStep) {
      return <img className='checkedImge' src={TickImge} alt='Done' />;
    } else if (parseInt(activeStep) === currentStep) {
      return <img className='uncheckedImge' src={Passtick} alt='Active' />;
    } else {
      return <img className='uncheckedImge' src={newTabCircal} alt='Inactive' />;
    }
  };

  render() {
    const { squarePartner } = this.state;
    const steps = ['Your Details', 'Your Company', 'Features', 'Connect Your POS', 'Your e.pop Dashboard'];

    return (
      <div className='left-img-container' style={{ backgroundImage: `url('${leftBackGroundImge}')` }}>
        <Row>
          <Col span={24}>
            <div className='info-container mb-1'>
              <Col flex={'auto'}>
                {this.props.showBack && (
                  <div
                    className='closeSideBar'
                    onClick={() => {
                      this.props.toggleSidebar(false);
                    }}
                  >
                    <AiOutlineArrowLeft />
                  </div>
                )}
              </Col>
              <div className='logo-container'>
                {squarePartner && <img src={SquareLogo} alt='Square Logo' className='square-logo' />}
                <img src={logo} alt='e.pop Logo' />
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className='progressbar-wrap'>
              <ul className='signUp-progressBar'>
                {steps.map((stepText, index) => (
                  <li key={index}>
                    {stepText} {this.getStepImage(index)}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(OnboardingMenu);
