const env = process.env.NODE_ENV || 'development';
const loggingEnabled = process.env.REACT_APP_DEBUG === 'true' || env === 'development';

const log = (message) => {
  if (loggingEnabled) {
    console.log(message);
  }
};

const logError = (message, error) => {
  if (loggingEnabled) {
    console.log(message, error);
  }
};

const logNamedObject = (objectName, object) => {
  if (loggingEnabled) {
    console.log(objectName, object);
  }
};
const ConsoleSafe = {
  log,
  logError,
  logNamedObject,
};

export default ConsoleSafe;
