import 'semantic-ui-css/semantic.min.css';

import * as Yup from 'yup';

import { Button, Col, Row, message } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import parsePhoneNumber from 'libphonenumber-js';

import ChangePasswordModal from '../../../components/modal/auth/ChangePasswordModal';
import { Dropdown } from 'semantic-ui-react';
import Loading from '../../../components/Loading';
import LoginFormModal from '../../../components/modal/auth/LoginFormModal';
import MerchantService from '../../../api/service/MerchantService';
import MiscUtil from '../../../utils/MiscUtils';
import RouteConstants from '../../../constants/RouteConstants';
import { State } from 'country-state-city';
import firebase from '../../../firebase';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import UserService from '../../../api/service/UserService';
import TrackingUtils from '../../../utils/TrackingUtils';
import ConsoleSafe from '../../../utils/ConsoleSafe';

const validateSchema = Yup.object().shape({
  name: Yup.string().required('Business Name is required.'),
  address_line_1: Yup.string().required('Address is required.'),
  address_line_2: Yup.string(),
  city: Yup.string().required('City is required.'),
  province: Yup.string().required('State is required.'),
  zip: Yup.string().required('Zip is required.'),
  first_name: Yup.string().required('A First Name is required.'),
  last_name: Yup.string().required('A Last Name is required.'),
  phone: Yup.string().nullable(),
  website: Yup.string().nullable(),
  instagram_handle: Yup.string().nullable(),
  facebook_url: Yup.string().nullable(),
  category: Yup.string().required('Business Category is required.'),
});

@inject('dataStore')
@observer
class MerchantSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      editBusinessName: false,
      editBusinessAddress: false,
      editContactDetails: false,
      categories: [],
      user: {},
      submitLoading: false,
      changePasswordModalVisible: false,
      loginModalVisible: false,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const user = await this.props.dataStore.user.getDetails();
    MerchantService.getAllCategories()
      .then((response) => {
        this.setState({
          categories: MiscUtil.getValuesForCategoryDropdown(response),
          loading: false,
          user,
        });
      })
      .catch(() => {
        this.setState({ loading: false, categories: [], user });
      });
  }

  toggleEditBusinessName = () => this.setState({ editBusinessName: !this.state.editBusinessName });

  toggleEditBusinessAddress = () => this.setState({ editBusinessAddress: !this.state.editBusinessAddress });

  toggleEditContactDetails = () => this.setState({ editContactDetails: !this.state.editContactDetails });

  logout = async () => {
    this.props.dataStore.user.reset();
    this.props.dataStore.shop.reset();
    await firebase.signOut();
    this.props.history.replace(RouteConstants.SIGNOUT);
    TrackingUtils.resetTracking();
  };

  onSubmit = async (values) => {
    const errors = await this.form.validateForm();

    if (!isEmpty(errors)) {
      window.scrollTo(0, 0);
      message.warn('Please fill all mandatory fields');
    } else {
      let { user } = this.state;
      this.setState({ submitLoading: true });
      if (values.first_name !== user.first_name || values.last_name !== user.last_name) {
        user.first_name = values.first_name;
        user.last_name = values.last_name;
        await UserService.updateUserDetails(user)
          .then((response) => {
            this.props.dataStore.user.setDetails(response);
            this.setState({ user: this.props.dataStore.user.getDetails() });
            TrackingUtils.eventMerchantDetailsUpdated(
              null,
              null,
              null,
              null,
              null,
              null,
              values.first_name,
              values.last_name,
              null,
              null,
              null,
              null,
              null,
            );
            TrackingUtils.identifyMerchantDetailsUpdated(
              this.props.dataStore.user.epopId,
              null,
              null,
              null,
              null,
              null,
              null,
              values.first_name,
              values.last_name,
              null,
              null,
              null,
              null,
              null,
            );
          })
          .catch((err) => {
            ConsoleSafe.log(err);
            message.error("Couldn't update user details at the moment");
          });
      }

      MerchantService.updateBusiness({
        ...values,
        ':id': user.business.id,
      })
        .then((response) => {
          this.props.dataStore.user.setBusiness(response);
          const country_code = this.props.dataStore.user.country_code;
          let phone;
          try {
            phone = values.phone ? parsePhoneNumber(values.phone, country_code).format('E.164') : null;
          } catch (err) {
            phone = values.phone;
          }
          const website =
            !values.website || values.website.includes('http') ? values.website : `https://${values.website}`;
          TrackingUtils.eventMerchantDetailsUpdated(
            values.name,
            values.address_line_1,
            values.address_line_2,
            values.province,
            values.city,
            values.zip,
            null,
            null,
            phone,
            website,
            values.instagram_handle,
            values.facebook_url,
            this.props.dataStore.user.business?.category?.name,
          );
          TrackingUtils.identifyMerchantDetailsUpdated(
            this.props.dataStore.user.epopId,
            values.name,
            values.address_line_1,
            values.address_line_2,
            values.province,
            values.city,
            values.zip,
            null,
            null,
            phone,
            website,
            values.instagram_handle,
            values.facebook_url,
            this.props.dataStore.user.business?.category?.name,
          );
          this.setState({ submitLoading: false });
          this.props.history.replace(RouteConstants.MERCHANT.DASHBOARD);
        })
        .catch((err) => {
          ConsoleSafe.log(err);
          message.error("Couldn't update details at the moment");
          this.setState({ submitLoading: false });
        });
    }
  };

  render() {
    const {
      loading,
      editBusinessName,
      editBusinessAddress,
      editContactDetails,
      categories,
      user,
      submitLoading,
      changePasswordModalVisible,
      loginModalVisible,
    } = this.state;

    let country_code = user.country_code;
    if (user.business?.address?.country_code) country_code = user.business?.address?.country_code;

    if (loading) return <Loading />;
    return (
      <>
        <Formik
          innerRef={(ref) => (this.form = ref)}
          initialValues={{
            email: user.email,
            password: '',
            terms: user.has_agreed,
            name: user.business?.name ? user.business?.name : '',
            address_line_1: user.business?.address?.address_line_1 ? user.business?.address?.address_line_1 : '',
            address_line_2: user.business?.address?.address_line_2 ? user.business?.address?.address_line_2 : '',
            city: user.business?.address?.city ? user.business?.address?.city : '',
            province: user.business?.address?.province ? user.business?.address?.province : '',
            country: user.business?.address?.country ? user.business?.address?.country : user.country,
            zip: user.business?.address?.zip ? user.business?.address?.zip : '',
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.business?.phone ? user.business?.phone : '',
            website: user.business?.website ? user.business?.website : '',
            instagram_handle: user.business?.instagram_handle ? user.business?.instagram_handle : '',
            facebook_url: user.business?.facebook_url ? user.business?.facebook_url : '',
            category: user.business?.category?.id ? user.business?.category?.id : '',
          }}
          validationSchema={validateSchema}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form className='login-Form'>
              <Row>
                <Col xs={24} lg={20} xl={18} xxl={16}>
                  <div className='mb-4'>
                    <div className='py-2'>
                      <p className='font-MuliBold'>Login Details</p>
                      <div className='fieldContainer'>
                        <label>Email Address</label>
                        <Field
                          className='ant-input mt-3'
                          type='text'
                          placeholder='John@coffee66.com'
                          name='email'
                          disabled
                        />
                        <small className='text-danger'>
                          {errors.email && touched.email ? <div>{errors.email}</div> : null}
                        </small>
                      </div>
                      <div className='d-flex justify-content-between mt-3'>
                        <div>
                          <label>Password</label>
                        </div>
                        <div onClick={() => this.setState({ loginModalVisible: true })}>
                          <label className='font-MuliReg linkss'>CHANGE PASSWORD</label>
                        </div>
                      </div>
                      <div className='fieldContainer'>
                        <Field
                          type={'password'}
                          value='********'
                          className='ant-input'
                          placeholder='Create Password'
                          name='password'
                          disabled
                        />
                        <small className='text-danger'>
                          {errors.password && touched.password ? <div>{errors.password}</div> : null}
                        </small>
                      </div>
                      {/* Business Details form */}
                      <div className='d-flex justify-content-between mt-4'>
                        <div>
                          <label>Name of Business</label>
                        </div>
                        <div onClick={this.toggleEditBusinessName}>
                          <label className='font-MuliReg'> {editBusinessName ? 'CLOSE' : 'EDIT'}</label>
                        </div>
                      </div>
                      <div className='fieldContainer'>
                        <Field
                          className='ant-input mt-3'
                          type='text'
                          placeholder='Business Name'
                          name='name'
                          disabled={!editBusinessName}
                        />
                        <small className='text-danger'>
                          {errors.name && touched.name ? <div>{errors.name}</div> : null}
                        </small>
                      </div>{' '}
                      <div className='d-flex justify-content-between mt-3'>
                        <div>
                          <label>Business Address</label>
                        </div>
                        <div onClick={this.toggleEditBusinessAddress}>
                          <label className='font-MuliReg'>{editBusinessAddress ? 'CLOSE' : 'EDIT'}</label>
                        </div>
                      </div>
                      <div className='fieldContainer1'>
                        <Field
                          className='ant-input mt-2 '
                          type='text'
                          placeholder='Address Line 1'
                          name='address_line_1'
                          disabled={!editBusinessAddress}
                        />
                        <small className='text-danger'>
                          {errors.address_line_1 && touched.address_line_1 ? <div>{errors.address_line_1}</div> : null}
                        </small>
                      </div>
                      <div className='fieldContainer1'>
                        <Field
                          className='ant-input mt-2 '
                          type='text'
                          placeholder='Address Line 2'
                          name='address_line_2'
                          disabled={!editBusinessAddress}
                        />
                      </div>
                      <div className='fieldContainer1'>
                        <Field
                          className='ant-input mt-2'
                          type='text'
                          placeholder='Country'
                          name='country'
                          disabled={true}
                        />
                        <small className='text-danger'>
                          {errors.country && touched.country ? <div>{errors.country}</div> : null}
                        </small>
                      </div>
                      <div className='fieldContainer1'>
                        <Field
                          className='ant-input mt-0 ant-select-suffix'
                          as='select'
                          placeholder='State'
                          name='province'
                          disabled={!editBusinessAddress}
                        >
                          <option>Select</option>
                          {State.getStatesOfCountry(country_code).map((state, index) => {
                            return (
                              <option key={index} value={state.name}>
                                {state.name}
                              </option>
                            );
                          })}
                        </Field>
                        <small className='text-danger'>
                          {errors.province && touched.province ? <div>{errors.province}</div> : null}
                        </small>
                      </div>
                      <div className='fieldContainer1'>
                        <Row gutter={[16, 6]}>
                          <Col md={{ span: 14 }} xs={{ span: 24 }}>
                            <Field
                              className='ant-input mt-0 '
                              type='text'
                              placeholder='City'
                              name='city'
                              disabled={!editBusinessAddress}
                            />
                            <small className='text-danger'>
                              {errors.city && touched.city ? <div>{errors.city}</div> : null}
                            </small>
                          </Col>
                          <Col md={{ span: 10 }} xs={{ span: 24 }}>
                            <Field
                              className='ant-input mt-0'
                              type='text'
                              placeholder='Zip'
                              name='zip'
                              disabled={!editBusinessAddress}
                            />
                            <small className='text-danger'>
                              {errors.zip && touched.zip ? <div>{errors.zip}</div> : null}
                            </small>
                          </Col>
                        </Row>
                      </div>
                      <div className='d-flex justify-content-between mt-3'>
                        <div>
                          <label>Contact Details</label>
                        </div>
                        <div onClick={this.toggleEditContactDetails}>
                          <label className='font-MuliReg'> {editContactDetails ? 'CLOSE' : 'EDIT'}</label>
                        </div>
                      </div>
                      <div className='fieldContainer1'>
                        <Row gutter={[16, 6]}>
                          <Col md={{ span: 11 }} xs={{ span: 24 }}>
                            <Field
                              className='ant-input mt-2'
                              type='text'
                              placeholder='First Name'
                              name='first_name'
                              disabled={!editContactDetails}
                            />
                            <small className='text-danger'>
                              {errors.first_name && touched.first_name ? <div>{errors.first_name}</div> : null}
                            </small>
                          </Col>
                          <Col md={{ span: 13 }} xs={{ span: 24 }}>
                            <Field
                              className='ant-input mt-2'
                              type='text'
                              placeholder='Last Name'
                              name='last_name'
                              disabled={!editContactDetails}
                            />
                            <small className='text-danger'>
                              {errors.last_name && touched.last_name ? <div>{errors.last_name}</div> : null}
                            </small>
                          </Col>
                        </Row>
                      </div>
                      <div className='fieldContainer1'>
                        <Field
                          className='ant-input mt-2 '
                          type='text'
                          placeholder='Phone Number'
                          name='phone'
                          disabled={!editContactDetails}
                        />
                        <small className='text-danger'>
                          {errors.phone && touched.phone ? <div>{errors.phone}</div> : null}
                        </small>
                      </div>
                      <div className='fieldContainer1'>
                        <Field
                          className='ant-input mt-2 '
                          type='text'
                          placeholder='Website'
                          name='website'
                          disabled={!editContactDetails}
                        />
                        <small className='text-danger'>
                          {errors.website && touched.website ? <div>{errors.website}</div> : null}
                        </small>
                      </div>
                      <div className='fieldContainer1'>
                        <Row gutter={[16, 6]}>
                          <Col md={{ span: 11 }} xs={{ span: 24 }}>
                            <Field
                              className='ant-input mt-0'
                              type='text'
                              placeholder='Instagram Username'
                              name='instagram_handle'
                              disabled={!editContactDetails}
                            />
                            <small className='text-danger'>
                              {errors.instagram_handle && touched.instagram_handle ? (
                                <div>{errors.instagram_handle}</div>
                              ) : null}
                            </small>
                          </Col>
                          <Col md={{ span: 13 }} xs={{ span: 24 }}>
                            <Field
                              className='ant-input mt-0'
                              type='text'
                              placeholder='Facebook page'
                              name='facebook_url'
                              disabled={!editContactDetails}
                            />
                            <small className='text-danger'>
                              {errors.facebook_url && touched.facebook_url ? <div>{errors.facebook_url}</div> : null}
                            </small>
                          </Col>
                        </Row>
                      </div>
                      <Row>
                        <Col span={24}>
                          <div className='right-header mt-5'>
                            <h3 className='mb-1'>Business Category</h3>
                            <p>
                              What type of business do you run? {'  '}This allows us to automatically categorize the
                              receipts in your customers e.pop app.
                            </p>
                          </div>
                          <div className='mt-1'>
                            <Dropdown
                              className='image-drop-down'
                              placeholder='Category of your business'
                              fluid
                              search
                              selection
                              options={categories}
                              disabled={loading}
                              value={values.category}
                              onChange={(e, data) => {
                                setFieldValue('category', data.value);
                              }}
                            />
                          </div>
                          <small className='text-danger'>
                            {errors.category && touched.category ? <div>{errors.category}</div> : null}
                          </small>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className='d-flex flex-wrap justify-content-center pt-5 pb-5'>
                <div className='button-wrap mr-lg-auto'>
                  <Button
                    type='logout'
                    className='primary-green-btn btnShape'
                    onClick={this.logout}
                    disabled={submitLoading}
                  >
                    Logout
                  </Button>
                </div>

                <div className='button-wrap mx-3'>
                  <Button
                    htmlType='cancel'
                    className='gray-submit btnShape'
                    onClick={() => this.props.history.push(RouteConstants.MERCHANT.DASHBOARD)}
                    disabled={submitLoading}
                  >
                    Cancel
                  </Button>
                </div>

                <div className='button-wrap ml-md-5 ml-lg-0'>
                  <Button
                    htmlType='submit'
                    className='success-submit'
                    loading={submitLoading}
                    onClick={() => this.onSubmit(values, errors)}
                  >
                    Save &amp; Exit
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <LoginFormModal
          isVisible={loginModalVisible}
          onSuccess={() =>
            this.setState({
              loginModalVisible: false,
              changePasswordModalVisible: true,
            })
          }
          onCancel={() =>
            this.setState({
              loginModalVisible: false,
              changePasswordModalVisible: false,
            })
          }
        />
        <ChangePasswordModal
          isVisible={changePasswordModalVisible}
          onCancel={() =>
            this.setState({
              loginModalVisible: false,
              changePasswordModalVisible: false,
            })
          }
        />
      </>
    );
  }
}

export default withRouter(MerchantSettingsForm);
