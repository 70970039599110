import { Col, Row } from 'antd';

import { BsList } from 'react-icons/bs';
import MerchantMenu from '../../../components/menu/MerchantMenu';
import React from 'react';
import RightSideCard from '../../../components/common/RightSideCard';
import RouteConstants from '../../../constants/RouteConstants';
import { findIndex } from 'lodash';
import { withRouter } from 'react-router-dom';

const { MERCHANT } = RouteConstants;

const steps = [
  { url: MERCHANT.DASHBOARD, step: 1 },
  { url: MERCHANT.MANAGE_STORES, step: 2 },
  { url: MERCHANT.NEW_STORE_LOCATION, step: 2 },
  // { url: MERCHANT.MANAGE_STORES, step: 3 },
  { url: MERCHANT.SUBSCRIPTION_SETTINGS, step: 3 },
  { url: MERCHANT.OFFERS, step: 4 },
  { url: MERCHANT.SETTINGS, step: 5 },
];

class MerchantLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      mobile: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    var x = window.innerWidth;
    if (x < 991) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  showSideBar = (e) => {
    this.setState({
      sidebarVisible: e,
    });
  };

  render() {
    const { mobile, sidebarVisible } = this.state;
    const { url } = this.props.match;

    let objIndex = findIndex(steps, { url });
    let stepIndex = -1;
    if (objIndex >= 0) stepIndex = steps[objIndex].step;

    return (
      <>
        {mobile ? (
          <Row>
            <Col span={24}>
              <div className='d-flex'>
                <div className={sidebarVisible ? 'leftBarSliders active' : 'leftBarSliders'}>
                  <MerchantMenu activeStep={stepIndex} toggleSidebar={this.showSideBar} showBack={mobile} />
                </div>
                <div className='w-100'>
                  <Row className='mb-5'>
                    <Col span={24}>
                      <div className='showNavContainer'>
                        <div onClick={() => this.showSideBar(true)}>
                          <BsList />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <RightSideCard isError={stepIndex === -1}>
                    <div className='wrapperNameCategory px-3'>{this.props.children}</div>
                  </RightSideCard>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className='full-content'>
            <Col md={{ span: 4 }} lg={{ span: 5 }} xxl={{ span: 6 }} span={24} className='left-side-content'>
              <MerchantMenu activeStep={stepIndex} toggleSidebar={this.showSideBar} showBack={mobile} />
            </Col>
            <Col md={{ span: 20 }} lg={{ span: 19 }} xxl={{ span: 18 }} span={24} className='right-side-content'>
              <RightSideCard isError={stepIndex === -1}>
                <div className='wrapperNameCategory px-3'>{this.props.children}</div>
              </RightSideCard>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default withRouter(MerchantLayout);
