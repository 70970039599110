import React from 'react';

const MembershipSubtitle = ({ tier }) => {
  return tier === 'GROWTH' ? (
    <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
      You are currently a Growth Member and have access to all the perks
    </p>
  ) : tier === 'BASE' ? (
    <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
      You are currently a Base Member, which is our free membership tier entitling you to some cool but limited perks
    </p>
  ) : (
    <p style={{ fontSize: '1.15rem' }} className='text-left mt-2'>
      You are a Founding Member, which means that you are one of a limited number of merchants to enjoy e.pop
      FREE-FOR-LIFE!*
    </p>
  );
};

export default MembershipSubtitle;
