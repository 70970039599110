import { Col, Modal, Row, Typography } from 'antd';
import React, { Component } from 'react';

import { MdArrowBack } from 'react-icons/md';

const { Title } = Typography;

class EsignConsentModal extends Component {
  render() {
    const { isVisible } = this.props;
    return (
      <Modal
        visible={isVisible}
        className='epop-modal'
        maskClosable={false}
        closable={false}
        footer={null}
        title={
          <Row gutter={[20, 0]} className='align-center'>
            <Col span='auto'>
              <div className='backArrowWrap'>
                <span
                  onClick={() => {
                    this.props.onCancel();
                  }}
                  className='backArrow  m-0'
                >
                  <MdArrowBack />
                </span>
              </div>
            </Col>
            <Col span='auto'>
              <Title className='privacy_heading text-light-dark mb-0' level={4}>
                E-Sign Consent
              </Title>
            </Col>
          </Row>
        }
      >
        <div className='privacy_wrap'>
          <p className='privacy_des'>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
            sed diam voluptua. <br />
            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
            est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
            justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
            amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
            labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
            rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
            sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
            aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
            gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
            voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
            takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
            sed diam nonumy eirmod tempor invidunt ut labore et dolore magnas
          </p>
        </div>
      </Modal>
    );
  }
}

export default EsignConsentModal;
