import { Col, Row, message } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Loading from '../../components/Loading';
import MembershipProgressSection from './storeDashboard/MembershipProgressSection';
import MonthlyUsageSection from './storeDashboard/MonthlyUsageSection';
import RouteConstants from '../../constants/RouteConstants';
import SectionHeaderWithStore from '../../components/common/SectionHeaderWithStore';
import SecureComponent from '../../components/SecureComponent';
import Title from 'antd/lib/typography/Title';
import UrlGenerator from '../../api/UrlGenerator';
import UsageStatistics from '../../components/usageStats/UsageStatistics';
import ConsoleSafe from '../../utils/ConsoleSafe';
import TrackingUtils from '../../utils/TrackingUtils';

@inject('dataStore')
@observer
class StoreDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      store: {},
      stats: {},
    };
  }

  componentDidMount = () => {
    TrackingUtils.eventStorePagesStoreDashboard();
    this.setState({ loading: true });
    const { id } = this.props;
    this.props.dataStore.shop
      .getStoreDetails(id)
      .then((store) => {
        this.setState({ store });
        this.props.dataStore.shop
          .getMonthlyAllowance()
          .then((data) => {
            this.setState({ stats: data, loading: false });
          })
          .catch(() => this.setState({ stats: {}, loading: false }));
      })
      .catch((err) => {
        ConsoleSafe.log(err);
        message.error('Store detail not found');
        this.setState({ loading: false, stats: {} });
      });
  };

  navigateToTransactions = () => {
    const { id } = this.props;
    this.props.history.push(
      UrlGenerator.getUiUrlWithPathParams(RouteConstants.STORE.TRANSACTIONS, {
        id,
      }),
    );
  };

  render() {
    const { loading, store, stats } = this.state;

    return (
      <>
        {loading ? (
          <>
            <SectionHeaderWithStore heading='My store e.pop dashboard' />
            <Loading tip='Fetching store details...' />
          </>
        ) : (
          <>
            <SectionHeaderWithStore
              heading='My store e.pop dashboard'
              store={this.props.dataStore.shop}
              dashboardView={true}
            />
            <Row>
              <Col span={24}>
                <div className='mt-2'>
                  <Title className='privacy_heading text-light-dark font-weight-bolder' level={5}>
                    Today
                  </Title>
                </div>
              </Col>
            </Row>
            <UsageStatistics stats={stats.today} onRightArrowClick={this.navigateToTransactions} />

            <MonthlyUsageSection store={store} stats={stats.allowance} />
            {/* <ImpactStatistics /> */}
            <MembershipProgressSection store={store} />
          </>
        )}
      </>
    );
  }
}

export default SecureComponent(StoreDashboard);
