import { Col, Row } from 'antd';

import PropTypes from 'prop-types';
import React from 'react';

const RightSideCard = (props) => {
  const style = {};
  if (props.isError) style.background = '#fff';
  if (props.noMarginTop) style.marginTop = 0;
  return (
    <Row>
      <Col
        className='adjustOffset md-padding-sides'
        md={{ span: 24 }}
        lg={{ span: 22, offset: 1 }}
        xl={{ span: 22, offset: 1 }}
        span={24}
      >
        <div className='Right-wrapper p-lg-4' style={style}>
          {props.children}
        </div>
      </Col>
    </Row>
  );
};

RightSideCard.propTypes = {
  showBackBtn: PropTypes.bool,
};

RightSideCard.defaultProps = {
  showBackBtn: false,
};

export default RightSideCard;
