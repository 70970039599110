import React, { useEffect } from 'react';

import { Col, Row } from 'antd';
import OnboardingBusinessForm from './onboardingBusiness/OnboardingBusinessForm';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import TrackingUtils from '../../utils/TrackingUtils';

const OnboardingBusiness = () => {
  useEffect(() => {
    TrackingUtils.eventMerchantOnboardingStep2NameAndCategory();
  }, []);

  return (
    <>
      <SectionHeader
        title='What is the name and category of your business?'
        subTitle='Use your personal name if appropriate so that customers know who they are paying.'
      />
      <Row>
        <Col span={24}>
          <OnboardingBusinessForm />
        </Col>
      </Row>
    </>
  );
};

export default SecureComponent(OnboardingBusiness);
