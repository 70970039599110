import { Col, Row, Typography } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import PropTypes from 'prop-types';
import StoreCircleInfo from './StoreCircleInfo';

@inject('dataStore')
@observer
class SectionHeaderWithStore extends Component {
  static propTypes = {
    heading: PropTypes.string,
    headingAlignCenter: PropTypes.bool,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    alignCenter: PropTypes.bool,
    store: PropTypes.object,
    dashboardView: PropTypes.bool,
  };

  render() {
    const { heading, headingAlignCenter, title, subTitle, alignCenter, store, dashboardView } = this.props;
    return (
      <Row>
        <Col span={16}>
          <div className='right-header'>
            {heading && (
              <Typography.Title
                className={`main-heading ${headingAlignCenter ? 'text-center' : 'text-left'}`}
                level={1}
              >
                {heading}
              </Typography.Title>
            )}

            {title && (
              <Typography.Title className={`text-light-dark ${alignCenter ? 'text-center' : 'text-left'}`} level={2}>
                {title}
              </Typography.Title>
            )}

            {subTitle && (
              <div
                className={`font-muli-Regular text-light-dark ${alignCenter ? 'text-center' : 'text-left'}`}
                dangerouslySetInnerHTML={{
                  __html: '<p>' + this.props.subTitle + '</p>',
                }}
              ></div>
            )}

            {dashboardView && (
              <>
                <Typography.Title className='store-name-heading text-left' level={1}>
                  {store?.name}
                </Typography.Title>
                <div className='store-logo-wrapper'>
                  {store.logo_url ? (
                    <img src={store?.logo_url} alt={store?.name} className='store-logo-img' />
                  ) : (
                    <img
                      src={store?.category.icon_bw_url}
                      alt={store?.category.name}
                      className='store-logo-default-img'
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </Col>
        <Col span={8}>
          <StoreCircleInfo store={this.props.dataStore.shop.getDetails()} />
        </Col>
      </Row>
    );
  }
}

export default SectionHeaderWithStore;
