import 'react-credit-cards/es/styles-compiled.css';

import { Button, Col, Row, message } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ApiManager from '../../api/ApiManager';
import Cards from 'react-credit-cards';
import HttpConstants from '../../constants/HttpConstants';
import Loading from '../../components/Loading';
import RouteConstants from '../../constants/RouteConstants';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import UrlConstants from '../../constants/UrlConstants';
import { withRouter } from 'react-router-dom';
import ConsoleSafe from '../../utils/ConsoleSafe';
import TrackingUtils from '../../utils/TrackingUtils';

@inject('dataStore')
@observer
class MerchantBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      paymentMethods: [],
    };
  }

  componentDidMount() {
    TrackingUtils.eventMerchantPagesPaymentMethod();
    this.setState({ loading: true });
    ApiManager.makeApiCallWithAuthentication(UrlConstants.GET_PAYMENT_METHOD.USECASE, HttpConstants.GET_METHOD)
      .then((response) => {
        this.setState({ paymentMethods: response, loading: false });
      })
      .catch((err) => {
        ConsoleSafe.log(err);
        this.setState({ loading: false });
        message.error('Unable to fetch billing information');
      });
  }

  render() {
    const { loading, paymentMethods } = this.state;
    return (
      <>
        <SectionHeader heading='My payment method' />
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row className='mt-5 pb-5'>
              <Col span={24}>
                {paymentMethods.length > 0 ? (
                  <Row gutter={[0, 40]}>
                    {paymentMethods.map((card, index) => {
                      return (
                        <Col span={24} md={12} xxl={8} key={index}>
                          <Cards
                            expiry={
                              parseInt(card.expiry_month).toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              }) +
                              '/' +
                              card.expiry_year
                            }
                            name={card.billing_details ? card.billing_details.name : ' '}
                            number={'••••••••••••' + card.last_4}
                            issuer={card.brand}
                            cvc='***'
                            preview
                          />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <Row>
                    <Col span={24}>
                      <div className='comming-soon'>No Payment Method Setup Yet</div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row className='button-wrap wrap-spacer-large d-flex align-items-center'>
              <Col flex='auto'>
                <Button
                  className='primary-green-btn mx-auto ml-lg-auto'
                  onClick={() => this.props.history.push(RouteConstants.ONBOARDING.PAYMENT_METHODS)}
                >
                  Add a new payment method
                </Button>
              </Col>
              <Col flex='auto' className='text-right'>
                <Button
                  type='submit'
                  className='success-submit '
                  onClick={() => this.props.history.push(RouteConstants.MERCHANT.DASHBOARD)}
                >
                  Exit
                </Button>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default SecureComponent(withRouter(MerchantBilling));
