import { Col, Row } from 'antd';
import { inject, observer } from 'mobx-react';

import Loading from '../../../components/Loading';
import OnboardingFooterBtn from '../common/OnboardingFooterBtn';
import PosService from '../../../api/service/PosService';
import React from 'react';
import RouteConstants from '../../../constants/RouteConstants';
import SectionHeader from '../../../components/common/SectionHeader';
import SecureComponent from '../../../components/SecureComponent';
import UrlGenerator from '../../../api/UrlGenerator';
import UrlUtils from '../../../utils/UrlUtils';
import { isEmpty } from 'lodash';
import squarePosImg from '../../../assets/images/Square_POS_Selected@2x.png';
import { withRouter } from 'react-router-dom';
import TrackingUtils from '../../../utils/TrackingUtils';
import StoreService from '../../../api/service/StoreService';
import TagManager from 'react-gtm-module';

// Handle Square Callback
@inject('dataStore')
@observer
class OnboardingSquareCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      created_stores: [],
      errorDetail: {},
      navigateLoading: false,
    };
  }

  componentDidMount() {
    const parameters = UrlUtils.getAllParamsFromUrl(this.props.location.search);

    if (isEmpty(parameters)) {
      const errorObj = {
        type: 'Invalid Request',
        description: 'Please connect to Square from your store',
      };
      TrackingUtils.eventMerchantOnboardingStep4POSConnectionFailed('square', errorObj.type);
      this.setState({ error: true, errorDetail: errorObj, loading: false });
    } else if (parameters.hasOwnProperty('error')) {
      //Verify if Permission has been granted  or not
      const errorObj = {
        type: parameters.error,
        description: parameters.error_description,
      };
      TrackingUtils.eventMerchantOnboardingStep4POSConnectionFailed('square', errorObj.type);
      this.setState({
        error: true,
        errorDetail: errorObj,
        loading: false,
      });
    } else {
      PosService.publishSquareCallbackData({
        ...parameters,
      })
        .then(async (response) => {
          this.setState({ created_stores: response.data });
          await this.props.dataStore.user.getDetails(true);
          TrackingUtils.eventMerchantOnboardingStep4POSConnected('square');
          const stores = await StoreService.getAllStores();
          let active_stores = [];
          let inactive_stores = [];
          for (let store of stores) {
            if (store.is_active) {
              active_stores.push(store);
            } else {
              inactive_stores.push(store);
            }
          }
          this.setState({
            error: false,
            loading: false,
          });
        })
        .catch((err) => {
          const errorObj = {
            type: 'Square Connection Failed',
            description:
              err?.data && err.data.message ? err.data.message : 'Please try to connect your square app again',
          };
          TrackingUtils.eventMerchantOnboardingStep4POSConnectionFailed('square', errorObj.type);
          this.setState({
            error: true,
            errorDetail: errorObj,
            loading: false,
          });
        });
    }
  }

  navigate = async () => {
    this.setState({ navigateLoading: true });
    const tagManagerArgs = {
      dataLayer: {
        event: 'onboardingCompleted', // event name declared during initialization
        userId: this.props.dataStore.user.epopId,
        // add more data you want here
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    this.props.history.replace(RouteConstants.MERCHANT.DASHBOARD);
  };

  navigateBack = async () => {
    this.setState({ navigateLoading: true });
    const user = await this.props.dataStore.user.getDetails();
    this.props.history.replace(
      UrlGenerator.getUiUrlWithPathParams(RouteConstants.ONBOARDING.CHOOSE_POS, { id: user.business.id }),
    );
  };

  render() {
    const { loading, error, errorDetail, navigateLoading, created_stores } = this.state;
    let stores = '';
    if (created_stores && !isEmpty(created_stores) && created_stores.length > 0) {
      stores = created_stores
        .map(
          (store) => 'Location (' + store.location_id + ',' + store.location_name + ') to Store: ' + store.store_name,
        )
        .join('\n');
    }
    if (loading) {
      return (
        <>
          <SectionHeader
            title='Connecting to Square'
            alignCenter={true}
            subTitle='Trying to connect to your square store'
          />

          <Row className='text-center mt-3 mt-lg-5 px-lg-5' gutter={[0, 20]}>
            <Col span={24}>
              <img src={squarePosImg} alt='Square' className='store-circle' />
            </Col>
            <Col span={24}>
              <Loading tip='Processing request...' noMinHeight={true} />
            </Col>
          </Row>
        </>
      );
    } else if (error) {
      return (
        <>
          <SectionHeader title={errorDetail.type} alignCenter={true} subTitle={errorDetail.description} />

          <Row className='text-center mt-3 mt-lg-5 px-lg-5'>
            <Col span={24}>
              <img src={squarePosImg} alt='Square' className='store-circle' />
            </Col>
            <Col span={24} className='mt-5'>
              <p className='privacy_des text-light-dark text-center mt-4 mb-5'>
                We were unable to process your request to connect to square
              </p>
            </Col>
          </Row>
          <OnboardingFooterBtn
            text='Reconnect to Square'
            alignCenter={true}
            loading={navigateLoading}
            onClick={this.navigateBack}
          />
        </>
      );
    } else
      return (
        <>
          <SectionHeader
            title='Congratulations'
            alignCenter={true}
            subTitle='You have successfully connected your Square account to e.pop'
          />

          <Row className='text-center mt-3 mt-lg-5 px-lg-5'>
            <Col span={24}>
              <img src={squarePosImg} alt='Square' className='store-circle' />
            </Col>
            <Col span={24} className='mt-5'>
              <p className='privacy_des text-light-dark text-center mt-4 mb-5'>
                We have created {created_stores.length} store(s) from your account information:
              </p>
              <p className='privacy_des text-light-dark text-center mt-4 mb-5'>{stores}</p>
            </Col>
          </Row>

          <OnboardingFooterBtn text='Continue' alignCenter={true} loading={navigateLoading} onClick={this.navigate} />
        </>
      );
  }
}

export default SecureComponent(withRouter(OnboardingSquareCallback));
