import { Col, Row, Typography } from 'antd';
import { inject, observer } from 'mobx-react';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import leftBackGroundImg from '../../assets/images/Left Menu Blue Circles@2x.png';
import logo from '../../assets/images/Group 1208.png';
import merchantImg from '../../assets/images/MERCHANT ICON@2x.png';
import rightArrowCheckImg from '../../assets/images/Menu Bar Active.png';
import rightArrowImg from '../../assets/images/Menu Bar Inactive.png';
import shoppingBagImg from '../../assets/images/iconfinder_shopping-bag-paper-store_2205215.png';
import supportImg from '../../assets/images/support_icon@2x.png';
import { withRouter } from 'react-router-dom';
import StoreService from '../../api/service/StoreService';
import firebase from '../../firebase';
import { getActiveMembershipForUser } from '../../api/service/MembershipService';

const { Title } = Typography;
const { MERCHANT } = RouteConstants;
@inject('dataStore')
@observer
class MerchantMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [
        {
          title: 'Merchant Dashboard',
          route: MERCHANT.DASHBOARD,
        },
        {
          title: 'Connect Your POS',
          icon: shoppingBagImg,
          route: MERCHANT.MANAGE_STORES,
        },
        // {
        //   title: 'Receipt Preferences',
        //   route: MERCHANT.MANAGE_STORES,
        // },
        {
          title: 'Membership',
          route: MERCHANT.SUBSCRIPTION_SETTINGS,
        },
        {
          title: 'Offers',
          route: MERCHANT.OFFERS,
        },
        {
          title: 'Account Settings',
          route: MERCHANT.SETTINGS,
        },
      ],
    };
    this.getStores();
  }
  navigateToStep = (step) => {
    this.props.history.push(step.route);
    this.props.toggleSidebar(false);
  };

  async componentDidMount() {
    firebase.auth.onAuthStateChanged((user) => {
      if (!user) {
        return;
      } else {
        this.getStores();
      }
    });
  }

  getStores = () => {
    StoreService.getAllStores()
      .then((response) => {
        let active_stores = [];
        for (let store of response) {
          if (store.is_active) {
            active_stores.push(store);
          }
        }
        if (active_stores.length > 0) {
          this.setState({
            steps: [
              {
                title: 'Merchant Dashboard',
                route: MERCHANT.DASHBOARD,
              },
              {
                title: 'Manage My Store',
                icon: shoppingBagImg,
                route: MERCHANT.MANAGE_STORES,
              },
              // {
              //   title: 'Receipt Preferences',
              //   route: MERCHANT.MANAGE_STORES,
              // },
              {
                title: 'Membership',
                route: MERCHANT.SUBSCRIPTION_SETTINGS,
              },
              {
                title: 'Offers',
                route: MERCHANT.OFFERS,
              },
              {
                title: 'Account Settings',
                route: MERCHANT.SETTINGS,
              },
            ],
          });
        } else {
          this.setState({
            steps: [
              {
                title: 'Merchant Dashboard',
                route: MERCHANT.DASHBOARD,
              },
              {
                title: 'Connect Your POS',
                icon: shoppingBagImg,
                route: MERCHANT.MANAGE_STORES,
              },
              // {
              //   title: 'Receipt Preferences',
              //   route: MERCHANT.MANAGE_STORES,
              // },
              {
                title: 'Membership',
                route: MERCHANT.SUBSCRIPTION_SETTINGS,
              },
              {
                title: 'Offers',
                route: MERCHANT.OFFERS,
              },
              {
                title: 'Account Settings',
                route: MERCHANT.SETTINGS,
              },
            ],
          });
        }
      })
      .catch(() => {});
  };

  render() {
    const { showBack, activeStep } = this.props;

    return (
      <div className='left-img-container ' style={{ backgroundImage: `url('${leftBackGroundImg}')` }}>
        <>
          <Row>
            <Col span={24}>
              <div className='info-container mb-1'>
                <Col flex={'auto'}>
                  {showBack && (
                    <div
                      className='closeSideBar'
                      onClick={() => {
                        this.props.toggleSidebar(false);
                      }}
                    >
                      <AiOutlineArrowLeft />
                    </div>
                  )}
                </Col>
                <div className='logo-container '>
                  <img src={merchantImg} alt='Store' />
                </div>
                <div className='logo-container'>
                  <img src={logo} alt='e.pop Logo' />
                </div>
              </div>
              <div className='des-container mt-1 merchant-name-heading'>
                <Title level={2}>{this.props.dataStore.user.business?.name}</Title>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className='adjustOffset' span={24}>
              <div className='des-info-container mt-5 '>
                {this.state.steps.map((step, index) => {
                  return (
                    <div className='des-info-lists' key={index}>
                      {step.icon && (
                        <div className='icon-bag' onClick={() => this.navigateToStep(step)}>
                          <img src={step.icon} alt='img' />
                        </div>
                      )}
                      <div>
                        <p onClick={() => this.navigateToStep(step)}>{step.title}</p>
                      </div>
                      <div className='merchant-icon'>
                        <img
                          onClick={() => this.navigateToStep(step)}
                          src={activeStep === index + 1 ? rightArrowCheckImg : rightArrowImg}
                          alt='Img'
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='des-info-container mt-5 '>
                <div className='des-info-lists'>
                  <div>
                    <a href='mailto:support@epoppay.com'>
                      <p>Contact Support</p>
                    </a>
                  </div>
                  <div className='merchant-icon'>
                    <a href='mailto:support@epoppay.com'>
                      <img src={supportImg} alt='Support' />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      </div>
    );
  }
}

export default withRouter(MerchantMenu);
