import 'firebase/auth';

import app from 'firebase/app';
// import { useAccordionToggle } from 'react-bootstrap';

class Firebase {
  constructor() {
    let configFileName = process.env.REACT_APP_FIREBASE_ACCOUNT;
    let unifiedServiceAccount = require('./' + configFileName);

    app.initializeApp(unifiedServiceAccount);
    this.auth = app.auth();
  }

  signInWithEmailAndPassword = (email, password) => {
    return this.auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredentials) =>
        Promise.all([Promise.resolve(userCredentials), userCredentials.user.getIdTokenResult()]),
      )
      .then(([userCredentials, tokenResult]) => {
        if ((tokenResult.claims.scopes || []).includes('merchant')) {
          return Promise.resolve(userCredentials);
        } else {
          throw Error('You are not allowed to access this app. You are welcome to sign-up as a new e.pop merchant!');
        }
      });
  };

  signOut = () => this.auth.signOut();

  sendPasswordResetEmail = (email) => this.auth.sendPasswordResetEmail(email);

  updatePassword = (password) => this.auth.currentUser.updatePassword(password);

  getIdToken = () => this.auth.currentUser?.getIdToken();

  getCredential = (email, password) => app.auth.EmailAuthProvider.credential(email, password);
}

export default Firebase;
