import ApiManager from '../ApiManager';
import HttpConstants from '../../constants/HttpConstants';
import UrlConstants from '../../constants/UrlConstants';

const PaymentService = {
  updatePaymentMethod: (paymentMethod) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_PAYMENT_METHOD.USECASE,
      HttpConstants.POST_METHOD,
      {
        stripe_payment_method: paymentMethod.id,
      },
    );
  },
};

export default PaymentService;
