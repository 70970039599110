import OnboardingLayout from './onboarding/common/OnboardingLayout';
import OnboardingRoute from './onboarding/OnboardingRouter';
import React from 'react';

const OnboardingContent = () => {
  return (
    <OnboardingLayout>
      <OnboardingRoute />
    </OnboardingLayout>
  );
};

export default OnboardingContent;
