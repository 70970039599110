import * as Yup from 'yup';

import { Button, Checkbox, Col, Row, message } from 'antd';
import { Field, Form, Formik } from 'formik';
import { NavLink, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { isNil } from 'lodash';

import { AiFillEye } from 'react-icons/ai';
import { BsEyeSlashFill } from 'react-icons/bs';
import EsignConsentModal from '../../../components/modal/EsignConsentModal';
import ReactFlagsSelect from 'react-flags-select';
import RouteConstants from '../../../constants/RouteConstants';
import StringConstants from '../../../constants/StringConstants';
import UrlUtils from '../../../utils/UrlUtils';
import ResponseUtils from '../../../utils/ResponseUtils';
import UserService from '../../../api/service/UserService';
import firebase from '../../../firebase';
import TrackingUtils from '../../../utils/TrackingUtils';
import { inject, observer } from 'mobx-react';
import ConsoleSafe from '../../../utils/ConsoleSafe';

const validateSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is mandatory'),
  lastName: Yup.string().required('Last name is mandatory'),
  email: Yup.string().required('Email is required').email('Email is not correct.'),
  password: Yup.string().when('isAuthenticatedUser', {
    is: false,
    then: Yup.string()
      .required('Password is required')
      .min(8, 'Must contain 8 characters')
      .matches(
        /^(?=.*[A-Z])(?=.*[~!@#£$%^&*()_=+<>?\-.])(?=.*[0-9])(?=.*[a-z])/,
        'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character',
      ),
    otherwise: Yup.string().oneOf(['', null], ''),
  }),
  confirmPassword: Yup.string().when('isAuthenticatedUser', {
    is: false,
    then: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords don't match")
      .required('Confirm Password is required.'),
    otherwise: Yup.string().oneOf([Yup.ref('password'), '', null], "Passwords don't match"),
  }),
  country: Yup.string().required('Country is required.'),
  terms: Yup.boolean().oneOf([true], 'Please accept our terms & conditions and privacy policy to continue.'),
});

@inject('dataStore')
@observer
class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isPreRegisteredUser: this.props.hasOwnProperty('isPreRegisteredUser') ? this.props.isPreRegisteredUser : false,
      isAuthenticatedUser: this.props.hasOwnProperty('isAuthenticatedUser') ? this.props.isAuthenticatedUser : false,
      showPassword: false,
      showConfirmPassword: false,
      selectedCountry: [],
      ref: null,
      partner: null,
      termsModalVisible: false,
      privacyPolicyModal: false,
      eSignConsentModal: false,
    };
  }

  componentDidMount() {
    const ref = UrlUtils.getParamsFromUrl(this.props.location.search, 'r');
    const partner = UrlUtils.getParamsFromUrl(this.props.location.search, 'p');
    this.setState({ ref, partner });
  }

  toggleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  toggleShowConfirmPassword = () => this.setState({ showConfirmPassword: !this.state.showConfirmPassword });

  onSubmit = (values) => {
    const { ref, partner } = this.state;

    if (!values.isAuthenticatedUser) {
      //normal flow, we know nothing about this user in theory, but if we figure that we do
      //then we first redirect towards login

      //insert SSO in this case, if needed BUT it would need not to be BeOne branded in this case.
      //Right now:
      // 1. we call the backend to create an authuser
      // 2. in case of success we create a merchant user
      // 2.1 in case of failure we send to login page
      // 3. after the creation of the merchant user we perform the login here
      this.setState({ loading: true });
      const loadingMessage = message.loading('Creating your account...', 0);
      const authData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        scope: 'merchant',
      };
      UserService.authSignUp(authData)
        .then((response) => {
          const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            country: values.country,
            uuid: response.uuid,
            ref,
            p: partner,
          };
          UserService.signUp(data)
            .then(() => {
              setTimeout(loadingMessage);
              message.success('Account created successfully', 2);
              //then login and redirect
              this.performLogin(values);
            })
            .catch((err) => {
              this.setState({ loading: false });
              setTimeout(loadingMessage);
              const errMessage = ResponseUtils.extractPublicAPIErrorMessage(
                err,
                'Cannot create your account at the moment, please try later',
              );
              message.error(errMessage);
            });
        })
        .catch((error) => {
          setTimeout(loadingMessage);
          if (isNil(error.data.isKnownUser) || error.data.isKnownUser === false) {
            //something went wrong
            this.setState({ loading: false });
            const errMessage = ResponseUtils.extractPublicAPIErrorMessage(
              error,
              'Cannot create your account at the moment, please try later',
            );
            message.error(errMessage);
          } else {
            // the user is an authenticated user, so we should redirect to login
            const errMsg = ResponseUtils.extractPublicAPIErrorMessage(
              error,
              'You are already an e.pop user, please sign-in instead.',
            );
            message.error(errMsg);
            this.setState({ loading: false });
            this.props.history.replace(RouteConstants.LOGIN);
          }
        });
    } else {
      //we can only arrive here if the user is already logged in
      let firebaseUser = firebase.auth.currentUser;
      if (isNil(firebaseUser)) {
        //somehow we didn't arrive on this page with a logged in user while the flag is on
        this.setState({ loading: false });
        const msgNotAllowed = 'Operation not allowed.';
        message.error(msgNotAllowed);
        this.props.history.replace({
          pathname: RouteConstants.SIGNUP,
          search: this.props.location.search,
        });
      }
      if (values.isPreRegisteredUser) {
        //either a BeOne member who registered as a merchant, or a merchant account which had been deactivated
        //known auth user (might be using other apps) but not preregistered (i.e. not a BeOne member)
        this.setState({ loading: true });
        const loadingMessage = message.loading('Activating your merchant account...', 0);
        const data = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          country: values.country,
          uuid: firebaseUser.uid,
          ref,
          p: partner,
        };
        UserService.activate(data)
          .then(() => {
            setTimeout(loadingMessage);
            message.success('Merchant account activated successfully', 2);
            //then force update the user info and redirect
            this.props.dataStore.user
              .getDetails(true) //force reloading
              .then((user) => {
                TrackingUtils.eventMerchantOnboardingStep1CompletedSSO(user.country, user.country_code);
                TrackingUtils.identifyStep1(
                  user.epopId,
                  null,
                  null,
                  null,
                  user.country,
                  user.country_code,
                  null,
                  new Date().toISOString(),
                  new Date().toISOString(),
                  null,
                  null,
                );
                this.setState({ loading: false });
                this.props.history.replace({
                  pathname: RouteConstants.REDIRECT,
                  search: this.props.location.search,
                });
              })
              .catch(() => {
                message.success('Merchant account activated successfully. Please login again.', 2);
                TrackingUtils.eventMerchantOnboardingStep1CompletedSSO(null, values.country);
                this.setState({ loading: false });
                firebase.auth.signOut(); //only option in this case so as to force reloading
                TrackingUtils.resetTracking();
              });
          })
          .catch((err) => {
            this.setState({ loading: false });
            setTimeout(loadingMessage);
            const errMessage = ResponseUtils.extractPublicAPIErrorMessage(
              err,
              'Cannot activate your merchant account at the moment, please try later',
            );
            message.error(errMessage);
          });
      } else {
        //known auth user (might be using other apps) but not preregistered (i.e. not a BeOne member)
        this.setState({ loading: true });
        const loadingMessage = message.loading('Creating your merchant account...', 0);
        const data = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          country: values.country,
          uuid: firebaseUser.uid,
          ref,
          p: partner,
        };
        UserService.signUp(data)
          .then(() => {
            setTimeout(loadingMessage);
            message.success('Merchant account created successfully', 2);
            this.props.dataStore.user
              .getDetails(true) //force reloading, but it does not guarantee that we have an epopid yet if the user is not yet registered
              .then((user) => {
                TrackingUtils.eventMerchantOnboardingStep1CompletedSSO(user.country, user.country_code);
                TrackingUtils.identifyStep1(
                  user.epopId,
                  null,
                  null,
                  null,
                  user.country,
                  user.country_code,
                  null,
                  new Date().toISOString(),
                  new Date().toISOString(),
                  null,
                  null,
                );
              })
              .catch((err) => {
                ConsoleSafe.log(
                  'Something went wrong while updating user information after a firebase auth change event: ' + err,
                );
                TrackingUtils.eventMerchantOnboardingStep1CompletedSSO(null, values.country);
              });
            //then redirect
            this.setState({ loading: false });
            this.props.history.replace({
              pathname: RouteConstants.REDIRECT,
              search: this.props.location.search,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
            setTimeout(loadingMessage);
            const errMessage = ResponseUtils.extractPublicAPIErrorMessage(
              err,
              'Cannot create your merchant account at the moment, please try later',
            );
            message.error(errMessage);
          });
      }
    }
  };

  performLogin = (values) => {
    firebase
      .signInWithEmailAndPassword(values.email, values.password)
      .then((userCredentials) => {
        this.props.dataStore.user
          .getDetails(true) //force reloading, but it does not guarantee that we have an epopid yet if the user is not yet registered
          .then((user) => {
            const actionCodeSettings = {
              url: process.env.REACT_APP_URL_REDIRECT_EMAIL_VERIFICATION,
            };
            userCredentials.user.sendEmailVerification(actionCodeSettings);
            this.setState({ loading: false });
            this.props.history.replace({
              pathname: RouteConstants.REDIRECT,
              search: this.props.location.search,
            });
          })
          .catch((err) => {
            ConsoleSafe.log(
              'Something went wrong while updating user information after a firebase auth change event: ' + err,
            );
            TrackingUtils.eventMerchantOnboardingStep1Completed(
              values.email,
              values.firstName,
              values.lastName,
              null,
              values.country,
            );
            this.setState({ loading: false });
            this.props.history.replace({
              pathname: RouteConstants.REDIRECT,
              search: this.props.location.search,
            });
          });
      })
      .catch(() => {
        var errMgs = "Couldn't log you in at the moment, please check your password and try logging in again";
        message.error(errMgs);
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      loading,
      showPassword,
      showConfirmPassword,
      isAuthenticatedUser,
      isPreRegisteredUser,
      selectedCountry,
      eSignConsentModal,
    } = this.state;

    let firebase_user = null;
    let isConfirmedAuthenticatedUser = false;
    if (isAuthenticatedUser) {
      firebase_user = firebase.auth.currentUser;
      isConfirmedAuthenticatedUser = !isNil(firebase_user);
    }
    let merchant_user = null;
    let isConfirmedPreRegisteredUser = false;
    if (isPreRegisteredUser) {
      merchant_user = this.props.dataStore.user;
      isConfirmedPreRegisteredUser = !isNil(merchant_user) && merchant_user.isUserSet();
    }

    return (
      <>
        <Formik
          initialValues={{
            isAuthenticatedUser: isConfirmedAuthenticatedUser,
            isPreRegisteredUser: isConfirmedPreRegisteredUser,
            firstName: isConfirmedPreRegisteredUser ? merchant_user.first_name : '',
            lastName: isConfirmedPreRegisteredUser ? merchant_user.last_name : '',
            email: isConfirmedAuthenticatedUser ? firebase_user.email : '',
            password: '',
            confirmPassword: '',
            country: '',
            terms: false,
          }}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form className='login-Form mt-2'>
              <Row gutter={[16, 0]}>
                <Col xs={24} lg={12}>
                  <div className='fieldContainer'>
                    <Field
                      className='ant-input'
                      type='text'
                      placeholder='First Name'
                      name='firstName'
                      disabled={isPreRegisteredUser}
                    />
                    <small className='text-danger'>
                      {errors.firstName && touched.firstName ? <div>{errors.firstName}</div> : null}
                    </small>
                  </div>
                </Col>
                <Col xs={24} lg={12}>
                  <div className='fieldContainer'>
                    <Field
                      className='ant-input'
                      type='text'
                      placeholder='Last Name'
                      name='lastName'
                      disabled={isPreRegisteredUser}
                    />
                    <small className='text-danger'>
                      {errors.lastName && touched.lastName ? <div>{errors.lastName}</div> : null}
                    </small>
                  </div>
                </Col>
              </Row>
              <div className='fieldContainer'>
                <Field
                  className='ant-input'
                  type={isPreRegisteredUser ? 'text' : 'email'}
                  placeholder='Email Address'
                  name='email'
                  disabled={isAuthenticatedUser}
                />
                <small className='text-danger'>
                  {errors.email && touched.email ? <div>{errors.email}</div> : null}
                </small>
              </div>
              {!isAuthenticatedUser && (
                <>
                  <div className='fieldContainer'>
                    <span className='eye-show' onClick={this.toggleShowPassword}>
                      {showPassword ? <AiFillEye /> : <BsEyeSlashFill />}
                    </span>
                    <Field
                      type={showPassword ? 'text' : 'password'}
                      className='ant-input'
                      placeholder={'Create Password'}
                      name='password'
                    />
                    <small className='text-danger'>
                      {errors.password && touched.password ? <div>{errors.password}</div> : null}
                    </small>
                  </div>
                  <div className='fieldContainer'>
                    <span className='eye-show' onClick={this.toggleShowConfirmPassword}>
                      {showConfirmPassword ? <AiFillEye /> : <BsEyeSlashFill />}
                    </span>
                    <Field
                      type={showConfirmPassword ? 'text' : 'password'}
                      className='ant-input'
                      placeholder='Confirm Password'
                      name='confirmPassword'
                    />
                    <small className='text-danger'>
                      {errors.confirmPassword && touched.confirmPassword ? <div>{errors.confirmPassword}</div> : null}
                    </small>
                  </div>
                </>
              )}
              <ReactFlagsSelect
                searchable
                className={selectedCountry.length > 0 ? 'flagDropDown' : 'flagDropDown place-Holder-Op'}
                placeholder='Country'
                selected={selectedCountry}
                name='country'
                onSelect={(data) => {
                  this.setState({ selectedCountry: data });
                  setFieldValue('country', data, true);
                }}
              />
              <small className='text-danger'>
                {errors.country && touched.country ? <div>{errors.country}</div> : null}
              </small>
              <div className='agree-terms-wrap'>
                <div className={'custom-checkbox terms-conditions w-100 custom-control-label'}>
                  <Checkbox
                    onChange={(e) => {
                      setFieldValue('terms', e.target.checked);
                    }}
                  >
                    I agree to e.pop’s{' '}
                    <a href={StringConstants.TERMS_AND_CONDITION_URL} target='blank' className='epop-link'>
                      Terms of Service
                    </a>
                    ,{' '}
                    <a href={StringConstants.PRIVACY_POLICY_URL} target='blank' className='epop-link'>
                      Privacy Policy
                    </a>
                    {/* CAUTION: Uncomment if we want to re-enable e-sign consents */}
                    {/* {' '}and{' '}
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ eSignConsentModal: true });
                      }}
                      className='epop-link'
                    >
                      E-sign Consent
                    </span> */}
                  </Checkbox>
                </div>
              </div>
              <small className='text-danger d-block'>
                {errors.terms && touched.terms ? <div>{errors.terms}</div> : null}
              </small>

              <div className='agree-terms-wrap d-flex align-items-center'>
                {!this.props.shopifyFlow && !isAuthenticatedUser ? (
                  <>
                    <div>
                      <p className='terms-conditions mt-3'>
                        Already have an account?
                        <NavLink
                          to={{
                            pathname: RouteConstants.LOGIN,
                            search: this.props.location.search,
                          }}
                        >
                          {' '}
                          Sign In Now
                        </NavLink>
                      </p>
                    </div>
                  </>
                ) : (
                  <div />
                )}
                <div className='button-wrap wrap-spacer-large'>
                  <Button htmlType='submit' className='success-submit px-4 px-lg-5' loading={loading}>
                    Continue
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {/* <TermsAndConditionsModal
          isVisible={termsModalVisible}
          onCancel={() => this.setState({ termsModalVisible: false })}
        /> */}
        {/* <PrivacyPolicyModal
          isVisible={privacyPolicyModal}
          onCancel={() => this.setState({ privacyPolicyModal: false })}
        /> */}
        <EsignConsentModal isVisible={eSignConsentModal} onCancel={() => this.setState({ eSignConsentModal: false })} />
      </>
    );
  }
}

export default withRouter(SignupForm);
