import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Col, Row } from 'antd';

import MembershipContent from './components/MembershipContent';
import MembershipSubtitle from './components/MembershipSubtitle';

import { AiOutlineArrowRight } from 'react-icons/ai';
import { activateShopifyMembership, createStripePaidMembership } from '../../api/service/MembershipService';
import RouteConstants from '../../constants/RouteConstants';
import UrlGenerator from '../../api/UrlGenerator';
import AccountSetupCompletePrompt from './merchantDashboard/AccountSetupCompletePrompt';
import ImpactStatistics from './merchantDashboard/ImpactStatistics';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import TrackingUtils from '../../utils/TrackingUtils';
import StringConstants from '../../constants/StringConstants';
import PosService from '../../api/service/PosService';
import { Badge, BaseBadge, FoundingBadge, GrowthBadge, Rocket } from '../../assets/images/svg/index';
import { Spinner } from '../../components/common/Spinner';

@inject('dataStore')
@observer
class MerchantDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user: {},
      membershipTier: null,
    };
  }

  async componentDidMount() {
    const { history } = this.props;
    const searchParams = new URLSearchParams(history?.location?.search);
    const membershipId = searchParams.get('charge_id');
    const membershipTypeId = searchParams.get('membership_type_id');
    const sessionId = searchParams.get('session_id');
    TrackingUtils.eventMerchantPagesDashboard();
    this.setState({ loading: true });
    const user = await this.props.dataStore.user.getDetails(true);
    this.setState({ membershipTier: user?.business?.default_membership_plan });
    await this.installShopifyStoreAndEntities(user.id);
    await this.reinstallShopifyApp(user.id);
    if (membershipId) {
      await activateShopifyMembership({ user_id: user?.id, charge_id: membershipId, epop_id: user?.epopId });
      const updatedUser = await this.props.dataStore.user.getDetails(true);
      this.setState({ membershipTier: updatedUser?.business?.default_membership_plan });
      history.push('/merchant/dashboard');
    }
    if (membershipTypeId && sessionId) {
      await createStripePaidMembership({
        user_id: user?.id,
        membership_type_id: membershipTypeId,
        session_id: sessionId,
        epop_id: user?.epopId,
      });
      const updatedUser = await this.props.dataStore.user.getDetails(true);
      this.setState({ membershipTier: updatedUser?.business?.default_membership_plan });
      history.push('/merchant/dashboard');
    }
    this.setState({ user, loading: false });
  }

  installShopifyStoreAndEntities = async (userId) => {
    const shopifyRegisterCookie = this.props.cookies.get('shopify_create');
    if (shopifyRegisterCookie) {
      const response = await PosService.createShopifyStoreAndEntities({
        userId,
        shop: shopifyRegisterCookie?.shop,
        token: shopifyRegisterCookie?.token,
      });
      if (response?.success) {
        this.props.cookies.remove('shopify_create');
      }
    }
  };

  reinstallShopifyApp = async (userId) => {
    const shopifyReinstallCookie = this.props.cookies.get('shopify_reinstall');
    if (shopifyReinstallCookie) {
      const response = await PosService.reinstallShopifyApp({
        userId,
        shop: shopifyReinstallCookie?.shop,
        token: shopifyReinstallCookie?.token,
      });
      if (response?.success) {
        this.props.cookies.remove('shopify_reinstall');
      }
    }
  };

  render() {
    const { loading, user } = this.state;

    const colors = { BASE: '#C0C0C0', GROWTH: '#FFD700' };

    // local styles
    const styles = {
      badge: {
        marginRight: '5px',
      },
      spinner: {
        height: '150px',
        color: colors.BASE,
      },
      spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      tier: {
        display: 'flex',
        alignItems: 'center',
        color: colors[this.state.membershipTier],
      },
      largeBadge: {
        height: '150px',
        width: '150px',
      },
    };

    return (
      <>
        <SectionHeader heading='Welcome to e.pop' />

        <Row>
          <Col span={24}>
            <div>
              <MembershipSubtitle tier={this.state.membershipTier} />
              {this.state.membershipTier === 'FFL' ? (
                <div className='img mt-2'>
                  <FoundingBadge style={styles.largeBadge} />
                </div>
              ) : this.state.membershipTier === 'BASE' ? (
                <div className='img mt-2'>
                  <BaseBadge style={styles.largeBadge} />
                </div>
              ) : this.state.membershipTier === 'GROWTH' ? (
                <div className='img mt-2'>
                  <GrowthBadge style={styles.largeBadge} />
                </div>
              ) : (
                <div style={styles.spinnerContainer} className='img mt-2'>
                  <Spinner style={styles.spinner} />
                </div>
              )}
              <MembershipContent tier={this.state.membershipTier} />
              {this.state.membershipTier && this.state.membershipTier !== 'FFL' ? (
                <div className='store-unlock mt-3 d-flex align-items-center justify-content-center justify-content-lg-end'>
                  <div className='store-lock-icon d-flex align-items-center'>
                    <p className='ml-2 mb-0 font-MuliBold'>
                      {this.state?.membershipTier === 'BASE'
                        ? 'Start your 30-day free trial'
                        : 'Change your membership plan'}
                    </p>
                  </div>
                  <div
                    className='epop-dashboard-arrow my-4'
                    onClick={() => {
                      const id = this.props.match.params.id;
                      this.props.history.push(
                        UrlGenerator.getUiUrlWithPathParams(RouteConstants.MERCHANT.SUBSCRIPTION_SETTINGS, {
                          id,
                        }),
                      );
                    }}
                  >
                    <AiOutlineArrowRight />
                  </div>
                </div>
              ) : null}
              <p style={{ fontSize: '1.15rem' }} className='text-left'>
                * Terms & conditions apply. See more{' '}
                <a href={StringConstants.TERMS_AND_CONDITION_URL} target='blank' className='epop-link'>
                  here.
                </a>
              </p>
            </div>
          </Col>
        </Row>

        {!loading && <AccountSetupCompletePrompt merchant={user} />}

        <ImpactStatistics />
      </>
    );
  }
}

export default SecureComponent(withRouter(withCookies(MerchantDashboard)));
