import { Col, Row, Typography } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { AiOutlineArrowRight } from 'react-icons/ai';
import ReceiptsCounter from '../../../components/common/ReceiptsCounter';
import StringConstants from '../../../constants/StringConstants';
import americanForestslogo from '../../../assets/images/banners/american_forests_logo.png';
import logo from '../../../assets/images/Group 1208.png';
import treesForCitiesLogo from '../../../assets/images/banners/trees_for_cities_logo.png';
import ConsoleSafe from '../../../utils/ConsoleSafe';
import ecologi from '../../../assets/images/banners/ecologi.png';
import onetreeplanted from '../../../assets/images/banners/onetreeplanted.png';
import veritree from '../../../assets/images/banners/veritree.png';

const { Title } = Typography;

@inject('dataStore')
@observer
class YourImpactSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  componentDidMount() {
    this.props.dataStore.user.getDetails();
    this.props.dataStore.user
      .getReceiptStatistics()
      .then((count) => this.setState({ count }))
      .catch((err) => {
        ConsoleSafe.log(err);
        this.setState({ count: 0 });
      });
  }

  render() {
    const { count } = this.state;

    let carbonEmission = count ? (count * 2.5).toFixed(1) : 0;
    return (
      <Row className='justify-content-between'>
        <Col md={{ span: '11' }} span={24}>
          <Title className='privacy_heading text-light-dark mt-5' level={5}>
            Your Sustainability Impact
          </Title>

          <div className='epop-dashboard-impact mt-3'>
            <p className='font-18 font-italic font-MuliBold' style={{ color: '#666666' }}>
              {this.props.dataStore.user.business?.name} All Locations
            </p>
            <div className='mt-5'>
              <Title className='text-center font-weight-bold' level={4}>
                Digital Receipts Created
              </Title>
              <ReceiptsCounter count={count} />
            </div>
            <p style={{ fontSize: '1.15rem' }} className='mt-2'>
              Which is equivalent to approximately <strong>{carbonEmission}g of co2</strong>
            </p>
            {/* <div className='epop-dashboard-bottom'>
              <img src={treesPlantedImg} alt='Trees Planted' />
            </div> */}
            <p style={{ fontSize: '0.75rem', marginTop: 40 }}>
              * an average-sized paper receipts emits around 2.5g of carbon during its lifetime - from production, to
              transport, to landfill
            </p>
          </div>
        </Col>

        <Col md={{ span: '11' }} span={24}>
          <Title className='privacy_heading text-light-dark mt-5 font-weight-bold mt-4' level={5}>
            e.pop Mission
          </Title>
          <div className='epop-dashboard-impact mt-3'>
            <Row>
              <Col xs={24} className='mt-1 text-center'>
                <img src={logo} className='community-impact-logo' alt='e.pop' />
              </Col>
              <Col xs={24} className='mt-1 text-center'>
                <p className='community-impact-title'>
                  We are planting a tree for each new merchant & customer, pledging to plant 1M trees by 2025
                </p>
                <p className='community-impact-title'>Trees planted</p>
                <ReceiptsCounter count={120000} />
              </Col>
              <Col xs={24} className='mt-1 text-center'>
                <p className='community-impact-hashtag'>#RedoReceipts</p>
              </Col>
              <Col xs={24} className='mt-2 text-center'>
                <Row gutter={[3, 1]} className='justify-content-center'>
                  <Col span='auto'>
                    <img src={americanForestslogo} className='community-impact-partners-logo' alt='American Forests' />
                  </Col>
                  <Col span='auto'>
                    <img src={onetreeplanted} className='community-impact-partners-logo' alt='One Tree Planted' />
                  </Col>
                  <Col span='auto'>
                    <img src={veritree} className='community-impact-partners-logo' alt='Veritree' />
                  </Col>
                  <Col span='auto'>
                    <img src={treesForCitiesLogo} className='community-impact-partners-logo' alt='Trees for cities' />
                  </Col>
                  <Col span='auto'>
                    <img src={ecologi} className='community-impact-partners-logo' alt='American Forests' />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} className='mt-2 text-center community-impact-subtext'>
                We are proud to be partnering with{' '}
                <span className='community-impact-subtext-bold'>
                  American Forests, Trees for Cities, Ecologi, One Tree Planted & Veritree
                </span>
              </Col>
              <Col xs={24} className='mt-2 text-right d-flex align-items-center justify-content-end'>
                <a href={StringConstants.COMMUNITY_IMPACT_URL} className='community-impact-subtext-bold'>
                  Learn more
                </a>
                <div className='community-impact-arrow'>
                  <a href={StringConstants.COMMUNITY_IMPACT_URL}>
                    <AiOutlineArrowRight />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

export default YourImpactSection;
