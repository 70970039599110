import { Col, Row } from 'antd';
import React, { Component } from 'react';

import PaymentMethodForm from './onboardingPaymentMethod/PaymentMethodForm';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import { withRouter } from 'react-router-dom';

class OnboardingPaymentMethod extends Component {
  render() {
    return (
      <>
        <SectionHeader
          title='Add your payment method'
          subTitle='Enter your credit card details for you to start your e.pop journey.'
        />
        <Row>
          <Col span={24} md={{ span: 20 }} lg={{ span: 16 }}>
            <div className={`font-muli-Regular font-italic text-light-dark`}>
              <p>
                Your credit card will only be charged once your free trial period has ended and a reminder notification
                will be sent before payment is processed.
              </p>
            </div>
          </Col>
          <Col span={24} md={{ span: 20 }} lg={{ span: 16 }} className='mt-3'>
            <img
              className='powered-by-stripe'
              src={require('../../assets/images/powered-by-stripe-black.png').default}
              alt='Powered by Stripe'
            />
          </Col>
          <Col span={24} md={{ span: 16 }} lg={{ span: 14 }} className='mt-5'>
            <PaymentMethodForm />
          </Col>
        </Row>
      </>
    );
  }
}

export default SecureComponent(withRouter(OnboardingPaymentMethod));
