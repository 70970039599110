import * as Yup from 'yup';

import { Button, Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import ReactFlagsSelect from 'react-flags-select';
import ConsoleSafe from '../../../../utils/ConsoleSafe';

const validateSchema = Yup.object().shape({
  storename: Yup.string().required('Store name is required'),
  addressline1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip is required'),
  EmailAddress: Yup.string().required('Email is required').email('Email is not correct.'),
  phonenumber: Yup.string().required('Phone number is required'),
});

let AddStoreLocationForm = () => {
  let [selectedFlag, setSelectedFlag] = useState('');

  return (
    <Formik
      initialValues={{
        EmailAddress: '',
        storename: '',
        addressline1: '',
        addressline2: '',
        city: '',
        state: '',
        zip: '',
        phonenumber: '',
        terms: false,
      }}
      validationSchema={validateSchema}
      onSubmit={(values) => {
        ConsoleSafe.log(values);
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form className='login-Form'>
          <Row>
            <Col span={6}>
              <div className='form-label-header'>
                <p className='frontvalues'>Store Name</p>
              </div>
            </Col>
            <Col span={1}></Col>
            <Col span={17}>
              <Field className='ant-input mt-2' type='text' placeholder='Coffee 66' name='storename' />
              <small className='text-danger'>
                {errors.storename && touched.storename ? <div>{errors.storename}</div> : null}
              </small>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <div className='form-label-header'>
                <p className='frontvalues'>Address Line 1</p>
              </div>
            </Col>
            <Col span={1}></Col>
            <Col span={17}>
              <Field className='ant-input mt-2' type='text' placeholder='200 Time Square' name='addressline1' />
              <small className='text-danger'>
                {errors.addressline1 && touched.addressline1 ? <div>{errors.addressline1}</div> : null}
              </small>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <div className='form-label-header'>
                <p className='frontvalues'>Address Line 2</p>
              </div>
            </Col>
            <Col span={1}></Col>
            <Col span={17}>
              <Field className='ant-input mt-2' type='text' placeholder='Unit 105' name='addressline2' />
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <div className='form-label-header'>
                <p className='frontvalues'>City</p>
              </div>
            </Col>
            <Col span={1}></Col>
            <Col span={17}>
              <Field className='ant-input mt-2' type='text' placeholder='200 Time Square' name='city' />
              <small className='text-danger'>{errors.city && touched.city ? <div>{errors.city}</div> : null}</small>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Row>
                <Col span={11}>
                  <div className='form-label-header'>
                    <p className='frontvalues'>State</p>
                  </div>
                </Col>
                <Col span={1}></Col>
                <Col span={11}>
                  <ReactFlagsSelect
                    className={selectedFlag.length > 0 ? 'flagDropDown' : 'flagDropDown place-Holder-Op'}
                    placeholder='NY'
                    selected={selectedFlag}
                    name='state'
                    onSelect={(data) => {
                      setSelectedFlag(data);
                      setFieldValue('state', data, true);
                    }}
                  />
                  <small className='text-danger'>
                    {errors.state && touched.state ? <div>{errors.state}</div> : null}
                  </small>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row>
                <Col span={6}>
                  <div className='form-label-header'>
                    <p className='frontvalues'>Zip</p>
                  </div>
                </Col>
                <Col span={1}></Col>
                <Col span={17}>
                  <Field className='ant-input mt-2' type='text' placeholder='10011' name='zip' />
                  <small className='text-danger'>{errors.zip && touched.zip ? <div>{errors.zip}</div> : null}</small>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <div className='form-label-header'>
                <p className='frontvalues'>Phone Number</p>
              </div>
            </Col>
            <Col span={1}></Col>
            <Col span={17}>
              <Field className='ant-input mt-2' type='text' placeholder='646-960-6460' name='phonenumber' />
              <small className='text-danger'>
                {errors.phonenumber && touched.phonenumber ? <div>{errors.phonenumber}</div> : null}
              </small>
            </Col>
          </Row>

          <div className='button-wrap wrap-spacer-medium ml-lg-auto text-center text-lg-right'>
            <Row>
              <Col span={12} className='text-lg-left'>
                <Button type='cancel' className='gray-submit btnShape'>
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button htmlTpe='submit' className='success-submit btnShape'>
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddStoreLocationForm;
