import { Button, Result } from 'antd';
import { inject, observer } from 'mobx-react';

import Loading from './Loading';
import React from 'react';
import RouteConstants from '../constants/RouteConstants';
import UserAgreement from './UserAgreement';
import firebase from '../firebase';
import { withRouter } from 'react-router-dom';

const SecureComponent = (Page) => {
  @inject('dataStore')
  @observer
  class SecureComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoggedIn: null,
        agreementAccepted: false,
        isActive: false,
      };
    }

    componentDidMount() {
      // MM: this needs fixing
      // if (!this.props.dataStore.user.isUserSet()) {
      //   let route = RouteConstants.REDIRECT;
      //   if (this.props.location && this.props.location.pathname) {
      //     route += '?redirect=' + this.props.location.pathname;
      //     if (this.props.location.search) route += encodeURIComponent(this.props.location.search);
      //   }
      //   this.props.history.replace(route);
      // }
      const agreementAccepted = this.props.dataStore.user.isUserAgreementAccepted();
      const isActive = this.props.dataStore.user.isActiveUser();
      this.setState({ isActive: isActive });
      this.listener = firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.setState({ authUser, isLoggedIn: true, agreementAccepted, isActive });
        } else {
          this.setState({
            authUser: null,
            isLoggedIn: false,
            agreementAccepted,
            isActive: false,
          });
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      if (this.state.isLoggedIn === null) return <Loading tip='Loading'></Loading>;
      if (!this.state.isLoggedIn) {
        return (
          <Result
            status='403'
            title='403'
            subTitle='Sorry, you are not authorized to access this page.'
            extra={
              <Button type='primary' onClick={() => this.props.history.replace(RouteConstants.LOGIN)}>
                Login
              </Button>
            }
          />
        );
      } else {
        if (!this.state.isActive || (this.state.isActive && this.state.agreementAccepted))
          return <Page {...this.state} {...this.props} />;
        else return <UserAgreement />;
      }
    }
  }

  return withRouter(SecureComponent);
};

export default SecureComponent;
