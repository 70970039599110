import ApiManager from '../ApiManager';
import HttpConstants from '../../constants/HttpConstants';
import UrlConstants from '../../constants/UrlConstants';

const PosService = {
  getSquareOauthUrl: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SQUARE_OAUTH_URL.USECASE,
      HttpConstants.GET_METHOD,
    );
  },

  getShopifyOauthUrl: (shop_domain) => {
    return ApiManager.makeApiCall(UrlConstants.GET_SHOPIFY_OAUTH_URL.USECASE, HttpConstants.GET_METHOD, shop_domain);
  },

  createShopifyStoreAndEntities: (data) => {
    return ApiManager.makeApiCall(UrlConstants.CREATE_SHOPIFY_STORE.USECASE, HttpConstants.POST_METHOD, data);
  },

  reinstallShopifyApp: (data) => {
    return ApiManager.makeApiCall(UrlConstants.REINSTALL_SHOPIFY_APP.USECASE, HttpConstants.POST_METHOD, data);
  },

  reinstallShopifyAppNewBusiness: (data) => {
    return ApiManager.makeApiCall(
      UrlConstants.REINSTALL_SHOPIFY_APP_NEW_BUSINESS.USECASE,
      HttpConstants.POST_METHOD,
      data,
    );
  },

  publishSquareCallbackData: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PUBLISH_SQUARE_CALLBACK.USECASE,
      HttpConstants.GET_METHOD,
      data,
    );
  },

  publishShopifyCallbackData: (isUserSet, data) => {
    if (isUserSet)
      return ApiManager.makeApiCallWithAuthentication(
        UrlConstants.PUBLISH_SHOPIFY_CALLBACK_AUTH.USECASE,
        HttpConstants.GET_METHOD,
        data,
      );
    else return ApiManager.makeApiCall(UrlConstants.PUBLISH_SHOPIFY_CALLBACK.USECASE, HttpConstants.GET_METHOD, data);
  },
};

export default PosService;
