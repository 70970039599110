import 'firebase/auth';

import HttpConstants from '../constants/HttpConstants';
import RouteConstants from '../constants/RouteConstants';
import UrlGenerator from './UrlGenerator';
import axios from 'axios';
import firebase from '../firebase';
import { isEmpty } from 'lodash';

function makeApiCall(usecase, httpMethod, data) {
  return makeApiCallInternal(usecase, httpMethod, false, data);
}

function makeApiCallWithAuthentication(usecase, httpMethod, data) {
  return makeApiCallInternal(usecase, httpMethod, true, data);
}

async function makeApiCallInternal(usecase, httpMethod, authenticatedCall, data) {
  let { url, params } = UrlGenerator.updatePathParamsForApiUrl(UrlGenerator.generateUrl(usecase), data);
  let headers = {};

  if (authenticatedCall) {
    const userToken = await firebase.getIdToken();
    headers = {
      Authorization: 'Bearer ' + userToken,
    };
  }

  const requestConfig = { headers, method: httpMethod };
  const env = process.env.NODE_ENV || 'development';
  const bypassCORS = process.env.REACT_APP_BYPASS_CORS === 'true';

  if (bypassCORS) {
    axios.defaults.withCredentials = false;
  } else {
    axios.defaults.withCredentials = true;
  }

  if (authenticatedCall && !bypassCORS) {
    requestConfig.withCredentials = true;
  } else if (env === 'development') {
    requestConfig.withCredentials = false;
  }

  switch (httpMethod) {
    case HttpConstants.GET_BLOB_METHOD:
      requestConfig.responseType = 'blob';
      requestConfig.method = 'get';
      break;
    case HttpConstants.GET_METHOD:
    case HttpConstants.DELETE_METHOD:
      if (params) {
        let updatedUrlConfig = UrlGenerator.addQueryParamsToApiUrl(url, params);
        url = updatedUrlConfig.url;
        params = updatedUrlConfig.params;
      }
      break;
    default:
      break;
  }

  requestConfig.url = url;
  if (!isEmpty(params) || params instanceof FormData) requestConfig.data = params;

  return new Promise((response, reject) => {
    axios
      .request(requestConfig)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          response(res.data);
        } else {
          throw res;
        }
      })
      .catch(async (error) => {
        if (error.response && error.response.status === 401) {
          reject(error.response);
        } else if (error.response) {
          reject(error.response);
        } else {
          reject(error.message);
        }
      });
  });
}

const ApiManager = {
  makeApiCall,
  makeApiCallWithAuthentication,
};

export default ApiManager;
