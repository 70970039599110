import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';

class StoreContactDetails extends Component {
  render() {
    const { detail } = this.props;
    return (
      <Row>
        <Col xs={24} lg={20} xxl={18}>
          <Button
            type='submit'
            style={{ float: 'right' }}
            className='success-submit edit-store-btn'
            onClick={this.props.onEdit}
          >
            Edit
          </Button>
          <div className='store-details-wrap'>
            <Row>
              <Col span={8}>
                <div className='store-field-labels'>
                  <span>Store Name</span>
                </div>
              </Col>
              <Col span={14}>
                <span className='store-field-details'>{detail?.name}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className='store-field-labels'>
                  <span>Address Line 1</span>
                </div>
              </Col>
              <Col span={14}>
                <span className='store-field-details'>{detail.address?.address_line_1}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className='store-field-labels'>
                  <span>Address Line 2</span>
                </div>
              </Col>
              <Col span={14}>
                <span className='store-field-details'>{detail.address?.address_line_2}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className='store-field-labels'>
                  <span>City</span>
                </div>
              </Col>
              <Col span={14}>
                <span className='store-field-details'>{detail.address?.city}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className='store-field-labels'>
                  <span>State</span>
                </div>
              </Col>
              <Col span={6}>
                <span className='store-field-details'>
                  {detail.address?.province && `${detail.address?.province}, `} {detail.address?.country}{' '}
                  {detail.address?.country_code ? `(${detail.address?.country_code})` : ''}
                </span>
              </Col>
              <Col span={3}>
                <div className='store-field-labels'>
                  <span>Zip</span>
                </div>
              </Col>
              <Col span={7}>
                <span className='store-field-details'>{detail.address?.zip}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className='store-field-labels'>
                  <span>Email</span>
                </div>
              </Col>
              <Col span={14}>
                <span className='store-field-details'>{detail.email}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className='store-field-labels'>
                  <span>Phone Number</span>
                </div>
              </Col>
              <Col span={14}>
                <span className='store-field-details'>{detail.phone}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className='store-field-labels'>
                  <span>Facebook</span>
                </div>
              </Col>
              <Col span={14}>
                <span className='store-field-details'>{detail.facebook_url}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className='store-field-labels'>
                  <span>Instagram</span>
                </div>
              </Col>
              <Col span={14}>
                <span className='store-field-details'>{detail.instagram_handle}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className='store-field-labels'>
                  <span>Website</span>
                </div>
              </Col>
              <Col span={14}>
                <span className='store-field-details'>{detail.website}</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

export default StoreContactDetails;
