import { Col, Row, Typography } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { AiOutlineArrowRight } from 'react-icons/ai';
import MembershipPlans from '../../../components/membership/MembershipPlanConstants';
import RouteConstants from '../../../constants/RouteConstants';
import StringConstants from '../../../constants/StringConstants';
import UrlGenerator from '../../../api/UrlGenerator';
import adsActiveImage from '../../../assets/images/banners/BannerAds_Blue@2x.png';
import adsDisabledImage from '../../../assets/images/banners/BannerAds_Grey@2x.png';
import { find } from 'lodash';
import promoActiveImage from '../../../assets/images/banners/PromoOffers_Blue@2x.png';
import promoDisabledImage from '../../../assets/images/banners/PromoOffers_Grey@2x.png';
import receiptsActiveImage from '../../../assets/images/banners/Receipts_Blue@2x.png';
import unlockImage from '../../../assets/images/unlock@2x.png';
import { withRouter } from 'react-router-dom';

const { Title } = Typography;

const membershipFeatures = {
  RECEIPTS: 'Digital receipts*',
  PROMOS: 'Promotional offers',
  ADS: 'Banner advertisments',
};

@inject('dataStore')
@observer
class MonthlyUsageSection extends Component {
  isPremiumPlan() {
    const { store } = this.props;
    return store.membership_plan === StringConstants.MEMBERSHIP_PLANS.PREMIUM;
  }

  isStarterPlan() {
    const { store } = this.props;
    return store.membership_plan === StringConstants.MEMBERSHIP_PLANS.STARTER;
  }

  getMembershipPlanAllowance = (feature) => {
    const { store } = this.props;
    if (!feature) {
      return null;
    }
    const obj = find(MembershipPlans, { feature });
    if (!obj) {
      return null;
    }
    return obj[store.membership_plan];
  };

  render() {
    const { store, stats = {} } = this.props;
    const disableMembership = false;

    return (
      <Row>
        <Col span={24}>
          <Title className='privacy_heading text-light-dark mt-5 mb-0 font-weight-bold' level={5}>
            Monthly Plan Allowance
          </Title>
          <div className='store-monthly dashbordContainerDetails w-100 mt-3'>
            <p className='font-22 font-italic font-MuliBold' style={{ color: '#666666' }}>
              {store.name}
            </p>
            <p className='font-weight-bold'>You have used: </p>
            <Row className='justify-content-center'>
              <Col className='text-center' sm={{ span: 8 }} span={24}>
                <p className='font-weight-bold'>Receipts</p>
                <div className='store-dashboard-allowance'>
                  <img src={receiptsActiveImage} width='80px' height='80px' alt='Receipts' />
                  <p className='mb-0'>{stats.receipts ? stats.receipts : 0}</p>
                </div>
                <p className='font-weight-medium mb-0 mt-1'>
                  Out of <strong>{this.getMembershipPlanAllowance(membershipFeatures.RECEIPTS) ?? ''}</strong>
                </p>
              </Col>
              <Col className='text-center' sm={{ span: 8 }} span={24}>
                <p className='font-weight-bold'>Promo offers</p>
                <div className='store-dashboard-allowance'>
                  <img
                    src={!this.isStarterPlan() ? promoActiveImage : promoDisabledImage}
                    width='80px'
                    height='80px'
                    alt='offers'
                  />
                  <p className='mb-0'>0</p>
                </div>
                <p className='font-weight-medium mb-0 mt-1'>
                  {!this.isStarterPlan() && (
                    <>
                      Out of <strong>{this.getMembershipPlanAllowance(membershipFeatures.PROMOS) ?? ''}</strong>
                    </>
                  )}
                </p>
              </Col>
              <Col className='text-center' sm={{ span: 8 }} span={24}>
                <p className='font-weight-bold'>Banner Ads</p>
                <div className='store-dashboard-allowance'>
                  <img
                    src={this.isPremiumPlan() ? adsActiveImage : adsDisabledImage}
                    width='130px'
                    height='50px'
                    alt='ads'
                  />
                  <p className='mb-0'>0</p>
                </div>
                <p className='font-weight-medium mb-0 mt-1'>
                  {this.isPremiumPlan() && (
                    <>
                      Out of <strong>{this.getMembershipPlanAllowance(membershipFeatures.ADS) ?? ''}</strong>
                    </>
                  )}
                </p>
              </Col>
            </Row>
            {disableMembership ? (
              <div className='store-unlock mt-3 d-flex align-items-center justify-content-center justify-content-lg-end'>
                <div className='store-lock-icon d-flex align-items-center'>
                  <img src={unlockImage} width='15px' height='18px' alt='Unlock' />
                  <p className='ml-2 mb-0 font-MuliBold'>Unlock more with PREMIUM membership</p>
                </div>
                <div
                  className='epop-dashboard-arrow my-4'
                  onClick={() => {
                    const id = this.props.match.params.id;
                    this.props.history.push(
                      UrlGenerator.getUiUrlWithPathParams(RouteConstants.STORE.MEMBERSHIP, {
                        id,
                      }),
                    );
                  }}
                >
                  <AiOutlineArrowRight />
                </div>
              </div>
            ) : (
              <div className='mt-3'></div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default withRouter(MonthlyUsageSection);
