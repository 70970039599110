import * as Yup from 'yup';

import { Alert, Button, message } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';

import ErrorMsgConstants from '../../../constants/ErrorMsgConstants';
import { Link } from 'react-router-dom';
import RouteConstants from '../../../constants/RouteConstants';
import firebase from '../../../firebase';

const validateSchema = Yup.object().shape({
  email: Yup.string().required('Please enter your email ID').email('Please enter a valid email.'),
});

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      resetSuccess: false,
      resetError: false,
      errorMessage: null,
    };
  }

  onSubmit = (values) => {
    this.setState({ loading: true });
    const loading = message.loading('Submitting Request...', 0);

    firebase
      .sendPasswordResetEmail(values.email)
      .then(() => {
        setTimeout(loading);
        this.setState({
          resetSuccess: true,
          resetError: false,
          errorMessage: null,
          loading: false,
        });
        this.props.redirect();
      })
      .catch((error) => {
        setTimeout(loading);
        this.setState({
          errorMessage: ErrorMsgConstants.FORGOT_PASSWORD[error.code],
          resetSuccess: false,
          resetError: true,
          loading: false,
        });
      });
  };

  render() {
    const { loading, resetSuccess, resetError, errorMessage } = this.state;

    return (
      <>
        <div style={{ marginBottom: 20 }}>
          {resetSuccess && <Alert message='Password reset e-mail has been sent' type='success' />}
          {resetError && <Alert message={errorMessage} type='error' />}
        </div>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
        >
          {({ errors, touched }) => (
            <Form className='login-Form'>
              <div className='fieldContainer'>
                <Field className='mt-2' type='email' placeholder='Email Address' name='email' />
                <small className='text-danger'>
                  {errors.email && touched.email ? <div>{errors.email}</div> : null}
                </small>
              </div>

              <div className='d-flex flex-wrap wrap-spacer-medium pt-1 pb-1'>
                <div className='terms-conditions mt-2'>
                  Already have an account?&nbsp;
                  <Link to={RouteConstants.LOGIN} className='ml-2'>
                    Sign in
                  </Link>
                </div>
                <div className='button-wrap ml-auto text-right'>
                  <Button htmlType='submit' className='success-submit px-4 px-lg-5' loading={loading}>
                    Reset
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default ForgotPasswordForm;
