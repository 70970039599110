import 'semantic-ui-css/semantic.min.css';

import * as Yup from 'yup';

import { Button, Col, Row, message } from 'antd';
import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';
import RouteConstants from '../../../constants/RouteConstants';
import StoreService from '../../../api/service/StoreService';
import UrlGenerator from '../../../api/UrlGenerator';
import firebase from '../../../firebase';
import { withRouter } from 'react-router-dom';
import TrackingUtils from '../../../utils/TrackingUtils';

const validateSchema = Yup.object().shape({
  // password: Yup.string()
  //   .required('Password is required')
  //   .min(8, 'Password between 8 and 20 characters')
  //   .max(20, 'Password between 8 and 20 characters'),
  // email: Yup.string()
  //   .required('Email is required')
  //   .email('Email is not correct.'),
});

@inject('dataStore')
@observer
class StorePosForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  logout = async () => {
    this.props.dataStore.user.reset();
    this.props.dataStore.shop.reset();
    await firebase.signOut();
    this.props.history.replace(RouteConstants.SIGNOUT);
    TrackingUtils.resetTracking();
  };

  onSubmit = (values) => {
    this.setState({ loading: true });
    StoreService.updateStore(this.props.detail.id, {
      category: values.category,
    })
      .then((response) => {
        this.props.dataStore.shop.setDetails(response);
        this.setState({ loading: false });
        this.props.history.replace(
          UrlGenerator.getUiUrlWithPathParams(RouteConstants.STORE.DASHBOARD, {
            id: this.props.storeId,
          }),
        );
      })
      .catch(() => {
        message.error("Couldn't update category at the moment");
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { loading } = this.state;
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
          category: this.props.detail?.category?.id,
        }}
        validationSchema={validateSchema}
        onSubmit={this.onSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form className='login-Form'>
            <Row>
              <Col xs={24} lg={20}>
                <Row className='mt-5'>
                  <Col span={24}>
                    <p className='font-MuliBold mb-1'>Change Business Category</p>
                  </Col>
                </Row>
                <div className='mt-2'>
                  <Dropdown
                    className='image-drop-down'
                    placeholder='Dining & Beverage'
                    fluid
                    selection
                    onChange={(e, data) => {
                      setFieldValue('category', data.value);
                    }}
                    options={this.props.categories}
                    value={values.category}
                  />
                </div>

                <small className='text-danger'>
                  {errors.category && touched.category ? <div>{errors.category}</div> : null}
                </small>
              </Col>
              <Col span={24}>
                <div className='d-flex flex-wrap justify-content-center pt-5 pb-5'>
                  <div className='button-wrap mr-lg-auto'>
                    <Button className='primary-green-btn btnShape' onClick={this.logout} disabled={loading}>
                      Logout
                    </Button>
                  </div>

                  <div className='button-wrap ml-md-5 ml-lg-0'>
                    <Button htmlType='submit' className='success-submit ' loading={loading}>
                      Save &amp; Exit
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(StorePosForm);
