const UrlConstants = {
  CREATE_AUTH_USER: {
    USECASE: 'CREATE_AUTH_USER',
    URL: '/api/admin/auth',
  },
  CREATE_USER: {
    USECASE: 'CREATE_USER',
    URL: '/api/merchant/user',
  },
  ACTIVATE_USER: {
    USECASE: 'ACTIVATE_USER',
    URL: '/api/merchant/user/activate',
  },
  CHECK_USER_STATUS: {
    USECASE: 'CHECK_USER_STATUS',
    URL: '/api/merchant/user/check',
  },
  GET_USER_DETAIL: {
    USECASE: 'GET_USER_DETAIL',
    URL: '/api/merchant/user',
  },
  UPDATE_USER_DETAIL: {
    USECASE: 'UPDATE_USER_DETAIL',
    URL: '/api/merchant/user',
  },
  GET_STORES: {
    USECASE: 'GET_STORES',
    URL: '/api/merchant/store',
  },
  GET_STORE_DETAIL: {
    USECASE: 'GET_STORE_DETAIL',
    URL: '/api/merchant/store/:id',
  },
  CREATE_STORE: {
    USECASE: 'CREATE_STORE',
    URL: '/api/merchant/store',
  },
  UPDATE_STORE: {
    USECASE: 'UPDATE_STORE',
    URL: '/api/merchant/store/:id',
  },
  DEACTIVATE_STORE: {
    USECASE: 'DEACTIVATE_STORE',
    URL: '/api/merchant/store/:id',
  },

  GET_ALL_CATEGORIES: {
    USECASE: 'GET_ALL_CATEGORIES',
    URL: '/api/merchant/categories',
  },

  GET_AGREEMENT: {
    USECASE: 'GET_AGREEMENT',
    URL: '/api/merchant/agreement',
  },
  ACCEPT_AGREEMENT: {
    USECASE: 'ACCEPT_AGREEMENT',
    URL: '/api/merchant/agreement/accept',
  },

  GET_SQUARE_OAUTH_URL: {
    USECASE: 'GET_SQUARE_OAUTH_URL',
    URL: '/api/merchant/square',
  },
  PUBLISH_SQUARE_CALLBACK: {
    USECASE: 'PUBLISH_SQUARE_CALLBACK',
    URL: '/api/merchant/square/callback',
  },

  GET_SHOPIFY_OAUTH_URL: {
    USECASE: 'GET_SHOPIFY_OAUTH_URL',
    URL: '/api/merchant/shopify',
  },

  CREATE_SHOPIFY_STORE: {
    USECASE: 'CREATE_SHOPIFY_STORE',
    URL: '/api/merchant/shopify/create-store',
  },

  REINSTALL_SHOPIFY_APP: {
    USECASE: 'REINSTALL_SHOPIFY_APP',
    URL: '/api/merchant/shopify/reinstall-shopify-app',
  },

  REINSTALL_SHOPIFY_APP_NEW_BUSINESS: {
    USECASE: 'REINSTALL_SHOPIFY_APP_NEW_BUSINESS',
    URL: '/api/merchant/shopify/reinstall-shopify-app-new-business',
  },

  PUBLISH_SHOPIFY_CALLBACK: {
    USECASE: 'PUBLISH_SHOPIFY_CALLBACK',
    URL: '/api/merchant/shopify/callback',
  },

  PUBLISH_SHOPIFY_CALLBACK_AUTH: {
    USECASE: 'PUBLISH_SHOPIFY_CALLBACK_AUTH',
    URL: '/api/merchant/shopify/auth_callback',
  },

  CREATE_BUSINESS: {
    USECASE: 'CREATE_BUSINESS',
    URL: '/api/merchant/business',
  },
  UPDATE_BUSINESS: {
    USECASE: 'UPDATE_BUSINESS',
    URL: '/api/merchant/business/:id',
  },
  POS_NOT_CONNECTED: {
    USECASE: 'POS_NOT_CONNECTED',
    URL: '/api/merchant/events/pos-not-connected',
  },

  UPLOAD_STORE_LOGO: {
    USECASE: 'UPLOAD_STORE_LOGO',
    URL: '/api/merchant/store/:id/logo',
  },
  UPLOAD_BUSINESS_LOGO: {
    USECASE: 'UPLOAD_BUSINESS_LOGO',
    URL: '/api/merchant/business/:id/logo',
  },

  UPDATE_PAYMENT_METHOD: {
    USECASE: 'UPDATE_PAYMENT_METHOD',
    URL: '/api/merchant/user/payment-method',
  },
  GET_PAYMENT_METHOD: {
    USECASE: 'GET_PAYMENT_METHOD',
    URL: '/api/merchant/user/payment-methods',
  },
  GET_ALL_SUBSCRIPTIONS: {
    USECASE: 'GET_ALL_SUBSCRIPTIONS',
    URL: '/api/merchant/membership/active',
  },

  // Statistics
  GET_ALL_RECEIPTS_COUNT: {
    USECASE: 'GET_ALL_RECEIPTS_COUNT',
    URL: '/api/merchant/user/receipt/-/count',
  },

  // Store Transactions
  GET_TRANSACTIONS_FOR_STORE: {
    USECASE: 'GET_TRANSACTIONS_FOR_STORE',
    URL: '/api/merchant/store/:id/transaction',
  },

  // Store Monthly Usage
  GET_STORE_MONTHLY_USAGE: {
    USECASE: 'GET_STORE_MONTHLY_USAGE',
    URL: '/api/merchant/store/:id/allowance/-/count',
  },

  //Receipt preference
  CREATE_RECEIPT_PREFERENCE: {
    USECASE: 'CREATE_RECEIPT_PREFERENCE',
    URL: '/api/merchant/receipt-preference',
  },
  GET_RECEIPT_PREFERENCE_BUSINESS: {
    USECASE: 'GET_RECEIPT_PREFERENCE_BUSINESS',
    URL: '/api/merchant/receipt-preference/:merchant_business_id',
  },
  GET_RECEIPT_PREFERENCE_STORE: {
    USECASE: 'GET_RECEIPT_PREFERENCE_STORE',
    URL: '/api/merchant/receipt-preference/store/:merchant_store_id',
  },
  UPDATE_RECEIPT_PREFERENCE: {
    USECASE: 'UPDATE_RECEIPT_PREFERENCE',
    URL: '/api/merchant/receipt-preference/',
  },
  UPDATE_RECEIPT_PREFERENCE_ALL_STORES: {
    USECASE: 'UPDATE_RECEIPT_PREFERENCE_ALL_STORES',
    URL: '/api/merchant/receipt-preference/all-stores',
  },

  // membership
  GET_ACTIVE_MEMBERSHIP_TYPES: {
    USECASE: 'GET_ACTIVE_MEMBERSHIP_TYPES',
    URL: '/api/merchant/membership/active-membership',
  },

  CREATE_SHOPIFY_MEMBERSHIP: {
    USECASE: 'CREATE_SHOPIFY_MEMBERSHIP',
    URL: '/api/merchant/membership/create-shopify',
  },

  ACTIVATE_SHOPIFY_MEMBERSHIP: {
    USECASE: 'ACTIVATE_SHOPIFY_MEMBERSHIP',
    URL: '/api/merchant/membership/activate-shopify',
  },

  GET_ACTIVE_MEMBERSHIP_FOR_USER: {
    USECASE: 'GET_ACTIVE_MEMBERSHIP_FOR_USER',
    URL: '/api/merchant/membership/active',
  },

  CANCEL_MEMBERSHIP: {
    USECASE: 'CANCEL_MEMBERSHIP',
    URL: '/api/merchant/membership/unsubscribe-shopify',
  },

  CREATE_STRIPE_CHECKOUT: {
    USECASE: 'CREATE_STRIPE_CHECKOUT',
    URL: '/api/merchant/membership/create-stripe-checkout',
  },

  CREATE_STRIPE_PAID_MEMBERSHIP: {
    USECASE: 'CREATE_STRIPE_PAID_MEMBERSHIP',
    URL: '/api/merchant/membership/create-stripe-membership',
  },

  CANCEL_STRIPE_MEMBERSHIP: {
    USECASE: 'CANCEL_STRIPE_MEMBERSHIP',
    URL: '/api/merchant/membership/unsubscribe-stripe',
  },
};

export default UrlConstants;
