import ApiManager from '../ApiManager';
import HttpConstants from '../../constants/HttpConstants';
import UrlConstants from '../../constants/UrlConstants';

const getAllStores = () => {
  return ApiManager.makeApiCallWithAuthentication(UrlConstants.GET_STORES.USECASE, HttpConstants.GET_METHOD);
};

const getStoreDetail = (storeId) => {
  return ApiManager.makeApiCallWithAuthentication(UrlConstants.GET_STORE_DETAIL.USECASE, HttpConstants.GET_METHOD, {
    ':id': storeId,
  });
};

const createStore = (data) => {
  return ApiManager.makeApiCallWithAuthentication(UrlConstants.CREATE_STORE.USECASE, HttpConstants.POST_METHOD, data);
};

const updateStore = (storeId, data) => {
  return ApiManager.makeApiCallWithAuthentication(UrlConstants.UPDATE_STORE.USECASE, HttpConstants.PATCH_METHOD, {
    ':id': storeId,
    ...data,
  });
};

const deactivateStore = (storeId) => {
  return ApiManager.makeApiCallWithAuthentication(UrlConstants.DEACTIVATE_STORE.USECASE, HttpConstants.DELETE_METHOD, {
    ':id': storeId,
  });
};

const getAllCategories = () => {
  return ApiManager.makeApiCallWithAuthentication(UrlConstants.GET_ALL_CATEGORIES.USECASE, HttpConstants.GET_METHOD);
};

const getTransactions = (storeId, startDate, endDate) => {
  return ApiManager.makeApiCallWithAuthentication(
    UrlConstants.GET_TRANSACTIONS_FOR_STORE.USECASE,
    HttpConstants.GET_METHOD,
    { ':id': storeId, start_date: startDate, end_date: endDate },
  );
};

const getMonthlyUsageStats = (storeId) => {
  return ApiManager.makeApiCallWithAuthentication(
    UrlConstants.GET_STORE_MONTHLY_USAGE.USECASE,
    HttpConstants.GET_METHOD,
    { ':id': storeId },
  );
};

const StoreService = {
  createStore,
  updateStore,
  deactivateStore,
  getAllStores,
  getStoreDetail,
  getAllCategories,
  getTransactions,
  getMonthlyUsageStats,
};
export default StoreService;
