import { Button, Col, Row } from 'antd';

import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';

const ErrorComponent = () => {
  const history = useHistory();

  return (
    <Row>
      <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={require('../assets/images/errormessage-nobutton@2x.png').default} alt='404 Not Found' />
      </Col>
      <Col flex='auto' className='text-center button-wrap wrap-spacer-large '>
        <Button type='submit' className='success-submit ' onClick={() => history.goBack()}>
          Go Back
        </Button>
      </Col>
    </Row>
  );
};

export default withRouter(ErrorComponent);
