import React, { Component } from 'react';

import BrandingImages from '../../components/common/branding/BrandingImages';
import IntroLayout from './common/IntroLayout';
import SectionHeader from '../../components/common/SectionHeader';
import SignupForm from './signup/SignupForm';
import UnsecureComponent from '../../components/UnsecureComponent';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'antd';
import logoBeOne from '../../assets/images/logoBeOne.png';
import TrackingUtils from '../../utils/TrackingUtils';

// Handle Square Onboarding Logic
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticatedUser: false,
      isPreRegisteredUser: false,
      innerWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props?.location?.search);
    const campaignLastSource = searchParams.get('utm_source');
    const campaignLastMedium = searchParams.get('utm_medium');
    const campaignLastName = searchParams.get('utm_campaign');
    const campaignLastTerm = searchParams.get('utm_term');
    const campaignLastContent = searchParams.get('utm_content');
    TrackingUtils.identifyUTMParameters(
      campaignLastSource,
      campaignLastMedium,
      campaignLastName,
      campaignLastTerm,
      campaignLastContent,
    );
    TrackingUtils.eventMerchantOnboardingStep1YourDetails();
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ innerWidth: window.innerWidth });
  }

  render() {
    return (
      <IntroLayout>
        <SectionHeader
          title='Let’s get your account set up.'
          subTitle='Sign up to e.pop and start benefiting from these today:'
        />
        <BrandingImages />
        {this.state.innerWidth < 500 ? (
          <Row justify='space-around' align='middle'>
            <Col span='24'>
              <div className='imgAlignCenter mt-3'>
                <img src={logoBeOne} alt='Logo BeOne' />
              </div>
              <div
                className={`font-muli-Regular text-light-dark text-left mt-1`}
                dangerouslySetInnerHTML={{
                  __html:
                    '<p>If you already have a <span class="font-MuliBold">merchant account</span> at <span class="font-MuliBold">BeOne</span>, our community site, then you can <a href="../">sign in</a> with the same email and password.</p>',
                }}
              ></div>
            </Col>
          </Row>
        ) : (
          <Row justify='space-around' align='middle'>
            <Col span='16'>
              <div
                className={`font-muli-Regular text-light-dark text-left mt-3`}
                dangerouslySetInnerHTML={{
                  __html:
                    '<p>If you already have a <span class="font-MuliBold">merchant account</span> at <span class="font-MuliBold">BeOne</span>, our community site, then you can <a href="../">sign in</a> with the same email and password.</p>',
                }}
              ></div>
            </Col>
            <Col span='8'>
              <div className='img mt-3'>
                <img src={logoBeOne} alt='Logo BeOne' />
              </div>
            </Col>
          </Row>
        )}
        <SignupForm
          isPreRegisteredUser={this.state.isPreRegisteredUser}
          isAuthenticatedUser={this.state.isAuthenticatedUser}
        />
      </IntroLayout>
    );
  }
}

export default UnsecureComponent(withRouter(Signup));
