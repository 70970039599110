import PropTypes from 'prop-types';
import React from 'react';

const StoreCircleInfo = (props) => {
  return (
    <div className='store-epop-header ml-auto mr-0'>
      <p>{props.store.name}</p>
      {props.store.address && props.store.address.city && <p>{props.store.address?.city}</p>}
      {props.store.address && props.store.address.province && <p>{props.store.address?.province}</p>}
    </div>
  );
  // else
  //   return (
  //     <div className='store-epop-header ml-auto mr-0'>
  //       <img
  //         src={props.store.logo_url}
  //         alt={props.store.name}
  //         className='store-logo'
  //       />
  //     </div>
  //   );
};

StoreCircleInfo.propTypes = {
  store: PropTypes.object.isRequired,
};

export default StoreCircleInfo;
