import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
  createShopifyMembership,
  getAllActiveMemberships,
  createStripeCheckoutOrFreeMembership,
  getActiveMembershipForUser,
} from '../../../api/service/MembershipService';
import { ArrowRight } from '../../../assets/images/svg';
import SectionHeader from '../../../components/common/SectionHeader';
import SecureComponent from '../../../components/SecureComponent';
import moment from 'moment';
import { useHistory } from 'react-router';
import styles from './merchant-subscription.module.scss';
import { inject, observer } from 'mobx-react';
import TrackingUtils from '../../../utils/TrackingUtils';

const cardData = {
  BASE: ['Issue digital receipts', 'Listing on e.pop merchant directory', 'Access to BeOne Shopping Community'],
  GROWTH: [
    'Issue digital receipts',
    'Listing on e.pop merchant directory',
    'Access to BeOne Shopping Community',
    'Send offers to shoppers',
    'Shopper analytics and insights (Coming Soon!)',
    'Priority email support',
    '30-day free trial',
  ],
};

const Features = ({ type }) => {
  return cardData[type].map((item) => <p key={item}>{item}</p>);
};

const MerchantSubscription = inject('dataStore')(
  observer(({ dataStore }) => {
    const [state, setState] = useState([]);
    const [modal, setModal] = useState(false);
    const [user, setUser] = useState({});
    const [membership, setMembership] = useState({});
    const [membershipId, setMembershipId] = useState(null);

    const history = useHistory();
    const modalCard = useRef(null);

    const isActive = (tier) => tier === user?.business?.default_membership_plan;

    const handleCloseModal = (e) => {
      if (!modalCard.current.contains(e.target)) {
        setModal(false);
      }
    };

    const handleCreateMembership = async () => {
      if (user?.integrated_source === 'SHOPIFY') {
        const response = await createShopifyMembership({
          user_id: user?.id,
          membership_id: membershipId,
          epop_id: user?.epopId,
        });
        setModal(false);
        window.location = response.redirect_url;
      } else {
        const response = await createStripeCheckoutOrFreeMembership({
          user_id: user?.id,
          membership_id: membershipId,
          stripe_customer_id: user?.stripe_customer_id,
          epop_id: user?.epopId,
        });
        setModal(false);
        window.location = response.redirect_url;
      }
    };

    const ctaContent = (userPlan, tier) => {
      if (!userPlan) {
        return (
          <>
            <span>Get Started</span>
            <ArrowRight />
          </>
        );
      } else if (tier === user?.business?.default_membership_plan) {
        return 'Your current plan';
      } else if (userPlan !== 'BASE') {
        return 'Downgrade my plan';
      } else if (userPlan !== 'GROWTH') {
        return 'Upgrade my plan';
      }
    };

    const subtitleContent = (userPlan) => {
      if (!userPlan) {
        return 'Select the plan that suits you best. We understand that requirements evolve with your business. If you need to, simply change your plan at any time.';
      } else if (userPlan === 'GROWTH') {
        return 'You are currently on our Growth plan';
      } else if (userPlan === 'BASE') {
        return 'You are currently a Base Member, which is our free membership tier entitling you to some cool but limited perks';
      }
    };

    const isInCancellation = (cancellsOn, status) =>
      cancellsOn && new Date(cancellsOn) >= new Date() && status === 'cancelled';

    const handleDisable = (tier) => {
      if (
        membership?.name?.toUpperCase() === tier &&
        (membership?.status === 'active' || membership?.status === 'cancelled')
      ) {
        return false;
      }
      if (membership?.name?.toUpperCase() !== tier && isInCancellation(membership?.cancel_on, membership?.status)) {
        return false;
      }
      return true;
    };

    useEffect(() => {
      if (user?.business?.default_membership_plan === 'FFL') {
        history.push('/merchant/dashboard');
      }
      const fetchData = async () => {
        const userData = await dataStore?.user?.getDetails();
        setUser(userData);
        const response = await getAllActiveMemberships(userData?.integrated_source || 'SQUARE');
        const activeMembership = await getActiveMembershipForUser(userData?.id);
        if (response.length > 0) {
          setState(response);
        }
        if (activeMembership) {
          setMembership(activeMembership);
        }
      };
      fetchData();
      TrackingUtils.eventMembershipOptions();
    }, [user?.business?.default_membership_plan]);

    return (
      <>
        <SectionHeader
          heading='My Membership Plan'
          subTitle={subtitleContent(user?.business?.default_membership_plan)}
        />
        <div className={styles.container}>
          {state
            .sort((a, b) => a.price - b.price)
            .map((item) => (
              <div className={`${styles.card} ${isActive(item?.tier) && styles.isActive}`} key={item.id}>
                <div className={styles.dataContent}>
                  <h3>{item.name}</h3>
                  <div>
                    {item?.tier === 'BASE' ? (
                      <p className={styles.price}>FREE</p>
                    ) : (
                      <p className={styles.price}>${item?.price?.toFixed(2)} / mo</p>
                    )}
                    <Features type={item.tier} />
                    {isInCancellation(membership?.cancel_on, membership?.status) && item?.tier === 'GROWTH' && (
                      <p className={styles.cancelled}>
                        Membership ends on {moment(membership?.cancel_on).format('YYYY-MM-DD')}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (handleDisable(item?.tier)) {
                      setMembershipId(item?.id);
                      setModal(true);
                    }
                  }}
                  className={`${
                    isActive(item?.tier)
                      ? styles.disabled
                      : !isActive(item?.tier) && isInCancellation(membership?.cancel_on, membership?.status)
                      ? styles.inCancellation
                      : styles.callToAction
                  }`}
                >
                  {ctaContent(user?.business?.default_membership_plan, item?.tier)}
                </div>
              </div>
            ))}
        </div>
        {modal ? (
          <div className={styles.subscriptionModal} onClick={(e) => handleCloseModal(e)}>
            <div ref={modalCard} className={styles.subscriptionModalCard}>
              <SectionHeader title='Confirmation' subTitle="Please confirm you'd like to activate the subscription" />
              <div className={styles.subscriptionButtonContainer}>
                <button
                  className={styles.confirm}
                  onClick={async () => {
                    await handleCreateMembership();
                  }}
                >
                  Confirm
                </button>
                <button
                  className={styles.cancel}
                  onClick={() => {
                    setMembershipId(null);
                    setModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }),
);

export default SecureComponent(withRouter(MerchantSubscription));
