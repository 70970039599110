import { Button, Checkbox, Col, Input, Row, Typography, Upload, message } from 'antd';
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';
import ImgCrop from 'antd-img-crop';
import Loading from '../../components/Loading';
import MerchantService from '../../api/service/MerchantService';
import MiscUtil from '../../utils/MiscUtils';
import ReceiptComponent from '../../components/receipt/ReceiptComponent';
import ReceiptPreferenceService from '../../api/service/ReceiptPreferenceService';
import RouteConstants from '../../constants/RouteConstants';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import { isEmpty } from 'lodash';
import uploadImage from '../../assets/images/Top@2x.png';
import { withRouter } from 'react-router-dom';
import TrackingUtils from '../../utils/TrackingUtils';

const { Title } = Typography;
const { TextArea } = Input;

@inject('dataStore')
@observer
class MerchantReceiptSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      logoUrl: null,
      fileList: [],
      categories: [],
      show_business_name: false,
      show_address_line1: false,
      show_city: false,
      show_state: false,
      show_phone_number: false,
      show_website: false,
      show_instagram: false,
      show_facebook: false,
      categoryId: null,
      show_category: false,
      show_default_store: false,
      contactDetails: [],
      returnPolicyData: [],
      show_return_policy: false,
      show_barcode: false,
      show_logo: false,
      receiptPreferenceAllStore: false,
      user: {},
      receiptPreference: {},
      return_policy: '',
      logoUrlType: false,
      submitLoading: false,
      show_marketing_message: false,
      marketing_message: '',
      return_policy_message: '',
      contact_details: {},
      barcodeUUID: '',
    };
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ logoUrl: reader.result });
    };
    reader.onerror = () => this.setState({ fileList: [], logoUrl: null });
  }

  generateUUIDv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  setReceiptPreferenceFlags(response, user) {
    this.setState(
      {
        contact_details: user.business,
        show_business_name: response.show_business_name,
        show_barcode: response.show_barcode,
        barcodeUUID: this.generateUUIDv4(),
        show_facebook: response.show_facebook,
        show_instagram: response.show_instagram,
        show_address_line1: response.show_address_line1,
        show_category: response.show_category,
        show_city: response.show_city,
        show_state: response.show_state,
        show_logo: response.show_logo,
        show_phone_number: response.show_phone_number,
        show_website: response.show_website,
        show_return_policy: response.show_return_policy,
        receiptPreference: response,
        return_policy: response.return_policy,
        show_marketing_message: response.show_marketing_message,
        marketing_message: response.marketing_message,
        return_policy_message: response.return_policy_message,
        categories: user.business?.category ? MiscUtil.getValuesForCategoryDropdown([user.business?.category]) : [],
        user,
        logoUrl: user.business?.logo_url ? user.business?.logo_url : user.business?.category?.icon_bw_url,
        logoUrlType: user.business.logo_url ? true : false,
        categoryId: user.business?.category?.id ? user.business?.category?.id : '',
        returnPolicyData: [
          { key: 'No Return', value: 'No Return', text: 'No Return' },
          { key: '7days', value: '7 Days', text: '7 Days' },
          { key: '30days', value: '30 Days', text: '30 days' },
          { key: '90days', value: '90 Days', text: '90 days' },
          { key: '180days', value: '180 Days', text: '180 days' },
          { key: 'custom', value: null, text: 'custom' },
        ],
      },
      () => {
        this.setState({ loading: false });
      },
    );
  }

  async componentDidMount() {
    TrackingUtils.eventMerchantPagesReceiptPreferences();
    this.setState({ loading: true });
    const user = await this.props.dataStore.user.getDetails();

    ReceiptPreferenceService.getReceiptPreferenceForBusiness(user.business.id)
      .then((response) => {
        this.setReceiptPreferenceFlags(response, user);
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  onSubmit = async () => {
    const { fileList } = this.state;
    this.setState({ submitLoading: true });
    const payload = {
      record: {
        show_logo: this.state.show_logo,
        show_category: this.state.show_category,
        show_business_name: this.state.show_business_name,
        show_address_line1: this.state.show_address_line1,
        show_city: this.state.show_city,
        show_state: this.state.show_state,
        show_phone_number: this.state.show_phone_number,
        show_website: this.state.show_website,
        show_instagram: this.state.show_instagram,
        show_facebook: this.state.show_facebook,
        return_policy: this.state.return_policy,
        show_return_policy: this.state.show_return_policy,
        show_marketing_message: this.state.show_marketing_message,
        show_barcode: this.state.show_barcode,
        marketing_message: this.state.marketing_message,
        return_policy_message: this.state.return_policy_message,
      },
    };
    if (!isEmpty(fileList)) {
      try {
        const formData = new FormData();
        formData.append(':id', this.state.user.business.id);
        formData.append('file', fileList[0]);
        await MerchantService.uploadBusinessLogo(formData);
        const user = await this.props.dataStore.user.getDetails(true);
        this.setState({
          user,
          logoUrl: user.business.logo_url ? user.business.logo_url : user.business.category.icon_bw_url,
          logoUrlType: user.business.logo_url ? true : false,
        });
        this.setState({ submitLoading: false });
      } catch (err) {
        this.setState({ submitLoading: false });
        message.error("Couldn't upload logo at the moment, try again later");
      }
    }

    if (this.state.receiptPreferenceAllStore) {
      payload['merchant_business_id'] = this.state.user.business.id;

      ReceiptPreferenceService.updateReceiptPreferenceToAllStores(payload)
        .then(() => {
          message.success('Receipt preferences updated for all Stores Successfully');
          this.setState({
            receiptPreferenceAllStore: false,
            submitLoading: false,
          });
          this.props.history.push(RouteConstants.MERCHANT.DASHBOARD);
        })
        .catch(() => {
          this.setState({ submitLoading: false });
          message.error("Couldn't update receipt preferences for all stores right now, try again later");
        });
    } else {
      payload['id'] = this.state.receiptPreference.id;

      ReceiptPreferenceService.updateReceiptPreference(payload)
        .then(() => {
          message.success('Receipt preferences updated successfully');
          this.props.history.push(RouteConstants.MERCHANT.DASHBOARD);
        })
        .catch(() => {
          message.error("Couldn't update receipt preferences at the moment, try again later");
        });
    }
  };

  render() {
    const {
      logoUrl,
      fileList,
      categories,
      loading,
      show_business_name,
      show_address_line1,
      show_city,
      show_state,
      show_phone_number,
      show_website,
      show_instagram,
      show_facebook,
      contactDetails,
      returnPolicyData,
      show_barcode,
      categoryId,
      return_policy,
      show_return_policy,
      receiptPreferenceAllStore,
      user,
      logoUrlType,
      submitLoading,
      show_category,
      show_logo,
      show_marketing_message,
      marketing_message,
      return_policy_message,
      contact_details,
      barcodeUUID,
    } = this.state;
    const props = {
      accept: 'image/png, image/jpeg',
      onRemove: () => {
        this.setState({
          fileList: [],
          logoUrl: user.business.category.icon_bw_url,
          logoUrlType: false,
        });
      },
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
          return;
        }
        this.setState({
          fileList: [file],
          logoUrlType: true,
        });
        this.getBase64(file);
        return false;
      },
      fileList,
    };
    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row>
              <Col sm={24} md={24} lg={9}>
                <ReceiptComponent
                  categoryId={categoryId}
                  categories={categories}
                  contactDetails={contactDetails}
                  show_barcode={show_barcode}
                  barcodeUUID={barcodeUUID}
                  show_return_policy={show_return_policy}
                  show_address_line1={show_address_line1}
                  show_business_name={show_business_name}
                  show_city={show_city}
                  show_state={show_state}
                  show_phone_number={show_phone_number}
                  show_website={show_website}
                  show_instagram={show_instagram}
                  show_facebook={show_facebook}
                  return_policy={return_policy}
                  receiptPreferenceAllStore={receiptPreferenceAllStore}
                  logoUrl={logoUrl}
                  logoUrlType={logoUrlType}
                  show_category={show_category}
                  show_logo={show_logo}
                  show_marketing_message={show_marketing_message}
                  marketing_message={marketing_message}
                  return_policy_message={return_policy_message}
                  contact_details={contact_details}
                />
                <div style={{ width: '93%' }} className={'custom-checkbox custom-control-label mt-4'}>
                  <Checkbox
                    disabled={this.state.show_default_store}
                    checked={receiptPreferenceAllStore}
                    onChange={() => {
                      this.setState({
                        receiptPreferenceAllStore: !this.state.receiptPreferenceAllStore,
                      });
                    }}
                  >
                    <span className='receipt-form-contentsBold receipt-checkbox-text'>
                      Default to same receipt info for all stores
                    </span>
                  </Checkbox>
                </div>
              </Col>
              <Col sm={24} md={24} lg={14}>
                <SectionHeader heading='My receipt preferences' />
                <div className='font-muli-Regular text-light-dark text-left'>
                  <p className='receipt-form-contents'>
                    To display your custom branding please upload your logo (ideally in a square format). Upload images
                    less than 10MB in size and in png or jpeg format
                  </p>
                  <div>
                    <Title className='privacy_heading text-light-dark mt-3 mb-2' level={5}>
                      Logo
                    </Title>
                    <Col span='12'>
                      {logoUrlType ? (
                        <div
                          style={{
                            width: 70,
                            height: 70,
                            marginLeft: 0,
                            marginBottom: '2rem',
                          }}
                          className='image-upload-container mt-1'
                        >
                          {logoUrl != null && (
                            <img
                              src={logoUrl}
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                              className='image-preview-container'
                              alt='uploaded'
                            />
                          )}
                          <ImgCrop rotate quality={0.8} modalTitle={'Crop and select business logo'} modalOk='Select'>
                            <Upload {...props} maxCount={1} onPreview={this.onPreview}>
                              <img
                                style={{ right: -30, bottom: -13, height: 34 }}
                                src={uploadImage}
                                alt='Click to Upload'
                                className='logo-upload'
                              />
                            </Upload>
                          </ImgCrop>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: 70,
                            height: 70,
                            marginLeft: 0,
                            marginBottom: '2rem',
                            padding: 10,
                          }}
                          className='image-upload-container receipt-logo mt-1'
                        >
                          {logoUrl != null && (
                            <img
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                              src={logoUrl}
                              className='image-preview-container receipt-logo'
                              alt='uploaded'
                            />
                          )}
                          <ImgCrop rotate quality={0.8} modalTitle={'Crop and select business logo'} modalOk='Select'>
                            <Upload {...props} maxCount={1} onPreview={this.onPreview}>
                              <img
                                style={{ right: -30, bottom: -13, height: 34 }}
                                src={uploadImage}
                                alt='Click to Upload'
                                className='logo-upload'
                              />
                            </Upload>
                          </ImgCrop>
                        </div>
                      )}
                    </Col>
                  </div>
                  <div>
                    <Title className='privacy_heading text-light-dark mt-3 mb-2' level={5}>
                      Business Category
                    </Title>
                    <p className='receipt-form-contents'>
                      What type of business do you run? This allows us to automatically categorize the receipts on your
                      customer e.pop app.
                    </p>
                    <Dropdown
                      className='image-drop-down receipt-dropdown receipt-drop-down-border'
                      placeholder='Category of your business'
                      fluid
                      search
                      selection
                      options={categories}
                      disabled={true}
                      value={categoryId}
                      onChange={(_, data) => {
                        this.setState({ categoryId: data.value });
                      }}
                    />
                  </div>
                  <div>
                    <Title className='privacy_heading text-light-dark mt-3 mb-2' level={5}>
                      Contact Details
                    </Title>
                    <p className='receipt-form-contents'>
                      Choose below what contact information you would like on your receipts?
                    </p>
                    <Col span={24}>
                      <div
                        onClick={() => {
                          this.setState({
                            show_business_name: !this.state.show_business_name,
                          });
                          show_business_name
                            ? contactDetails.splice(contactDetails.indexOf('Business'), 1)
                            : contactDetails.push('Business');
                        }}
                        className={
                          show_business_name
                            ? 'customList-checkbox active receipt-form-detail receipt-contact-detail'
                            : 'customList-checkbox receipt-form-detail receipt-contact-detail'
                        }
                      >
                        <div>Business Name</div>
                        <div className='receipt-contact-detail-check'>
                          {show_business_name ? (
                            <FaCheckCircle className='checked' />
                          ) : (
                            <FaRegCheckCircle className='unchecked' />
                          )}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          this.setState({
                            show_address_line1: !this.state.show_address_line1,
                          });
                          show_address_line1
                            ? contactDetails.splice(contactDetails.indexOf('show_address_line1'), 1)
                            : contactDetails.push('show_address_line1');
                        }}
                        className={
                          show_address_line1
                            ? 'customList-checkbox active receipt-form-detail receipt-contact-detail'
                            : 'customList-checkbox receipt-form-detail receipt-contact-detail'
                        }
                      >
                        <div>Address Line 1</div>
                        <div className='receipt-contact-detail-check'>
                          {show_address_line1 ? (
                            <FaCheckCircle className='checked' />
                          ) : (
                            <FaRegCheckCircle className='unchecked' />
                          )}
                        </div>
                      </div>
                      <Row>
                        <Col span={12}>
                          <div
                            onClick={() => {
                              this.setState({
                                show_city: !this.state.show_city,
                              });
                              show_city
                                ? contactDetails.splice(contactDetails.indexOf('show_city'), 1)
                                : contactDetails.push('show_city');
                            }}
                            className={
                              show_city
                                ? 'customList-checkbox active receipt-form-detail receipt-contact-detail'
                                : 'customList-checkbox receipt-form-detail receipt-contact-detail'
                            }
                            style={{ marginRight: 4 }}
                          >
                            <div className='receipt-form-detail'>City</div>
                            <div className='receipt-contact-detail-check'>
                              {show_city ? (
                                <FaCheckCircle className='checked' />
                              ) : (
                                <FaRegCheckCircle className='unchecked' />
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div
                            onClick={() => {
                              this.setState({
                                show_state: !this.state.show_state,
                              });
                              show_state
                                ? contactDetails.splice(contactDetails.indexOf('show_state'), 1)
                                : contactDetails.push('show_state');
                            }}
                            className={
                              show_state
                                ? 'customList-checkbox active receipt-form-detail receipt-contact-detail'
                                : 'customList-checkbox receipt-form-detail receipt-contact-detail'
                            }
                          >
                            <div>State</div>
                            <div className='receipt-contact-detail-check'>
                              {show_state ? (
                                <FaCheckCircle className='checked' />
                              ) : (
                                <FaRegCheckCircle className='unchecked' />
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div
                            onClick={() => {
                              this.setState({
                                show_phone_number: !this.state.show_phone_number,
                              });
                              show_phone_number
                                ? contactDetails.splice(contactDetails.indexOf('show_phone_number'), 1)
                                : contactDetails.push('contactDetails.show_phone_number');
                            }}
                            className={
                              show_phone_number
                                ? 'customList-checkbox active receipt-form-detail receipt-contact-detail'
                                : 'customList-checkbox receipt-form-detail receipt-contact-detail'
                            }
                            style={{ marginRight: 4 }}
                          >
                            <div>Phone Number</div>
                            <div className='receipt-contact-detail-check'>
                              {show_phone_number ? (
                                <FaCheckCircle className='checked' />
                              ) : (
                                <FaRegCheckCircle className='unchecked' />
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div
                            onClick={() => {
                              this.setState({
                                show_website: !this.state.show_website,
                              });
                              show_website
                                ? contactDetails.splice(contactDetails.indexOf('show_website'), 1)
                                : contactDetails.push('show_website');
                            }}
                            className={
                              show_website
                                ? 'customList-checkbox active receipt-form-detail receipt-contact-detail'
                                : 'customList-checkbox receipt-form-detail receipt-contact-detail'
                            }
                          >
                            <div className='receipt-form-detail'>Website</div>
                            <div className='receipt-contact-detail-check'>
                              {show_website ? (
                                <FaCheckCircle className='checked' />
                              ) : (
                                <FaRegCheckCircle className='unchecked' />
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div
                            onClick={() => {
                              this.setState({
                                show_instagram: !this.state.show_instagram,
                              });
                              show_instagram
                                ? contactDetails.splice(contactDetails.indexOf('show_instagram'), 1)
                                : contactDetails.push('show_instagram');
                            }}
                            className={
                              show_instagram
                                ? 'customList-checkbox active receipt-form-detail receipt-contact-detail'
                                : 'customList-checkbox receipt-form-detail receipt-contact-detail'
                            }
                            style={{ marginRight: 4 }}
                          >
                            <div>Instagram</div>
                            <div className='receipt-contact-detail-check'>
                              {show_instagram ? (
                                <FaCheckCircle className='checked' />
                              ) : (
                                <FaRegCheckCircle className='unchecked' />
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div
                            onClick={() => {
                              this.setState({
                                show_facebook: !this.state.show_facebook,
                              });
                              show_facebook
                                ? contactDetails.splice(contactDetails.indexOf('show_facebook'), 1)
                                : contactDetails.push('show_facebook');
                            }}
                            className={
                              show_facebook
                                ? 'customList-checkbox active receipt-form-detail receipt-contact-detail'
                                : 'customList-checkbox receipt-form-detail receipt-contact-detail'
                            }
                          >
                            <div>Facebook</div>
                            <div className='receipt-contact-detail-check'>
                              {show_facebook ? (
                                <FaCheckCircle className='checked' />
                              ) : (
                                <FaRegCheckCircle className='unchecked' />
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                  <div>
                    <Title className='privacy_heading text-light-dark mt-3 mb-2' level={5}>
                      Return Policy
                    </Title>
                    <p className='receipt-form-contents'>
                      Let us know your return policy to allow us to send a reminder to your customer letting them know
                      when they are close to their return window.
                    </p>
                    <Dropdown
                      className='image-drop-down receipt-drop-down-border receipt-content-font-size'
                      placeholder='Return Policy'
                      fluid
                      search
                      selection
                      options={returnPolicyData}
                      value={return_policy}
                      disabled={loading}
                      onChange={(e, data) => {
                        if (data.value === null) {
                          this.setState({
                            return_policy: null,
                            show_return_policy: true,
                          });
                        } else {
                          this.setState({
                            return_policy: data.value,
                            show_return_policy: true,
                          });
                        }
                      }}
                    />
                    <div className='receipt-form-detail' style={{ color: 'grey', marginTop: 15 }}>
                      <TextArea
                        className='optional-message'
                        placeholder='Return Policy Message (Optional)'
                        autoSize={{ minRows: 2, maxRows: 5 }}
                        value={return_policy_message}
                        onChange={(e) => {
                          this.setState({
                            return_policy_message: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Title className='privacy_heading text-light-dark mt-3 mb-2' level={5}>
                    Marketing Message (Optional)
                  </Title>
                  <div className={'custom-checkbox terms-conditions w-100 custom-control-label'}>
                    <TextArea
                      className='optional-message'
                      placeholder='Marketing message (Optional)'
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      value={marketing_message}
                      onChange={(e) => {
                        if (e.target.value === '') {
                          this.setState({
                            show_marketing_message: false,
                            marketing_message: '',
                          });
                        } else {
                          if (!show_marketing_message) this.setState({ show_marketing_message: true });
                          this.setState({ marketing_message: e.target.value });
                        }
                      }}
                    />
                  </div>
                </div>

                <div>
                  <Title className='privacy_heading text-light-dark mt-3 mb-2' level={5}>
                    Barcode
                  </Title>
                  <div className={'custom-checkbox terms-conditions w-100 custom-control-label'}>
                    <Checkbox
                      className='receiptCheckbox'
                      checked={show_barcode}
                      onChange={() => {
                        this.setState({
                          show_barcode: !this.state.show_barcode,
                        });
                      }}
                    >
                      <p className='receipt-form-contents receipt-checkbox-text' style={{ marginLeft: '-9px' }}>
                        Display a return barcode on the receipt?
                      </p>
                    </Checkbox>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className='button-wrap wrap-spacer-large d-flex align-items-center'>
              <Col flex='auto'>
                <Button
                  className='primary-green-btn mx-auto ml-lg-auto'
                  onClick={() => this.props.history.push(RouteConstants.MERCHANT.DASHBOARD)}
                >
                  Discard
                </Button>
              </Col>
              <Col flex='auto' className='text-right'>
                <Button type='submit' className='success-submit ' loading={submitLoading} onClick={this.onSubmit}>
                  Save &amp; Exit
                </Button>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default SecureComponent(withRouter(MerchantReceiptSettings));
