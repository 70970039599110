import { Col, Row, Typography } from 'antd';

import PropTypes from 'prop-types';
import React from 'react';

const SectionHeader = (props) => {
  return (
    <Row>
      <Col span='24'>
        <div className='right-header'>
          {props.heading && (
            <Typography.Title
              className={`main-heading ${props.headingAlignCenter ? 'text-center' : 'text-left'}`}
              level={1}
            >
              {props.heading}
            </Typography.Title>
          )}

          {props.title && (
            <Typography.Title
              className={`text-light-dark ${props.alignCenter ? 'text-center' : 'text-left'}`}
              level={2}
            >
              {props.title}
            </Typography.Title>
          )}

          {props.subTitle && (
            <div
              className={`font-muli-Regular text-light-dark ${props.alignCenter ? 'text-center' : 'text-left'}`}
              dangerouslySetInnerHTML={{
                __html: '<p>' + props.subTitle + '</p>',
              }}
            ></div>
          )}
        </div>
      </Col>
    </Row>
  );
};

SectionHeader.propTypes = {
  alignCenter: PropTypes.bool,
  headingAlignCenter: PropTypes.bool,
  heading: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

SectionHeader.defaultProps = {
  alignCenter: false,
  headingAlignCenter: false,
};

export default SectionHeader;
