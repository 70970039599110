import { Button, Col, Row } from 'antd';

import { MdArrowBack } from 'react-icons/md';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

const OnboardingFooterBtn = (props) => {
  if (props.showBack)
    return (
      <Row>
        <Col span={12} className='mt-2'>
          <div className={`button-wrap wrap-spacer-large text-left mt-5 pt-3`}>
            <div className='backArrowWrap'>
              <span onClick={() => props.history.goBack()} className='backArrow  m-0'>
                <MdArrowBack />
              </span>
            </div>
          </div>
        </Col>
        <Col span={12} className='mt-2'>
          <div
            className={`button-wrap wrap-spacer-large ${props.alignCenter ? 'text-center' : 'text-right'} mt-5 pt-3`}
          >
            <Button
              htmlType='submit'
              className='success-submit px-lg-5 mx-auto ml-lg-auto'
              disabled={props.disabled}
              loading={props.loading}
              onClick={props.onClick}
            >
              {props.text}
            </Button>
          </div>
        </Col>
      </Row>
    );
  else
    return (
      <Row>
        <Col span={24} className='mt-2'>
          <div
            className={`button-wrap wrap-spacer-large ${props.alignCenter ? 'text-center' : 'text-right'} mt-5 pt-3`}
          >
            <Button
              htmlType='submit'
              className='success-submit px-lg-5 mx-auto ml-lg-auto'
              disabled={props.disabled}
              loading={props.loading}
              onClick={props.onClick}
            >
              {props.text}
            </Button>
          </div>
        </Col>
      </Row>
    );
};

OnboardingFooterBtn.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  text: PropTypes.string,
  alignCenter: PropTypes.bool,
  onClick: PropTypes.func,
};

OnboardingFooterBtn.defaultProps = {
  disabled: false,
  loading: false,
  text: 'Continue',
  alignCenter: false,
};

export default withRouter(OnboardingFooterBtn);
