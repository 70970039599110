import { Route, Switch } from 'react-router-dom';

import ErrorPage from '../ErrorPage';
import OnboardingBusiness from './OnboardingBusiness';
import OnboardingChoosePos from './OnboardingChoosePos';
import OnboardingFeatures from './OnboardingFeatures';
import OnboardingMembership from './OnboardingMembership';
import OnboardingOtherPos from './onboardingChoosePos/OnboardingOtherPos';
import OnboardingOtherPosSuccess from './onboardingChoosePos/OnboardingOtherPosSuccess';
import OnboardingPaymentMethod from './OnboardingPaymentMethod';
import OnboardingShopifyCallback from './onboardingChoosePos/OnboardingShopifyCallback';
import OnboardingSquareCallback from './onboardingChoosePos/OnboardingSquareCallback';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';

const OnboardingRouter = () => {
  return (
    <Switch>
      <Route exact path={RouteConstants.ONBOARDING.BUSINESS_DETAILS} component={OnboardingBusiness} />
      <Route exact path={RouteConstants.ONBOARDING.FEATURES} component={OnboardingFeatures} />
      <Route exact path={RouteConstants.ONBOARDING.CHOOSE_POS} component={OnboardingChoosePos} />
      <Route exact path={RouteConstants.ONBOARDING.SQUARE_CALLBACK} component={OnboardingSquareCallback} />
      {/* MM: shopify_callback isn't being used atm (need to confirm the flow) */}
      <Route exact path={RouteConstants.ONBOARDING.SHOPIFY_CALLBACK} component={OnboardingShopifyCallback} />
      <Route exact path={RouteConstants.ONBOARDING.OTHER_POS} component={OnboardingOtherPos} />
      <Route exact path={RouteConstants.ONBOARDING.OTHER_POS_SUCCESS} component={OnboardingOtherPosSuccess} />
      <Route exact path={RouteConstants.ONBOARDING.EPOP_PLAN} component={OnboardingMembership} />
      <Route exact path={RouteConstants.ONBOARDING.PAYMENT_METHODS} component={OnboardingPaymentMethod} />
      <Route exact path='*'>
        <ErrorPage />
      </Route>
    </Switch>
  );
};

export default OnboardingRouter;
