import React from 'react';
// rightImge list
import dynamicReceipts from '../../../assets/images/iconfinder_E-receipt_4104743.png';
import ecofriendly from '../../../assets/images/Group 1571.png';
import increasedMargins from '../../../assets/images/Group 1926.png';
import offers from '../../../assets/images/Group 34.png';

class BrandingImages extends React.Component {
  render() {
    return (
      <div className='right-img-list'>
        <div>
          <div className='img'>
            <img src={dynamicReceipts} alt='Dynamic Reciepts' />
          </div>
          <p>Dynamic Receipts</p>
        </div>
        <div>
          <div className='img'>
            <img src={offers} alt='Offers' />
          </div>
          <p>Direct Offers to Customers</p>
        </div>
        <div>
          <div className='img'>
            <img src={increasedMargins} alt='Margins' />
          </div>
          <p>Increase Margins</p>
        </div>
        <div>
          <div className='img'>
            <img src={ecofriendly} alt='eco-friendly' />
          </div>
          <p>Eco-Conscious Brand</p>
        </div>
      </div>
    );
  }
}

export default BrandingImages;
