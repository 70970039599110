import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Typography, message } from 'antd';
import { inject, observer } from 'mobx-react';

import OnboardingFooterBtn from '../common/OnboardingFooterBtn';
import PaymentService from '../../../api/service/PaymentService';
import RouteConstants from '../../../constants/RouteConstants';
import StripeInput from '../../../components/stripe/StripeInput';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import ConsoleSafe from '../../../utils/ConsoleSafe';

const PaymentForm = inject('dataStore')(
  observer((props) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [name, setName] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [zipcode, setZipcode] = useState(undefined);

    const stripe = useStripe();
    const elements = useElements();

    const onSubmit = async () => {
      setLoading(true);
      if (!stripe || !elements) {
        // Make  sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const card = elements.getElement(CardNumberElement);
      const result = await stripe.createToken(card);
      if (result.error) {
        setErrorMessage(result.error.message);
        setLoading(false);
        return;
      } else {
        const billing_details = {
          name,
          email,
          address: { postal_code: zipcode },
        };

        stripe
          .createPaymentMethod({
            type: 'card',
            card: card,
            billing_details,
          })
          .then((result) => {
            if (result.error) {
              setErrorMessage(result.error.message);
            }
            PaymentService.updatePaymentMethod(result.paymentMethod)
              .then(async () => {
                message.success('Subscription started successfully');
                await props.dataStore.user.getDetails(true);
                props.history.replace(RouteConstants.MERCHANT.DASHBOARD);
              })
              .catch((err) => {
                ConsoleSafe.log(err);
                var errMgs = 'Subscription starting failed';
                message.error(errMgs);
              });
          })
          .catch((err) => {
            ConsoleSafe.log(err);
            var errMgs = 'Unable to add payment method';
            setErrorMessage(errMgs);
            setLoading(false);
          });
      }
    };

    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                label='Name on card'
                name='name'
                variant='outlined'
                placeholder='John Doe'
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ background: 'white' }}
                inputProps={{ style: { marginTop: 0 } }}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label='e-mail'
                name='email'
                type='email'
                variant='outlined'
                required
                placeholder='john.doe@email.com'
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ background: 'white' }}
                inputProps={{ style: { marginTop: 0 } }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4} className='mb-3'>
              <TextField
                label='Zipcode'
                name='zipcode'
                variant='outlined'
                placeholder='88888'
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ background: 'white' }}
                inputProps={{ style: { marginTop: 0 } }}
                onChange={(e) => setZipcode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label='Credit Card Number'
                name='ccnumber'
                variant='outlined'
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement,
                  },
                }}
                style={{ background: 'white' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='Expiration Date'
                name='ccexp'
                variant='outlined'
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                  },
                }}
                style={{ background: 'white' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='CVC'
                name='cvc'
                variant='outlined'
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                  },
                }}
                style={{ background: 'white' }}
              />
            </Grid>
            {!isEmpty(errorMessage) && (
              <Grid item xs={12} sm={12} className='text-center mt-5'>
                <Typography.Text type='danger' className='font-MuliReg'>
                  {errorMessage}
                </Typography.Text>
              </Grid>
            )}
          </Grid>
          <OnboardingFooterBtn text='Finish' loading={loading} showBack={true} />
        </form>
      </>
    );
  }),
);

export default withRouter(PaymentForm);
