import ShopStore from './ShopStore';
import UserStore from './UserStore';
import ContextStore from './ContextStore';

class DataStore {
  constructor() {
    this.user = new UserStore(this);
    this.shop = new ShopStore(this);
    this.context = new ContextStore(this);
  }
}
const dataStore = new DataStore();

export default dataStore;
