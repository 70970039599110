import { Col, Row } from 'antd';
import React, { Component } from 'react';

import MerchantSettingsForm from './merchantSettings/MerchantSettingsForm';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import { withRouter } from 'react-router-dom';
import TrackingUtils from '../../utils/TrackingUtils';

class MerchantSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    TrackingUtils.eventMerchantPagesReceiptAccountSettings();
  }

  render() {
    return (
      <>
        <SectionHeader heading='My account settings' />
        <Row>
          <Col span={24}>
            <MerchantSettingsForm />
          </Col>
        </Row>
      </>
    );
  }
}

export default SecureComponent(withRouter(MerchantSettings));
