import { Col, Row } from 'antd';
import React, { Component } from 'react';

import ShopifyDetails from './storePosDetails/ShopifyDetails';
import SquareDetails from './storePosDetails/SquareDetails';
import StringConstants from '../../../constants/StringConstants';
import { findIndex } from 'lodash';

class StorePosDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getStorePos = (POS) => {
    const { store } = this.props;
    const index = findIndex(store.pointOfSales, {
      platform: POS,
      is_active: true,
    });
    return store.pointOfSales[index];
  };

  render() {
    const { store } = this.props;
    return (
      <Row>
        {store.pointOfSales.map((pos, index) => {
          return (
            <Col xs={24} lg={20} key={index}>
              <div className='store-details-wrap'>
                {pos.integrated_source.name === StringConstants.POS_PROVIDERS.SQUARE && pos.is_active ? (
                  <SquareDetails pos={pos} key={index} />
                ) : pos.integrated_source.name === StringConstants.POS_PROVIDERS.SHOPIFY && pos.is_active ? (
                  <ShopifyDetails pos={pos} key={index} />
                ) : null}
              </div>
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default StorePosDetails;
