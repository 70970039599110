import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import IntroLayout from './common/IntroLayout';
import Loading from '../../components/Loading';
import RouteConstants from '../../constants/RouteConstants';
import UrlUtils from '../../utils/UrlUtils';
import firebase from '../../firebase';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import TrackingUtils from '../../utils/TrackingUtils';
import ConsoleSafe from '../../utils/ConsoleSafe';

@inject('dataStore')
@observer
class Redirect extends Component {
  setRoute = async (path) => {
    if (path !== RouteConstants.LOGIN) {
      this.props.dataStore.user
        .getDetails()
        .then((user) => {
          TrackingUtils.identify(user.epopId, firebase.auth.currentUser?.emailVerified);
          const isActive = user.is_active;
          if (!isActive) {
            this.props.history.push(RouteConstants.SECURESIGNUP, {
              isPreRegisteredUser: true,
            });
            TrackingUtils.eventMerchantOnboardingStartedPreRegistered();
          } else {
            TrackingUtils.eventUserSignedInToMerchantApp();
            const business = user.business;
            if (
              !isEmpty(business) &&
              (path.includes(RouteConstants.ONBOARDING.SQUARE_CALLBACK) ||
                path.includes(RouteConstants.ONBOARDING.SHOPIFY_CALLBACK) ||
                path.includes(RouteConstants.ONBOARDING.OTHER_POS_SUCCESS))
            ) {
              this.props.history.push(path);
            } else if (isEmpty(business) || isEmpty(business.id) || isEmpty(business.features)) {
              this.props.history.push({
                pathname: RouteConstants.ONBOARDING.BUSINESS_DETAILS,
                search: UrlUtils.cleanQueryParams(this.props.location.search),
              });
            } else {
              this.props.history.push(RouteConstants.MERCHANT.DASHBOARD);
            }
          }
        })
        .catch((err) => {
          ConsoleSafe.log(err);
          firebase.auth.signOut();
          TrackingUtils.resetTracking();
          this.props.dataStore.user.reset();
          this.setRoute(RouteConstants.LOGIN);
        });
    } else this.props.history.push(path);
  };

  componentDidMount = () => {
    this.listener = firebase.auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        this.props.dataStore.user
          .getDetails(true) //force reloading, but it does not guarantee that we have an epopid yet if the user is not yet registered
          .then((user) => {
            TrackingUtils.identify(user.epopId, firebaseUser.emailVerified);
          })
          .catch((err) =>
            ConsoleSafe.log(
              'Something went wrong while updating user information after a firebase auth change event: ' + err,
            ),
          );
        let redirectionRoute = RouteConstants.MERCHANT.DASHBOARD;
        if (this.props.location.search) {
          const route = UrlUtils.getParamsFromUrl(this.props.location.search, 'redirect');
          if (route) redirectionRoute = decodeURIComponent(route);
        }
        this.setRoute(redirectionRoute);
      } else {
        firebase.auth.signOut();
        this.props.dataStore.user.reset();
        TrackingUtils.resetTracking();
        this.setRoute(RouteConstants.LOGIN);
      }
    });
  };

  componentWillUnmount = () => {
    this.listener();
  };

  render() {
    return (
      <IntroLayout>
        <Loading tip='Fetching User Details...' />
      </IntroLayout>
    );
  }
}

export default withRouter(Redirect);
