const getParamsFromUrl = (urlSearchParams, param) => {
  return new URLSearchParams(urlSearchParams).get(param);
};

const getAllParamsFromUrl = (urlSearchParams) => {
  const params = new URLSearchParams(urlSearchParams);
  let paramObj = {};
  for (var value of params.keys()) {
    paramObj[value] = params.get(value);
  }
  return paramObj;
};

const cleanQueryParams = (queryParams) => {
  const params = decodeURIComponent(queryParams).replace(/\?.*\?/, '');
  return params;
};

const UrlUtils = {
  getParamsFromUrl,
  getAllParamsFromUrl,
  cleanQueryParams,
};

export default UrlUtils;
