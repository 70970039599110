import ApiManager from '../ApiManager';
import HttpConstants from '../../constants/HttpConstants';
import UrlConstants from '../../constants/UrlConstants';

const ReceiptPreferenceService = {
  getReceiptPreferenceForBusiness: (business_id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_RECEIPT_PREFERENCE_BUSINESS.USECASE,
      HttpConstants.GET_METHOD,
      {
        ':merchant_business_id': business_id,
      },
    );
  },
  getReceiptPreferenceForStore: (store_id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_RECEIPT_PREFERENCE_STORE.USECASE,
      HttpConstants.GET_METHOD,
      {
        ':merchant_store_id': store_id,
      },
    );
  },

  updateReceiptPreference: (values) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_RECEIPT_PREFERENCE.USECASE,
      HttpConstants.PATCH_METHOD,
      values,
    );
  },
  updateReceiptPreferenceToAllStores: (values) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_RECEIPT_PREFERENCE_ALL_STORES.USECASE,
      HttpConstants.PATCH_METHOD,
      values,
    );
  },
  uploadBusinessLogo: (file) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPLOAD_BUSINESS_LOGO.USECASE,
      HttpConstants.PATCH_METHOD,
      file,
    );
  },
};

export default ReceiptPreferenceService;
