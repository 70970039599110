import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorPage from '../ErrorPage';
import MerchantAddStore from './merchantStores/MerchantAddStore';
import MerchantBilling from './MerchantBilling';
import MerchantDashboard from './MerchantDashboard';
import MerchantMembership from './MerchantMembership';
import MerchantReceiptSettings from './MerchantReceiptSettings';
import MerchantSettings from './MerchantSettings';
import MerchantStores from './MerchantStores';
import MerchantSubscriptions from './MerchantSubscriptions';
import MerchantSubscription from './subscription/MerchantSubscription';
import MerchantOffers from './offers/Offers';
import MerchantSubscriptionSettings from './subscription/MerchantSubscriptionSettings';
import RouteConstants from '../../constants/RouteConstants';

const MerchantRouter = () => {
  return (
    <Switch>
      <Route exact path={RouteConstants.MERCHANT.DASHBOARD}>
        <MerchantDashboard />
      </Route>
      <Route exact path={RouteConstants.MERCHANT.MANAGE_STORES}>
        <MerchantStores />
      </Route>
      <Route exact path={RouteConstants.MERCHANT.NEW_STORE_LOCATION}>
        <MerchantAddStore />
      </Route>
      <Route exact path={RouteConstants.MERCHANT.OFFERS}>
        <MerchantOffers />
      </Route>
      <Route exact path={RouteConstants.MERCHANT.SUBSCRIPTION}>
        <MerchantSubscription />
      </Route>
      <Route exact path={RouteConstants.MERCHANT.SUBSCRIPTION_SETTINGS}>
        <MerchantSubscriptionSettings />
      </Route>
      <Route exact path={RouteConstants.MERCHANT.MEMBERSHIP}>
        <MerchantMembership />
      </Route>
      <Route exact path={RouteConstants.MERCHANT.BILLING} component={MerchantBilling} />
      <Route exact path={RouteConstants.MERCHANT.SUBSCRIPTIONS} component={MerchantSubscriptions} />
      <Route exact path={RouteConstants.MERCHANT.RECEIPT_SETTINGS} component={MerchantReceiptSettings} />
      <Route exact path={RouteConstants.MERCHANT.SETTINGS}>
        <MerchantSettings />
      </Route>

      <Route exact path='*'>
        <ErrorPage />
      </Route>
    </Switch>
  );
};

export default MerchantRouter;
