import ErrorComponent from '../components/ErrorComponent';
import IntroLayout from './auth/common/IntroLayout';
import React from 'react';
import { withRouter } from 'react-router-dom';

const ErrorPage = (props) => {
  if (props.isIntroPage)
    return (
      <IntroLayout isError={true}>
        <ErrorComponent />
      </IntroLayout>
    );
  else return <ErrorComponent />;
};

export default withRouter(ErrorPage);
