import { Component } from 'react';
import StoreLayout from './storeContent/common/StoreLayout';
import StoreRouter from './storeContent/StoreRouter';

class StoreContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <StoreLayout>
        <StoreRouter />
      </StoreLayout>
    );
  }
}

export default StoreContent;
