const RouteConstants = {
  LOGIN: '/',
  SHOPIFY_LOGIN: '/login',
  SIGNUP: '/signup',
  SHOPIFY_SIGNUP: '/shopify-signup',
  SECURESIGNUP: '/securesignup',
  SIGNOUT: '/signout',
  TERMS_CONDITIONS_VIEW: '/signup/terms-and-conditions',
  PRIVACY_POLICY_VIEW: '/signup/privacy-policy',

  ONBOARDING_ROUTER: '/onboarding/*',
  ONBOARDING_PROGRESS_ROUTER: '/onboarding/:id/*',
  ONBOARDING: {
    BUSINESS_DETAILS: '/onboarding/business',
    FEATURES: '/onboarding/:id/features',
    CHOOSE_POS: '/onboarding/:id/create-store',
    SQUARE_CALLBACK: '/onboarding/create-store/square',
    SHOPIFY_CALLBACK: '/onboarding/create-store/shopify',
    OTHER_POS: '/onboarding/:id/create-store/other-pos',
    OTHER_POS_SUCCESS: '/onboarding/:id/create-store/other-pos-success',
    EPOP_PLAN: '/onboarding/:id/membership-plan',
    PAYMENT_METHODS: '/onboarding/payment-method',
  },

  MERCHANT_ROUTER: '/merchant/*',
  MERCHANT: {
    DASHBOARD: '/merchant/dashboard',
    MANAGE_STORES: '/merchant/stores',
    NEW_STORE_LOCATION: '/merchant/stores/new',
    MEMBERSHIP: '/merchant/membership-plan',
    SUBSCRIPTION: '/merchant/subscription',
    SUBSCRIPTION_SETTINGS: '/merchant/subscription/settings',
    SUBSCRIPTIONS: '/merchant/subscriptions',
    BILLING: '/merchant/billing',
    RECEIPT_SETTINGS: '/merchant/receipt-preferences',
    SETTINGS: '/merchant/settings',
    OFFERS: '/merchant/offers',
  },

  STORE_ROUTER: '/store/:id/*',
  STORE: {
    DASHBOARD: '/store/:id/dashboard',
    TRANSACTIONS: '/store/:id/transactions',
    MEMBERSHIP: '/store/:id/membership-plan',
    BILLING: '/store/:id/billing',
    RECEIPT_SETTINGS: '/store/:id/receipt-preferences',
    OFFERS: '/store/:id/offers',
    STORE_INFORMATION: '/store/:id/details',
  },

  FORGOT_PASSWORD: '/forgot-password',
  REDIRECT: '/redirect',
  STORES: '/stores',
  STORE_DETAILS: '/store/:id',
  CREATE_STORES: '/stores-create',
  EDIT_STORES: '/stores-edit/:id',
  USER_PROFILE: '/profile',

  SQUARE_CALLBACK: '/square/callback',
  SHOPIFY_CALLBACK: '/shopify/callback',
};

export default RouteConstants;
