import { Col, Row, Table, message } from 'antd';
import React, { Component } from 'react';
import { capitalize, lowerCase } from 'lodash';

import ApiManager from '../../api/ApiManager';
import HttpConstants from '../../constants/HttpConstants';
import Loading from '../../components/Loading';
import SectionHeader from '../../components/common/SectionHeader';
import SecureComponent from '../../components/SecureComponent';
import UrlConstants from '../../constants/UrlConstants';
import moment from 'moment';
import ConsoleSafe from '../../utils/ConsoleSafe';
import TrackingUtils from '../../utils/TrackingUtils';

class MerchantSubscriptions extends Component {
  state = {
    loading: false,
    subscriptions: [],
  };

  componentDidMount() {
    TrackingUtils.eventMerchantPagesSubscriptions();
    this.setState({ loading: true });
    ApiManager.makeApiCallWithAuthentication(UrlConstants.GET_ALL_SUBSCRIPTIONS.USECASE, HttpConstants.GET_METHOD)
      .then((response) => {
        this.setState({ subscriptions: response, loading: false });
      })
      .catch((error) => {
        ConsoleSafe.log(error);
        message.error('Unable to get your subscriptions at the moment');
        this.setState({ loading: false });
      });
  }

  getColumnsForTable = () => {
    return [
      {
        title: 'Subscription ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Store Info',
        dataIndex: 'items',
        key: 'items',
        render: ({ data }) => {
          const metadata = data[0].metadata;
          if (metadata.name) return `${metadata.name} ${metadata.provider ? '(' + metadata.provider + ')' : ''} `;
          else return '-';
        },
      },
      {
        title: 'Plan',
        dataIndex: 'plan',
        key: 'plan',
        render: (plan) => plan.nickname,
      },
      {
        title: 'Payment Interval',
        dataIndex: 'plan',
        key: 'plan',
        render: (plan) => capitalize(plan.interval),
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        key: 'start_date',
        render: (start_date) => moment(start_date, 'X').format('ll'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => capitalize(lowerCase(status)),
      },
      {
        title: 'Trial Start',
        dataIndex: 'trial_start',
        key: 'trial_start',
        render: (trial_start) => moment(trial_start, 'X').format('ll'),
      },
      {
        title: 'Trial End',
        dataIndex: 'trial_end',
        key: 'trial_end',
        render: (trial_end) => moment(trial_end, 'X').format('ll'),
      },
      {
        title: 'Collection Method',
        dataIndex: 'collection_method',
        key: 'collection_method',
        render: (collection_method) => capitalize(lowerCase(collection_method)),
      },
    ];
  };

  render() {
    const { loading, subscriptions } = this.state;
    return (
      <>
        <SectionHeader heading='My e.pop subscriptions' />
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row className='mt-5 pb-5'>
              <Col span={24}>
                <div className='tableReponsive'>
                  <Table
                    dataSource={subscriptions}
                    className='transaction-table'
                    columns={this.getColumnsForTable()}
                    size='small'
                    rowKey={(row) => row.id}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default SecureComponent(MerchantSubscriptions);
