import { Col, Descriptions, Row, Table } from 'antd';
import React, { Component } from 'react';

import { isEmpty } from 'lodash';
import squarePosImg from '../../../../assets/images/Square_POS@2x.png';

const lookup = require('country-code-lookup');

class SquareDetails extends Component {
  getColumnsForTable = () => {
    const columns = [
      {
        title: 'Location ID',
        dataIndex: 'location_id',
        key: 'location_id',
      },
      {
        title: 'Location Name',
        dataIndex: 'location_name',
        key: 'location_name',
      },
      {
        title: 'Billing Currency',
        dataIndex: 'billing_currency',
        key: 'billing_currency',
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        render: (address) => address?.address_line_1,
      },
      {
        title: 'Locality',
        dataIndex: 'address',
        key: 'address',
        render: (address) => address?.city,
      },
      {
        title: 'Country',
        dataIndex: 'address',
        key: 'address',
        render: (address) =>
          !isEmpty(address?.country_code) && `${lookup.byIso(address.country_code)?.country} (${address.country_code})`,
      },
    ];

    return columns;
  };

  render() {
    const { pos } = this.props;
    return (
      <Row className='mt-2'>
        <Col xs={24}>
          <div className='flex align-center'>
            <img src={squarePosImg} alt='Shopify' className='store-pos-circle mr-3' />
            <p className='font-MuliBold'>Square Store</p>
          </div>
        </Col>
        <Col xs={24} className='mt-3'>
          <Descriptions size='small' column={{ sm: 1, md: 2 }}>
            <Descriptions.Item label='Business Name'>{pos.name}</Descriptions.Item>
            <Descriptions.Item label='Billing Currency'>{pos.currency}</Descriptions.Item>
            <Descriptions.Item label='Country'>
              {pos.address &&
                pos.address.country_code &&
                `${lookup.byIso(pos.address.country_code)?.country} (${pos.address.country_code})`}
            </Descriptions.Item>
            <Descriptions.Item label='Square Merchant ID'>{pos.platform_identifier}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={24} className='mt-3'>
          <Table
            bordered
            title={() => 'Store Locations'}
            dataSource={pos.locations}
            columns={this.getColumnsForTable()}
            rowKey={(row) => row.id}
            scroll={{ x: 'fit-content' }}
          />
        </Col>
      </Row>
    );
  }
}

export default SquareDetails;
