import { Col, Row, Typography } from 'antd';
import { inject, observer } from 'mobx-react';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import UrlGenerator from '../../api/UrlGenerator';
import leftBackGroundImg from '../../assets/images/Left Menu Blue Circles@2x.png';
import logo from '../../assets/images/Group 1208.png';
import logoutImg from '../../assets/images/exit-to-app.png';
import rightArrowCheckImg from '../../assets/images/Menu Bar Active.png';
import rightArrowImg from '../../assets/images/Menu Bar Inactive.png';
import shopImg from '../../assets/images/STORE ICON.png';
import supportImg from '../../assets/images/support_icon@2x.png';
import { withRouter } from 'react-router-dom';

const { Title } = Typography;
const { STORE } = RouteConstants;

const steps = [
  {
    title: 'Store Dashboard',
    route: STORE.DASHBOARD,
  },
  {
    title: 'Transactions',
    route: STORE.TRANSACTIONS,
  },
  {
    title: 'Receipt Preferences',
    route: STORE.RECEIPT_SETTINGS,
  },
  {
    title: 'Offers',
    route: STORE.OFFERS,
  },
  {
    title: 'Store Information',
    route: STORE.STORE_INFORMATION,
  },
  {
    title: 'Exit This Store',
    icon: logoutImg,
    route: RouteConstants.MERCHANT.DASHBOARD,
  },
];

@inject('dataStore')
@observer
class StoreMenu extends React.Component {
  navigateToStep = (step) => {
    const id = this.props.match.params.id;
    this.props.history.push(
      UrlGenerator.getUiUrlWithPathParams(step.route, {
        id,
      }),
    );
    this.props.toggleSidebar(false);
  };

  render() {
    const { showBack, activeStep } = this.props;

    return (
      <div className='left-img-container ' style={{ backgroundImage: `url('${leftBackGroundImg}')` }}>
        <>
          <Row>
            <Col span={24}>
              <div className='info-container mb-1'>
                <Col flex={'auto'}>
                  {showBack && (
                    <div
                      className='closeSideBar'
                      onClick={() => {
                        this.props.toggleSidebar(false);
                      }}
                    >
                      <AiOutlineArrowLeft />
                    </div>
                  )}
                </Col>
                <div className='logo-container '>
                  <img src={shopImg} alt='Store' />
                </div>
                <div className='logo-container'>
                  <img src={logo} alt='e.pop Logo' />
                </div>
              </div>
              <div className='des-container mt-1 merchant-name-heading'>
                <Title level={2}>{this.props.dataStore.shop?.name}</Title>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className='adjustOffset' span={24}>
              <div className='des-info-container mt-5 '>
                {steps.map((step, index) => {
                  return (
                    <div className='des-info-lists' key={index}>
                      {step.icon && (
                        <div className='icon-bag' onClick={() => this.navigateToStep(step)}>
                          <img src={step.icon} alt='img' />
                        </div>
                      )}
                      <div>
                        <p onClick={() => this.navigateToStep(step)}>{step.title}</p>
                      </div>
                      <div className='merchant-icon'>
                        <img
                          onClick={() => this.navigateToStep(step)}
                          src={activeStep === index + 1 ? rightArrowCheckImg : rightArrowImg}
                          alt='Img'
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='des-info-container mt-5 '>
                <div className='des-info-lists'>
                  <div>
                    <a href='mailto:support@epoppay.com'>
                      <p>Contact Support</p>
                    </a>
                  </div>
                  <div className='merchant-icon'>
                    <a href='mailto:support@epoppay.com'>
                      <img src={supportImg} alt='Support' />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      </div>
    );
  }
}

export default withRouter(StoreMenu);
