import React, { Component } from 'react';

class ReceiptsCounter extends Component {
  getPaddedNumber(count) {
    let string = '';
    if (count.toString().length < 7) {
      for (var i = count.toString().length; i < 7; i++) {
        string += '0';
      }
      string += count.toString();
    }
    return string;
  }

  render() {
    const { count } = this.props;

    const countString = count > 0 ? count.toString() : '0000000';

    return (
      <div className='epop-dashboard-receipt-wrap d-flex justify-content-center'>
        {countString.split('').map((char, index) => {
          return (
            <div style={{ height: 50 }} key={index}>
              <div className='epop-dashboard-reciept' style={count > 0 ? { background: '#7bbf44' } : {}}>
                <p className='mb-0'>{char}</p>
                <div
                  className='epop-dashboard-reciept epop-dashboard-receipt-bottom'
                  key={index}
                  style={count > 0 ? { background: '#7bbf44' } : {}}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ReceiptsCounter;
